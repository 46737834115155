import InventoryApi from '../api/inventory_api';

export const state = {};

export const mutations = {};

export const getters = {
  
};

export const actions = {
  history(_, query){
    return InventoryApi.history(query);
  },
  list(_, data) {
    return InventoryApi.list(data);
  },

  garage(_, data) {
    return InventoryApi.garage(data);
  },

  store(_, data) {
    return InventoryApi.store(data)
  },

  add(_, data) {
    return InventoryApi.add(data)
  },

  delete(_, data) {
    return InventoryApi.delete(data)
  },

  swap(_, data) {
    return InventoryApi.swap(data)
  },

  getByCondition(_, data) {
    return InventoryApi.getByCondition(data)
  },


  roomProductList(_, data){
    return InventoryApi.roomProductList(data)
  },

  roomProductAdd(_, data){
    return InventoryApi.roomProductAdd(data)
  },

  roomProductDelete(_, data) {
    return InventoryApi.roomProductDelete(data)
  },
};
