<template>
  <div>
    <div class="box-content">
      <div class="form-group mb-0">
        <label style="margin-bottom: 10px">Nhập sản phẩm vào kho</label>
        <div>
          <div class="form-group">
            <div
              class="btn btn-primary"
              style="width: 200px"
              @click.prevent="isShowProduct = true"
            >
              Chọn sản phẩm
            </div>
          </div>
          <div class="form-group">
            <label>File hóa đơn</label>
            <div
              class="input-control"
              style="width: 300px"
              :class="{ error: errors.has('File hóa đơn') }"
            >
              <input
                ref="billFile"
                @change="filePicker"
                name="File hóa đơn"
                v-validate="'required'"
                type="file"
              />
            </div>
            <div class="has-error" v-if="errors.has('File hóa đơn')">
              {{ errors.first("File hóa đơn") }}
            </div>
          </div>
        </div>
        <div v-if="products && products !== null && products.length > 0">
          <table>
            <thead>
              <td>Mã sản phẩm</td>
              <td>Mã nội bộ</td>
              <td>Tên</td>
              <td>Loại sản phẩm</td>
              <td>Chai</td>
              <td>Dung tích (1)</td>
              <td>Giá 1 chai (2)</td>
              <td>Đơn giá (2) / (1)</td>
              <td>Đơn giá cũ</td>
              <td>Áp dụng giá</td>
              <td style="min-width: 120px">Hành động</td>
            </thead>
            <tbody>
              <tr v-for="(row, index) of products" :key="row.key">
                <td>{{ row.product_id }}</td>
                <td>{{ row.product.code || "--" }}</td>
                <td
                  style="
                    min-width: 200px;
                    max-width: 500px;
                    word-break: break-word;
                  "
                >
                  {{ row.product.name }}
                </td>
                <td
                  v-html="generateTagHtml(row.product.type, ProductTypeLookup)"
                ></td>
                <td style="max-width: 50px">
                  <div style="margin-bottom: 0; display: flex">
                    <div>
                      <cleave
                        :options="options.number"
                        :class="{
                          error: errors.has(
                            `Chai_${row.product_id}_${row.key}`
                          ),
                        }"
                        class="input-control input-dynamic"
                        v-model="row.amount_on_package"
                        :name="`Chai_${row.product_id}_${row.key}`"
                        v-validate="
                          'required|numeric|min_value:1|max_value:2000000'
                        "
                        :data-vv-as="`Chai`"
                      />
                      <!-- <div class="has-error" v-if="errors.has(`Chai_${row.product_id}_${index}`)">
                      {{ errors.first(`Chai_${row.product_id}_${index}`) }}
                    </div> -->
                    </div>
                  </div>
                </td>
                <td style="max-width: 100px">
                  <div style="margin-bottom: 0; display: flex">
                    <div style="margin-right: 10px">
                      <cleave
                        style="min-width: 40px"
                        :options="options.number"
                        :class="{
                          error: errors.has(
                            `Dung_tích_${row.product_id}_${row.key}`
                          ),
                        }"
                        class="input-control input-dynamic"
                        v-model="row.amount_on_unit"
                        :name="`Dung_tích_${row.product_id}_${row.key}`"
                        v-validate="'required|numeric|digits_between:1,2000000'"
                        :data-vv-as="`Dung tích`"
                      />
                      <!-- <div class="has-error" v-if="errors.has(`Dung tích_${row.product_id}_${index}`)">
                      {{ errors.first(`Dung tích_${row.product_id}_${index}`) }}
                    </div> -->
                    </div>
                    <div style="padding-top: 6px; width: 20px">
                      {{ row.unit }}
                    </div>
                  </div>
                </td>
                <td style="max-width: 100px">
                  <div style="margin-bottom: 0; display: flex">
                    <div>
                      <cleave
                        :options="options.number"
                        :class="{
                          error: errors.has(`Gia_${row.product_id}_${row.key}`),
                        }"
                        class="input-control input-dynamic"
                        v-model="row.price_on_package"
                        :name="`Gia_${row.product_id}_${row.key}`"
                        v-validate="
                          'required|numeric|min_value:1|max_value:10000000'
                        "
                        :data-vv-as="`Chai`"
                      />
                      <!-- <div class="has-error" v-if="errors.has(`Chai_${row.product_id}_${index}`)">
                      {{ errors.first(`Chai_${row.product_id}_${index}`) }}
                    </div> -->
                    </div>
                  </div>
                </td>
                <td>
                  <div style="margin-bottom: 0; display: flex">
                    <span>{{ getUnitPrice(row).formatCurrency() }}</span>
                  </div>
                </td>
                <td>
                  <div style="margin-bottom: 0; display: flex">
                    <span>{{ row.product.price.formatCurrency() }}</span>
                  </div>
                </td>
                <td>
                  <div style="margin-bottom: 0; display: flex">
                    <input v-model="row.is_apply" type="checkbox" />
                  </div>
                </td>
                <td>
                  <button
                    class="btn btn-danger btn-sm"
                    style="min-width: auto"
                    @click.prevent="deleteProduct(index)"
                  >
                    Xóa
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style="margin-top: 20px"
          v-if="products && products !== null && products.length > 0"
        >
          Lưu ý: Nếu 2 sản phẩm cùng
          <span style="font-weight: bold">Mã nội bộ</span> hệ thống sẽ ưu tiên
          chọn <span style="font-weight: bold">Áp dụng giá</span> cao hơn.
        </div>
        <div
          class="input-control"
          style="border: 0; margin: auto"
          v-if="products && products !== null && products.length > 0"
        >
          <div class="form-group" style="margin-bottom: 0; margin-top: 10px">
            <div
              class="btn btn-primary btn-sm"
              style="width: 200px"
              @click.prevent="addToInventory"
            >
              Thêm vào kho &<br />
              Tải về thay đổi giá
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-product-table
      title="Danh sách sản phẩm"
      v-if="isShowProduct"
      @close="isShowProduct = false"
      @chooseProducts="chooseProducts"
    >
    </modal-product-table>
  </div>
</template>

<script>
import ModalProductTable from "@/components/ModalProductTable";
import { mapActions } from "vuex";
import ProductTypeLookup from "@/enums/ProductTypeLookup";
import { generateTagHtml } from "@/utils/convert";
import { uniqueId } from "lodash";
import Cleave from "vue-cleave-component";
import moment from 'moment';
export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  components: {
    ModalProductTable,
    Cleave,
  },
  props: ["garageId"],
  data() {
    return {
      moment,
      test: null,
      isShowProduct: false,
      products: [],
      fileData: null,
      ProductTypeLookup,
      generateTagHtml,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
        },
      },
    };
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    getUnitPrice(product, isDefault) {
      let price = 0;
      if (
        product.amount_on_package &&
        product.amount_on_unit &&
        product.price_on_package &&
        product.amount_on_unit > 0 &&
        product.amount_on_package > 0
      ) {
        price = Math.round(
          Number(product.price_on_package) / Number(product.amount_on_unit)
        );
      }
      return isDefault && price == 0 ? product.price : price;
    },
    filePicker(ev) {
      this.fileData = ev.target.files[0];
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    chooseProducts(val) {
      this.products = [...this.products, ...val];
      this.products = this.products.map((product) => {
        return {
          ...product,
          ...{ key: product.product_id + "_" + uniqueId() },
        };
      });
    },
    async addToInventory() {
      window.scrollTo(0, 0);

      const result = await this.validateAll(this);
      if (this.errors.has("File hóa đơn")) {
        this.scrollToTop();
      }
      if (!result) {
        return;
      }

      try {
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Đang upload file...",
        });
        // upload
        let mediaObj = await this.$store.dispatch("media/upload", {
          file: this.fileData,
        });
        const fileUrl = mediaObj.url;

        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Vui lòng đợi...",
        });
        let res = await this.$store.dispatch("inventory/add", {
          products: this.products.map((product) => {
            return {
              ...product,
              ...{ key: product.product_id + "_" + uniqueId() },
              price: this.getUnitPrice(product, true),
            };
          }),
          garageId: this.garageId,
          fileUrl,
        }).catch(e => -1);
        if(res != -1){
          let FILE = window.URL.createObjectURL(new Blob([res]));
          let docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute('download', `Áp dụng giá ${moment(new Date()).format("YYYY_MM_DD_HH_mm")}.xlsx`);
          document.body.appendChild(docUrl);
          docUrl.click();
        }
        
        this.products = [];
        this.$refs.billFile.value = null;
        this.$emit("submit");
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    deleteProduct(index) {
      this.products.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-content {
  background: white;
  padding: 16px 18px;
  margin-bottom: 32px;

  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
    .input-dynamic {
      min-width: 40px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .box-content {
    .form-group {
      .input-dynamic {
        width: 40px;
      }
    }
  }
}
</style>
