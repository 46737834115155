export default {
  //admin
  list: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/bundle/admin/list'), query);
  },
  create(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/bundle/admin/create'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
  detail: (payload) => {
    return window.$.get(`${process.env.VUE_APP_API_URL}/bundle/admin/detail/${payload.id}`);
  },
  update(payload) {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/bundle/admin/update/${payload.bundle.id}`,
      data: JSON.stringify(payload),
      contentType: 'application/json'
    });
  },

  priceCosts(payload) {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/bundle/admin/price-cost`,
      data: JSON.stringify(payload),
      contentType: 'application/json'
    });
  },
};
