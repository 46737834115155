<template>
  <modal-base @close="$emit('close')">
    <div class="content">
      <label style="font-weight: bold;">Thay đổi quản lý ga-ra</label>
      <div style="height: 20px" />
      <div class="tip">
        Chú ý: Trưởng gara hiện tại sẽ bị chuyển xuống vai trò nhân viên
      </div>
      <div style="height: 12px" />
      <div class="form-group">
        <label>Chọn nhân viên</label>
        <div class="input-control">
          <staff-list-view :role="2" @input="userId = $event"></staff-list-view>
        </div>
      </div>
      <div style="height: 12px" />
      <div
        class="btn btn-submit"
        style="margin: auto;"
        @click="update()"
      >
        Cập nhật
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
import StaffAssignView from "../../../../components/StaffAssignView.vue";
import StaffListView from "../../../../components/StaffListView.vue";
export default {
  components: { ModalBase, StaffAssignView, StaffListView },
  props: ["type", "value", "garageId"],

  data() {
    return {
      userId: null,
    };
  },
  methods: {
    async update() {
      if (!this.userId) return;
      try {
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Đang lưu dữ liệu...",
        });

        await this.$store.dispatch("garage/update", {
            id: this.garageId,
            user_id: this.userId
        });
        this.$toastr.s("Đã cập nhật quản lý gara");
        this.$emit("close");
        this.$emit("reload");
      } catch (e) {
        this.$toastr.e("Lỗi cập nhập. Vui lòng thử lại");
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: 100px;
  .control {
    min-height: 40px;
    border: 1px solid #ededed;
  }
}
</style>
