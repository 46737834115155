<template>
  <select
    v-model="model"
    :name="mName"
    v-validate="validate || ''"
    ref="select2"
    style="width: 100%; height: 100%;"
    multiple
  >
  </select>
</template>

<script>
export default {
  inject: ["parentValidator"],
  props: ["value", "validate", "mName", "garageId"],
  data() {
    return {
      model: this.value? this.value.map(user => user.id) : [],
    };
  },
  created() {
    this.$validator = this.parentValidator;
  },
  mounted() {
    const el = window.$(this.$refs["select2"]);
    if (this.value) {
      this.value.forEach(function(e) {
        if (!el.find("option:contains(" + e.text + ")").length)
          el.append(
            window
              .$("<option selected>")
              .text(e.text)
              .val(e.id)
          );
      });
    }

    el.select2({
      ajax: {
        url: `${process.env.VUE_APP_API_URL}/garage/staffs?garage_id=${this.garageId}`,
        dataType: "json",
        data: function(params) {
          return {
            search: params.term,
          };
        },
        processResults: function(data) {
          var result_data = window.$.map(data['data'], function(obj) {
            return {
              ...obj,
              text: obj.name,
            };
          });

          return {
            results: result_data,
          };
        },
      },

      placeholder: "- Chọn -",
      templateResult: (repo) => {
        if (repo.loading) return "Đang cập nhật...";
        return `${repo.text} - ${repo.phone}`;
      },
      templateSelection: (repo) => {
        return repo.text;
      },
    })
      .trigger("change")
      .on("change", () => {
        const selected = window.$(this.$refs["select2"]).select2("data");
        const selectIds = selected.map((x) => Number(x.id));
        this.$emit("input", selectIds);
      }).val(this.model);
  },

  beforeDestroy() {
    window
      .$(this.$refs["select2"])
      .off()
      .select2("destroy");
  },
};
</script>

<style lang="scss" scoped></style>
