<template>
  <default-layout>
    <div
      class="title"
      style="display: flex;align-items: center; background: white; padding: 20px;"
    >
      <div style="flex: 1; display: flex;   align-items: top;">
        <div style="display: flex; flex-direction: column; margin: 2px;">
          <div>Thông tin tồn kho</div>
          <div style="height: 8px;" />
          <div style="font-size: 14px;">
            <select-lazy-loading-view
            style="width: 400px"
              mName="ga-ra"
              :hasAll="false"
              :disabled="user.role != 4"
              :current="garageId"
              dispatch="garage/lookup"
              @onChange="garageId = $event"
            ></select-lazy-loading-view>
          </div>
        </div>
      </div>
    </div>
    <div class="box-content" >
      <tab-view
        :tabLists="tabLists"
        :current="currentTab"
        @onSelection="currentTab = $event"
      ></tab-view>
      <div style="margin-top: 20px">
        <div v-show="currentTab === 0">
          <div v-if="isFirsloading"></div>
          <div v-else>
            <div class="search-box">
              <form @submit.prevent="search">
                <div class="form-group">
                  <label>Sản phẩm</label>
                  <div class="form-control">
                    <input v-model="keyword" placeholder="Mã nội bộ, tên sản phẩm"/>
                  </div>
                </div>
                <div class="form-group ">
                  <label>Loại sản phẩm</label>
                  <div class="form-control">
                    <select v-model="type" @change="search">
                      <option value="">Tất cả</option>
                      <option v-for="(option,index) in ProductTypeLookup" v-bind:value="option.value" :key="index">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group ">
                  <label>Trạng thái</label>
                  <div class="form-control">
                    <select v-model="status" @change="search">
                      <option value="">Tất cả</option>
                      <option v-for="(option,index) in statusOptions" v-bind:value="option.value" :key="index">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
              <div class="action">
                <button class="btn btn-submit" @click.prevent="search">Tìm kiếm</button>
                <button class="btn btn-info" @click.prevent="resetFilter">Thiết lập lại</button>
                <div class="btn " @click.prevent="exportData" style="display: flex;"><img src="@/assets/icons/pdf.png" width="20" style="margin-right: 4px">&nbsp;&nbsp;File in</div>
                        <div class="btn " @click.prevent="exportDataExcel" style="display: flex;"><img src="@/assets/icons/excel.png" width="20" style="margin-right: 4px">&nbsp;&nbsp;File tổng hợp</div>
              </div>
            </div>
            <div style="height: 20px" />

            <table-count :meta="meta" @refresh="fetchData"></table-count>
            <div class="content" style="overflow: auto;">
              <table>
                <thead>
                  <td>Mã sản phẩm</td>
                  <td>Mã nội bộ</td>
                  <td style="min-width: 200px;">Tên sản phẩm</td>
                  <td>Loại sản phẩm</td>
                  <td style="min-width: 70px;">Tổng dung tích</td>
                  <td style="min-width: 70px;">Tồn</td>
                  <td style="min-width: 70px;">Đã phân phối</td>
                  <td>Trạng thái</td>
                  <td v-if="user.role === UserRoleType.MASTER"  style="min-width: 70px;">Hành động</td>
                </thead>
                <tbody>
                  <tr v-if="!rows || rows.length < 1">
                    <td colspan="7"><span class="text-danger"> Không có dữ liệu </span></td>
                  </tr>
                  <tr v-else v-for="row of rows" :key="row.id">
                    <td>{{ row.product_id }}</td>
                    <td>{{ row.product.code || '--' }}</td>
                   <td style="min-width: 200px; max-width: 500px; word-break: break-word;">{{ row.product.name }}</td>
                    <td v-html="generateTagHtml(row.product.type, ProductTypeLookup)"></td>
                    <td v-html="row.amount.formatNumber()"></td>
                    <td v-html="(row.amount - row.amount_delivered).formatNumber()"></td>
                    <td v-html="row.amount_delivered.formatNumber()"></td>
                    <td v-html="generateStatusHtml(row.status, statusOptions)"></td>
                    <td v-if="user.role === UserRoleType.MASTER">
                      <button
                        class="btn btn-danger  btn-sm"
                        style="min-width: auto; margin-right: 10px"
                        @click.prevent="
                          showConfirmDelete(row.id, row.product.name)
                        "
                      >
                        Xóa
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <paginate-view
              style="justify-content: right;"
              :page="meta.page"
              :totalPage="meta.totalPage"
              @onPage="meta.page = String($event)"
            ></paginate-view>
          </div>
        </div>
        <inventory-add
          v-show="currentTab === 1"
          :garageId="garageId"
          @submit="
            currentTab = 0;
            fetchData();
          "
        ></inventory-add>
        <inventory-swap
          v-show="currentTab === 2"
          :garageId="garageId"
          @submit="
            currentTab = 0;
            fetchData();
          "
        ></inventory-swap>
        <inventory-history v-if="currentTab === 3" :garageId="garageId"></inventory-history>
      </div>
    </div>
    <modal-confirm-delete
      :deleteObj="deleteObj"
      v-if="isShowConfirmDelete"
      @close="isShowConfirmDelete = false"
      @ok="deleteInventory"
    >
    </modal-confirm-delete>
  </default-layout>
</template>

<script>
import PaginateView from "@/components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import ModalConfirmDelete from "./modal/ModalConfirmDelete";
import SelectLazyLoadingView from "@/components/SelectLazyLoadingView.vue";
import TableCount from "@/components/TableCount.vue";
import TabView from "@/components/TabView.vue";
import InventoryAdd from "./tab/InventoryAdd.vue";
import InventorySwap from "./tab/InventorySwap.vue";
import { generateStatusHtml, generateTagHtml } from "@/utils/convert";
import UserRoleType from "@/enums/UserRoleType";
import InventoryStatusLookup from "@/enums/InventoryStatusLookup";
import ProductTypeLookup from "@/enums/ProductTypeLookup";
import { mapGetters } from "vuex";
import {isEmpty} from "lodash";
import InventoryHistory from './tab/InventoryHistory.vue';
import moment from 'moment'

export default {
  components: {
    ModalConfirmDelete,
    DefaultLayout,
    PaginateView,
    SelectLazyLoadingView,
    TableCount,
    TabView,
    InventoryAdd,
    InventorySwap,
    InventoryHistory,
  },
  data() {
    return {
      rows: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      keyword: null,
      status: '',
      type: '',
      isShowProduct: false,
      isShowSwapProduct: false,
      productIds: [],
      amountItems: [],
      garageOption: [],
      garageId: null,
      isLoadingList: true,
      isFirsloading: true,
      isShowConfirmDelete: false,
      deleteObj: null,
      currentTab: 0,
      tabLists: [{ id: 0, name: "Sản phẩm trong kho" }],
      generateStatusHtml,
      generateTagHtml,
      UserRoleType: UserRoleType,
      statusOptions: InventoryStatusLookup,
      ProductTypeLookup
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  async created() {
    this.garageId = this.user.garage ? this.user.garage.id : 0;

    if(localStorage.getItem('garageId') && !this.user.garage){
      this.garageId = localStorage.getItem('garageId');
    }
    
    if(this.$route.query.garage_id && !this.user.garage ){
      this.garageId = this.$route.query.garage_id
    }
    console.log(this.garageId, "XXX")

    if (this.user.role === UserRoleType.MASTER) {
      this.tabLists = [
        { id: 0, name: "Sản phẩm trong kho" },
        { id: 1, name: "Nhập kho" },
        // { id: 2, name: "Chuyển kho" },
        { id: 3, name: "Lịch sử nhập kho" },
      ];
    } else {
      this.fetchData();
    }
  },

  computed: {
    ...mapGetters("account", ["user"]),
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },

    garageId: function(val) {
      if (val === 0) return;
      localStorage.setItem('garageId', val);
      this.fetchData();
    },
  },
  methods: {
    search() {
      this.fetchData(1);
    },
    async resetFilter(){
      this.status = ''
      this.type = ''
      this.keyword = null
      await this.fetchData()
    },
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        // msg: "Vui lòng đợi...",
      });
      try{
        let resp = await this.$store.dispatch("inventory/list", {
          page,
          garageId: this.garageId,
          status: this.status,
          type: this.type,
          keyword: this.keyword,
          limit : 10
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.rows = resp.data;
        this.meta = resp.meta;
        this.isFirsloading = false;
      }catch (e){
        this.$toastr.e(e.responseJSON.message);
      }finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    showConfirmDelete(id, name) {
      this.deleteObj = {
        id,
        name,
      };
      this.isShowConfirmDelete = true;
    },
    async deleteInventory(id) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        // msg: "Vui lòng đợi...",
      });
      try{
        await this.$store.dispatch("inventory/delete", { id });
        this.isShowConfirmDelete = false;
        await this.fetchData();
      }catch (e){
        this.$toastr.e(e.responseJSON.message);
      }finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
     async exportDataExcel(){
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let res = await this.$store.dispatch("report/garageRoomProductExcel", {
          garageId: this.garageId
        });
        this.firstLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `Phân phối sản phẩm ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`);
        document.body.appendChild(docUrl);
        docUrl.click();
      } catch (e) {
        let msg = 'Server gặp sự cố. Vui lòng thử lại sau'
        if(e.status){
          msg = 'Không có dữ liệu!'
        }
        this.$toastr.e(msg);
      }finally{
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    async exportData(){
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let res = await this.$store.dispatch("report/garageRoomProduct", {
          garageId: this.garageId
        });
        this.firstLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `Phân phối sản phẩm ${moment().format("YYYY-MM-DD HH:mm")}.pdf`);
        document.body.appendChild(docUrl);
        docUrl.click();
      } catch (e) {
        let msg = 'Server gặp sự cố. Vui lòng thử lại sau'
        if(e.status){
          msg = 'Không có dữ liệu!'
        }
        this.$toastr.e(msg);
      }finally{
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
}
.box-content {
  background: white;
  padding: 16px 18px;
  margin-bottom: 32px;
  min-height: 300px;
  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    //min-height: 320px;
  }
}
.icon-loader {
  width: 34px;
  height: 34px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
}
</style>
