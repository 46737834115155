<script>
import { HorizontalBar } from "vue-chartjs";
import OrderStatusStringType from "@/enums/OrderStatusSringType";
export default {
  extends: HorizontalBar,
  data() {
    return {
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: "top",
          },
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: "rgba(0, 0, 0, 0.25)",
          backgroundColor: "#f6f6f6",
          titleFontColor: "#4b4b4b",
          bodyFontColor: "#4b4b4b",
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                zeroLineColor: "rgba(200, 200, 200, 0.2)",
                borderColor: "transparent",
                color: "rgba(200, 200, 200, 0.2)",
                drawTicks: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                min: 0,
                fontColor: "#6e6b7b",
                precision: 0
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: "#6e6b7b",
              },
            },
          ],
        },
      },
      data: {
        labels: Object.values(OrderStatusStringType),
        datasets: [
          {
            data: [],
            backgroundColor: "#00cfe8",
            borderColor: "transparent",
            barThickness: 15,
          },
        ],
      },
    };
  },
  async created() {},
  computed: {
    auth() {
      return this.$store.getters["account/user"];
    },
  },
  mounted() {
    this.fetchOrderStatus();
  },
  methods: {
    async fetchOrderStatus() {
      this.isLoadingList = true;
      let payload = {};
      if (this.auth.garage && this.auth.garage.id) {
        payload.garageId = this.auth.garage.id;
      }
      let resp = await this.$store.dispatch("report/orderStatus", payload);
      if (resp) {
        let dataSet = Object.values(OrderStatusStringType).map((key) => {
          return {
            x: 0,
            y: key,
          };
        });

        for (let item of resp) {
          let index = dataSet.findIndex((datum) => {
            return OrderStatusStringType[item.status] === datum.y
          });
          if(index > -1) dataSet[index].x = parseInt(item.total)
        }
        this.data.datasets[0].data = dataSet;
        this.renderChart(this.data, this.options);
      }
    },
  },
};
</script>
