<template>
  <default-layout>
    <div class="box-container">
      <div class="header">
        <label class="title">Tạo gói mới</label>
        <div class="action">
          <button
            class="btn btn-submit"
            style="width: 200px; margin: auto"
            @click.prevent="createBundle()"
          >
            Tạo gói
          </button>
        </div>
      </div>
      <div class="form">
        <div class="right">
          <div class="form-group">
            <div class="input-control box-form-input" style="margin: 0;">
              <input-view
                mName="Tên gói"
                :value="bundle.name"
                @input="bundle.name = $event"
                maxlength="250"
                validate="required"
              ></input-view>

          

              <input-view
                mName="Giá bán"
                :value="bundle.price"
                @input="bundle.price = $event"
                validate="required|numeric"
                :cleaveOption="options.number"
              ></input-view>

              <div class="form-group">
                <label>Trạng thái</label>
                <div class="form-control">
                  <select v-model="bundle.status">
                    <option v-for="(option, index) in statusOptions" :key="index" v-bind:value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
<!--            <label>Chọn sản phẩm *</label>-->
            <div class="input-control" style="border: 0;">
              <div class="text-info" style="font-style: italic">
                Chọn sản phẩm để tạo thành gói
              </div>
            </div>
            <div class="input-control" style="border: 0;">
              <div class="form-group">
                <div
                  class="btn btn-primary"
                  style="width: 200px;"
                  @click="isShowProduct = true"
                >
                  Chọn sản phẩm
                </div>
              </div>
            </div>
            <div
              class="input-control"
              style="border: none"
              v-if="products && products !== null && products.length > 0"
            >
              <table>
                <thead>
                  <td>Mã sản phẩm</td>
                  <td>Mã nội bộ</td>
                  <td>Tên</td>
                  <td>Loại sản phẩm</td>
                  <td>Dung tích</td>
                </thead>
                <tbody>
                  <tr v-for="(row) of products" :key="row.key">
                    <td>{{ row.product_id }}</td>
                    <td>{{ row.product.code || '--' }}</td>
                    <td style="min-width: 200px; max-width: 500px; word-break: break-word;">{{ row.product.name }}</td>
                    <td v-html="generateTagHtml(row.product.type, ProductTypeLookup)"></td>
                    <td>
                      <div style="margin-bottom: 0; display: flex">
                        <div style="margin-right: 10px">
                          <cleave
                            :options="options.number"
                            :class="{error: errors.has(`Dung_tích_${row.product_id}_${row.key}`)}"
                            type="text"
                            autocomplete="off"
                            class="input-control input-dynamic"
                            v-model="row.amount"
                            :name="`Dung_tích_${row.product_id}_${row.key}`"
                            v-validate="'required|numeric|min_value:1|max_value:2000000'"
                            :data-vv-as="`Dung tích`"
                            @input="validate"
                          />
                        </div>
                        <div style="margin-top: 6px; width: 20px">
                          {{ row.unit }}
                        </div>
                      </div>
                      <!-- <div class="has-error" v-if="errors.has(`Dung_tích_${row.product_id}_${index}`)">
                        {{ errors.first(`Dung_tích_${row.product_id}_${index}`) }}
                      </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-product-table
      :productCurrent="products"
      :productIds="productIds"
      v-if="isShowProduct"
      @close="isShowProduct = false"
      @chooseProducts="chooseProducts"
    >
    </modal-product-table>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import ModalProductTable from "../../../components/ModalProductTable";
import BundleStatusLookup from '@/enums/BundleStatusLookup'
import InputView from "@/components/InputView.vue";

import {cloneDeep, isEmpty, map, uniqueId} from "lodash";
import {mapActions} from "vuex";
import ProductTypeLookup from '@/enums/ProductTypeLookup'
import {generateTagHtml} from "@/utils/convert";
import Cleave from 'vue-cleave-component';

export default {
  components: {
    DefaultLayout,
    ModalProductTable,
    InputView,
    Cleave
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data() {
    return {
      bundle: {
        id: this.$route.params.id,
        status: BundleStatusLookup[0].value
      },
      isShowProduct: false,
      products: null,
      productIds: [],
      amountItems: [],
      statusOptions: BundleStatusLookup,
      ProductTypeLookup,
      generateTagHtml,
      options:{
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 0,
        },
      }
    };
  },
  created() {},
  watch: {
    products() {
      this.productIds = map(this.products, "product_id");
    },
  },
  computed: {},
  methods: {
    ...mapActions("global", ["validateAll"]),
    validate(){
      this.$validator.validateAll();
    },
    async createBundle() {
      const result = await this.validateAll(this);

      if(isEmpty(this.products)){
        this.$toastr.e(`Cần ít nhất một sản phẩm để tạo gói`);
      }

      if (!result || isEmpty(this.products)) return;

      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang tạo gói...",
      });
      try {
        const bundle = await this.$store.dispatch("bundle/create", {
          bundle: this.bundle,
          products: this.products,
        });
        this.$toastr.s(`Đã tạo gói`);
        this.$nextTick(() => {
          this.$router.push({
            name: "bundle detail",
            params: bundle,
          });
        });
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          msg: "Đang tạo gói...",
        });
      }
    },
    chooseProducts(val) {
      val = val.map(product => { return {...product, ...{key: product.product_id + '_' + uniqueId()}} })
      this.products = cloneDeep(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    textarea,
    input {
      border: 0;
      background: transparent;
      color: #717171;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
    .input-dynamic{
      min-width: 100px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .box-content {
    .form-group{
      .input-dynamic{
        width: 100px;
      }
    }
  }
}
</style>
