<template>
  <select
    v-validate="validate || ''"
    :name="mName"
    v-model="model"
    ref="select2"
    style="width: 100%; height: 100%;"
  >
  </select>
</template>

<script>
export default {
  inject: ["parentValidator"],

  props: ["options", "value", "mName", "validate"],

  data(){
    return{
      model: this.value
    }
    
  },
  created() {
    this.$validator = this.parentValidator;
  },
  mounted() {
    var vm = this;
    const el = window.$(this.$refs["select2"]);
    if (this.options) {
      this.options.forEach((e) => {
          if (!el.find("option:contains(" + e.name + ")").length)
        

          el.append(
            window
              .$("<option >")
              .text( e.name)
              .val(e.id)
          );
      })
      
    }

    window
      .$(this.$refs["select2"])
      .select2({
         cache: false,
        ajax: {
          url: `${process.env.VUE_APP_API_URL}/bundle/lists`,
          dataType: "json",
          data: function(params) {
            return {
              search: params.term,
            };
          },
         
          processResults: function(data) {
            var result_data = window.$.map(data.data, function(obj) {
              obj.text = `${obj.name}`;
              return obj;
            });
            return {
              results: result_data,
            };
          },
        },
        placeholder: "- Chọn -",
        templateResult: function(repo) {
          return repo.text;
        },

        templateSelection: function(repo) {
          return repo.text;
        },
      })
      .val(this.value)
      .trigger("change")
      // emit event on change.
      .on("change", function() {
        vm.$emit("input", this.value);
      });
  },
  watch: {
    value: function(value) {
      // update value
      window.$(this.$el).val(value);
    },
    options: function(options) {
      // update options
      // window
      //   .$(this.$el)
      //   .empty()
      //   .select2({ data: options });
    },
  },
  destroyed: function() {
    window
      .$(this.$el)
      .off()
      .select2("destroy");
  },
};
</script>

<style lang="scss" scoped>

</style>
