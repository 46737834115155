<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div class="group">
        <label>Nhập lý do huỷ đơn</label>
          <div style="height: 12px" />
        <div class="form-control" style="height: auto;">
          <textarea
            placeholder="VD: Đặt sai thông tin khách hàng."
            v-model.trim="note"
          ></textarea>
        </div>
        <div style="height: 12px" />
        <div style="color: #ff4f60;">
          Chú ý: Mọi dữ liệu check-in của nhân viên cũng sẽ bị hủy nếu
          có.
        </div>

        <div style="height: 20px" />

        <div
          class="btn btn-danger"
          style="width: 240px; margin: auto;"
          @click.prevent="
            $emit('ok', note);
            $emit('close');
          "
        >
          Xác nhận hủy
        </div>
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
export default {
  components: { ModalBase },
  data() {
    return {
      note: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
  .group {
    display: flex;
    flex-direction: column;
    label {
      font-weight: bold;
      font-size: 18px;
    }
    .form-control {
      border: 1px solid #ededed;
      display: flex;
      textarea {
        border: 0;
        width: 100%;
        resize: none;
        height: 100px;
      }
    }
  }
  .btn-primary {
    background: #ff4f60;
    color: white;
    width: 200px;
    margin: auto;
  }
}
</style>
