<template>
  <default-layout>
    <div class="search-box">
      <form>
        <div class="form-group" v-if="auth.role == 4">
          <label>Chọn ga-ra</label>
          <div class="form-control">
            <select-lazy-loading-view
              dispatch="garage/lookup"
              :hasAll="true"
              :current="garageId"
              @onChange="garageId = $event"
            ></select-lazy-loading-view>
          </div>
        </div>
        <!-- <div class="form-group">
          <label>Mã đơn hàng</label>
          <div class="form-control">
            <input />
          </div>
        </div>
        <div class="form-group">
          <label>Trạng thái</label>
          <div class="form-control">
            <select>
              <option value="">Đã duyệt</option>
            </select>
          </div>
        </div> -->
      </form>
    </div>
    <div class="user" v-if="userLists == null">
      </div>
      <div v-else-if="userLists.length == 0">
        Không có dữ liệu
      </div>
    <div class="user"  v-else>
      <table-count
        :meta="meta"
        @refresh="firstLoading=true;fetchData(meta.page, true)"
      ></table-count>
     
      <div class="content">
        <table>
          <thead>
            <td>Mã</td>
            <td>Họ & tên</td>
            <td>Số điện thoại</td>
            <td>Phòng</td>
            <td>Ga-ra</td>
            <td>Vai trò</td>
            <td>Trạng thái</td>
            <td style="min-width: 120px;">Hành động</td>
          </thead>
          <tbody>
            <tr v-for="user of userLists" :key="user.id">
              <td>{{ user.id }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.phone }}</td>
              <td v-html="generateRoom(user.id)"></td>
              <td>{{ user.garage.name }}</td>

              <td>{{ UserRoleStringType[user.role] }}</td>
              <td v-html="user.status.toStatusHtml()"></td>
              <td>
                <router-link
                  :to="{ name: 'staff detail', params: { id: user.id } }"
                 class="btn  btn-sm btn-info"
                  >Chi tiết</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <paginate-view
      style="justify-content: right;"
      :page="meta.page"
      :totalPage="meta.totalPage"
      @onPage="meta.page = String($event)"
    ></paginate-view>
  </default-layout>
</template>

<script>
import PaginateView from "../../../components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import UserRoleStringType from "../../../enums/UserRoleStringType";
import TableCount from "../../../components/TableCount.vue";
import SelectLazyLoadingView from "../../../components/SelectLazyLoadingView.vue";
import { mapGetters } from "vuex";
import {isEmpty} from "lodash";
export default {
  components: {
    DefaultLayout,
    PaginateView,
    TableCount,
    SelectLazyLoadingView,
  },
  data() {
    return {
      UserRoleStringType,
      firstLoading: true,
      userLists: null,
      garageId: null,
      garageRoomData: null,
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
    };
  },
  created() {

    this.garageId = this.auth.garage ? this.auth.garage.id : null;
 
    if(localStorage.getItem('garageId') && !this.auth.garage){
      this.garageId = localStorage.getItem('garageId');
    }

    const search = new URLSearchParams(location.search);
    if (search.has("garage_id")) {
      this.garageId = search.get("garage_id");
    }
    
    this.fetchData();
  },

  computed: {
    auth() {
      return this.$store.getters["account/user"];
    },
  },
  watch: {
    "meta.page": function(val) {
      console.log(val);
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
    garageId(val) {
      localStorage.setItem('garageId', val)
      this.fetchData();
    },
  },
  methods: {
    async fetchData(page = 1) {
       this.$store.dispatch("global/loading", {
          isLoading: true,
        });
      try {
        let resp = await this.$store.dispatch("account/list", {
          page,
          garage_id: this.garageId,
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.firstLoading = false;
        this.userLists = resp.data;
        this.garageRoomData = resp.garageRoom;
        this.meta = resp.meta;
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      }finally{
         this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    generateRoom(userId){
      let rooms = this.garageRoomData[userId]
      let str = ''
      for(let item in rooms){
         str +=`<a class="tag" href="room/${item}" style="background: #5867dd">${rooms[item]}</a>`
      }
      return str
    }
  },
};
</script>

<style lang="scss" scoped>
.user {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
  }
}
</style>
