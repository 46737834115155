<template>
  <div class="header">
    <span>
      Hiển thị kết quả từ {{ (Number(meta.page) - 1) * 50 + 1 }} đến {{ (Number(meta.page) - 1) * 50 + 50}} trong
      tổng số {{ meta.total }} bản ghi
    </span>
    <div class="refresh " @click.prevent="$emit('refresh')" >
        <img src="@/assets/icons/refresh.png">
        <span>Tải lại</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["meta"],
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    flex: 1;
    color: #717171;
  }
  .refresh{
      border: 1px solid #ededed;
      border-radius: 4px;

      cursor: pointer;
      padding: 4px 10px;
      height: 30px;
      display: flex;
      align-items: center;
    
      img{
          margin-right: 4px;
          height: 80%;
          filter: invert(60%) sepia(7%) saturate(5908%) hue-rotate(180deg) brightness(85%) contrast(88%);
      }
      span{
            color: #468bce;
      }
  }
}
</style>
