import AuthApi from '@/state/api/auth_api';

export const state = {
  token: localStorage.getItem('_token')
};

export const mutations = {
  AUTH: (_, auth) => {
    localStorage.setItem(
      "userData",
      encodeURIComponent(JSON.stringify(auth.user))
    );
    localStorage.setItem(
      "_token",
      auth.access_token
    );
  },
};

export const getters = {
};

export const actions = {
  login(_, data) {
    return AuthApi.login(data).then(resp => {
      _.commit("AUTH", resp.auth);
      return resp
    })
  },
};