<template>
  <div>
    <div class="box-content">
      <div class="form-group">
        <label style="margin-bottom: 10px; font-weight: bold">Chuyển đến</label>
        <div
          class="input-control"
          style=" border: 1px solid #b9b9b9; padding: 8px; width: 500px;"
        >
          <select-lazy-loading-view
            dispatch="garage/lookup"
            :payload="payloadLookup"
            :toggleRefresh="toggleRefreshSelect"
            @onChange="garageTo = $event"
          ></select-lazy-loading-view>
        </div>
      </div>
      <div class="form-group mb-0">
        <label style="margin-bottom: 10px">Chuyển kho</label>
        <div>
          <div class="form-group">
            <div
              class="btn btn-primary"
              style="width: 200px;"
              @click.prevent="isShowProduct = true"
            >
              Chọn sản phẩm
            </div>
          </div>
        </div>
        <div  v-if="products && products !== null && products.length > 0" >
          <table>
            <thead>
            <td>STT</td>
            <td>Mã sản phẩm</td>
            <td>Mã nội bộ</td>
            <td>Tên</td>
            <td>Đơn vị</td>
            <td>Dung tích hiện tại</td>
            <td>Dung tích muốn chuyển</td>
            <td style="min-width: 120px;">Hành động</td>
            </thead>
            <tbody>
            <tr v-for="(row, index) of products" :key="index" :class="{'row-danger': row.amountSwap > row.amount}">
              <td>{{ index + 1 }}</td>
              <td>{{ row.product_id }}</td>
               <td>{{ row.product.code || '--' }}</td>
             <td style="min-width: 200px; max-width: 500px; word-break: break-word;">{{ row.product.name }}</td>
              <td>{{ row.product.unit }}</td>
              <td>{{ row.amount}}</td>
              <td>
                <div style="margin-bottom: 0; display: flex">
                  <div>
                    <input
                      type="number"
                      class="input-control"
                      v-model="row.amountSwap"
                    />
                  </div>
                </div>
              </td>
              <td>
                <button
                  class="btn btn-danger  btn-sm"
                  style="min-width: auto"
                  @click.prevent="deleteProduct(index)"
                >
                  Xóa
                </button>
              </td>
            </tr> 
            </tbody>
          </table>
        </div>
        <div
          class="input-control"
          style="border: 0; margin: auto"
          v-if="products && products !== null && products.length > 0"
        >
          <div class="form-group" style="margin-bottom: 0;margin-top: 10px">
            <div
              class="btn btn-primary"
              style="width: 200px;"
              @click.prevent="swapProduct"
            >
              Chuyển kho
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-garage-product-table
      :productIds="productIds"
      :garageId="parseInt(garageId)"
      :status="['soon','available']"
      :dispatch="'inventory/list'"
      v-if="isShowProduct"
      @close="isShowProduct = false"
      @chooseProducts="chooseProducts"
    >
    </modal-garage-product-table>
  </div>

</template>

<script>
import ModalGarageProductTable from "../modal/ModalGarageProductTable";
import SelectLazyLoadingView from "@/components/SelectLazyLoadingView";
import {isEmpty, map} from "lodash";

export default {
  components: {
    ModalGarageProductTable,
    SelectLazyLoadingView
  },
  props: ['garageId'],
  data(){
    return {
      isShowProduct: false,
      products: [],
      productIds: [],
      payloadLookup: {
        garageIdHide: null
      },
      garage: null,
      toggleRefreshSelect: false
    }
  },
  created() {
    this.payloadLookup.garageIdHide = this.garageId
  },
  watch: {
    products(val) {
      this.productIds = map(this.products, "product_id");
    },
    garageId(val){
      this.payloadLookup.garageIdHide = this.garageId
      this.toggleRefreshSelect = !this.toggleRefreshSelect
      this.products = []
    }
  },
  methods: {
    chooseProducts(val) {
      this.products = val
    },
    deleteProduct(index) {
      this.products.splice(index, 1);
    },
    async swapProduct(){
      if(!this.garageTo){
        this.$toastr.e(`Lỗi chưa chọn Ga-ra muốn chuyển!`);
        return;
      }
      let dataSwap = []
      for(let row of this.products){
        if(row.amountSwap){
          row.amountSwap = parseInt(row.amountSwap)
          if(row.amountSwap > row.amount) {
            this.$toastr.e(`Lỗi nhập dung tích vượt mức hiện tại!`);
            return;
          }
          dataSwap.push({
            product_id: row.product_id,
            row_id: row.id,
            amountSwap: row.amountSwap
          })
        }
      }
      if(isEmpty(dataSwap)){
        this.$toastr.e(`Lỗi chưa nhập dung tích đúng sản phẩm muốn chuyển!`);
        return;
      }

      this.$store.dispatch("global/loading", {
        isLoading: true,
        // msg: "Vui lòng đợi...",
      });
      try {
        await this.$store.dispatch("inventory/swap", { products: dataSwap, garageFrom: parseInt(this.garageId), garageTo: this.garageTo });
        this.$toastr.s(`Đã chuyển kho thành công`);
        this.products = []
         this.$emit('submit')
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.box-content {
  background: white;
  padding: 16px 18px;
  margin-bottom: 32px;
    min-height: 300px;

  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
  .row-danger{
    background: #f5c6cb;
  }
}
</style>