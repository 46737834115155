<template>
  <div class="timeline">
     <div class="search-box">
        <form>
          <div class="form-group">
            <label>Chọn khoảng thời gian</label>
            <div class="form-control" style="position: relative;">
              <input type="text" id="dateFilter" value="" />
              <div style="position: absolute; width: 700px;" id="parent_dateFilter"/>
            </div>
          </div>
          
        </form>
      </div>

    <table>
      <thead>
        <td>Mã đơn hàng</td>
        <td>Tên gói</td>
        <td>Check-in</td>
        <td>Check-out</td>
        <td>Công</td>
        <td>Công việc</td>
        <td>Loại</td>
      </thead>
      <tbody>
        <tr v-for="(item, index) of timelines" :key="index">
          
          <td><a style="padding: 4px 10px; background: #ffd67f; border-radius: 4px; color: #000;" :href="`/order/${item.order_id }/detail`">{{ item.order_id }}</a></td>
          <td>{{item.order.bundle.name}}</td>
          <td>{{ moment(Number(item.check_in)).format("YYYY-MM-DD HH:mm") }}</td>
          <td>{{ item.check_out? moment(Number(item.check_out)).format("YYYY-MM-DD HH:mm") : '--' }}</td>
          <td>{{item.work_num}}</td>
          <td>{{ item.type == "washing" ? "Rửa xe" : "Đánh bóng" }}</td>
          <td v-html="getSupportText(item.is_support)"></td>
        </tr>
      </tbody>
    </table>

    <paginate-view
      style="justify-content: right;"
      :page="meta.page"
      :totalPage="meta.totalPage"
      @onPage="meta.page = String($event)"
    ></paginate-view>
  </div>
</template>

<script>
import PaginateView from "../../../../components/PaginateView.vue";
import moment from 'moment';
import "daterangepicker/daterangepicker.css";

export default {
  components: { PaginateView },
  props: ["user_id"],
  data() {
    return {
      moment,
      isLoading: true,
      timelines: [],
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
    };
  },

  created() {
    this.fetchData();
  },
  mounted(){
    const thiss = this
    window.$('#dateFilter').daterangepicker({
      parentEl: "#parent_dateFilter",
      ranges: {
        'Hôm nay': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      "alwaysShowCalendars": true,
      "startDate": this.startDate,
      "endDate": this.endDate,
    }, function(start, end, label) {
      thiss.fetchData(1, start, end);
    });
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    async filter(){

    },
    getSupportText(isSupport) {
      if (isSupport) {
        return '<span style="padding: 4px 10px; background: #ffd67f; border-radius: 4px;">Hỗ trợ</span>';
      }
      return "<span>Làm chính</span>";
    },
    async fetchData(page = 1, start = null, end = null) {
      this.isLoading = true;
      if(start){
        this.startDate = start;
      }
      if(end){
        this.endDate = end
      }


      try {
        const resp = await this.$store.dispatch("account/timelines", {
          user_id: this.user_id,
          page,
          startDate: this.startDate.format('YYYY-MM-DD 00:00:00'),
          endDate: this.endDate.format('YYYY-MM-DD 23:59:59'),
        });
        this.timelines = resp.timelines;
        this.meta = resp.meta;
      } catch (e) {
        console.log(e, "XXXX");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.daterangepicker{
  table{
    thead{
      background: white !important;
    }
  }
  .btn{
    border-radius: unset;
  }
}
</style>
