import store from "@/state/store";


function isGuest(from, to, next) {
  const token = localStorage.getItem('_token')
  if (token) {
    window.$.ajaxSetup({
      headers: {
        'X-API-TOKEN': token
      }
    });
    next('/');
  } else {
    localStorage.removeItem('userData');
    next();
  }
};



function isAuth(role) {
  return async (from, to, next) => {
    const token = localStorage.getItem('_token')
    try {
      if (token) {

        // verify accessToken
        store.dispatch("account/me").catch(err => {
          if(err.statusText == 'abort') return;
          if(err.status != 401){
            // retry
            const retry = sessionStorage.getItem('retry') || 0
            if(retry == 3){
              next()
            }else{
              sessionStorage.setItem('retry', retry + 1)
              location.reload()
            }
          
            return;
          }
          localStorage.removeItem("_token");
          localStorage.removeItem("userData");
          location.reload();
        });

        // cacheUser
        const userData = store.getters['account/user'];
        if(role){
          if(userData && userData.role == role){
            next()
          }else{
            next('/')
          }
        }else{
          next();
        }

        return true;
      } else {
       throw new Error('unauthencation')
      }
    } catch (e) {
      localStorage.removeItem("_token");
      localStorage.removeItem("userData");
      next({
        name: "login",
      });
    }
  }

}

export default [{
    path: "*",
    beforeEnter: (from, to, next) => {
      next('/');
    },
  },


  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/auth/login'),
    beforeEnter: isGuest,
    meta: {
      redirectIfLoggedIn: true,
    },
  },


  {
    path: '/',
    name: 'dashboard',
    beforeEnter: isAuth(),
    component: () => import('./pages/dashboard'),
  },
  {
    path: '/order',
    name: 'order',
    beforeEnter: isAuth(),
    component: () => import('./pages/order'),
  },



  {
    path: '/order/create',
    name: 'order create',
    beforeEnter: isAuth(),
    component: () => import('./pages/order/create.vue'),
  },
  {
    path: '/order/:id/detail',
    name: 'order detail',
    beforeEnter: isAuth(),
    component: () => import('./pages/order/detail.vue'),
  },

  {
    path: '/customer',
    name: 'customer',
    beforeEnter: isAuth(),
    component: () => import('./pages/customer'),
  },


  {
    path: '/staff',
    name: 'staff',
    beforeEnter: isAuth(),
    component: () => import('./pages/staff'),
  },
  {
    path: '/staff/create',
    name: 'staff create',
    beforeEnter: isAuth(4),
    component: () => import('./pages/staff/create'),
  },
  {
    path: '/staff/:id/detail',
    name: 'staff detail',
    beforeEnter: isAuth(),
    component: () => import('./pages/staff/detail'),
  },
  {
    path: '/staff/report-timeline',
    name: 'staff reportTimeline',
    beforeEnter: isAuth(),
    component: () => import('./pages/staff/reportTimeline'),
  },

  {
    path: '/inventory',
    name: 'inventory',
    beforeEnter: isAuth(),
    component: () => import('./pages/inventory'),
  },

  {
    path: '/setting',
    name: 'setting',
    beforeEnter: isAuth(),
    component: () => import('./pages/setting'),
  },

  {
    path: '/setting/garage',
    name: 'garage',
    beforeEnter: isAuth(),
    component: () => import('./pages/garage'),
  },
  {
    path: '/setting/garage/create',
    name: 'garage create',
    beforeEnter: isAuth(),
    component: () => import('./pages/garage/create'),
  },
  {
    path: '/setting/garage/:id/detail',
    name: 'garage detail',
    beforeEnter: isAuth(4),
    component: () => import('./pages/garage/detail'),
  },
  {
    path: '/garage',
    name: 'garage detail manage',
    beforeEnter: isAuth(3),
    component: () => import('./pages/garage/detail'),
  },

  {
    path: '/room',
    name: 'room',
    beforeEnter: isAuth(),
    component: () => import('./pages/room'),
  },
  {
    path: '/room/:id',
    name: 'room detail',
    beforeEnter: isAuth(),
    component: () => import('./pages/room/detail'),
  },
  {
    path: '/setting/product',
    name: 'product',
    beforeEnter: isAuth(),
    component: () => import('./pages/product'),
  },
  {
    path: '/setting/product/create',
    name: 'product create',
    beforeEnter: isAuth(),
    component: () => import('./pages/product/create'),
  },
  {
    path: '/setting/product/:id/detail',
    name: 'product detail',
    beforeEnter: isAuth(),
    component: () => import('./pages/product/detail'),
  },

  {
    path: '/setting/bundle',
    name: 'bundle',
    beforeEnter: isAuth(),
    component: () => import('./pages/bundle'),
  },
  {
    path: '/setting/bundle/create',
    name: 'bundle create',
    beforeEnter: isAuth(),
    component: () => import('./pages/bundle/create'),
  },
  {
    path: '/setting/bundle/:id/detail',
    name: 'bundle detail',
    beforeEnter: isAuth(),
    component: () => import('./pages/bundle/detail'),
  },

  {
    path: '/profile',
    name: 'profile',
    beforeEnter: isAuth(),
    component: () => import('./pages/profile'),
  },

  // {
  //   path: '/term',
  //   name: 'termAndCondition',
  //   component: () => import('./pages/term-condition'),
  // },
  // {
  //   path: '/indicate',
  //   name: 'indicate',
  //   beforeEnter: (from, to, next) => {
  //     if (!localStorage.getItem('agree')) {
  //       next('/');
  //     } else {
  //       next();
  //     }
  //   },
  //   component: () => import('./pages/indicate'),
  // },
  // {
  //   path: '/email-verify',
  //   name: 'email-verify',
  //   beforeEnter: (from, to, next) => {
  //     if(location.href.getParameterByName('expired')){
  //       next();
  //     }else{
  //       validateUserInfo(from, to, next);
  //     }

  //   },
  //   component: () => location.href.getParameterByName('expired') ? import('./pages/email-verify-expired') : import('./pages/email-verify') ,
  // },

  // {
  //   path: '/verification',
  //   name: 'verification',
  //   beforeEnter: validateUserInfo,
  //   component: () => import('./pages/verification'),
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   beforeEnter: validateUserInfo,
  //   component: () => import('./pages/login'),
  // },
  // {
  //   path: '/personal-detail',
  //   name: 'personal-detail',
  //   beforeEnter: validateUserInfo,
  //   component: () => import('./pages/personal-detail'),
  // },
  // {
  //   path: '/empolyment',
  //   name: 'empolyment',
  //   beforeEnter: validateUserInfo,
  //   component: () => import('./pages/empolyment'),
  // },

  // {
  //   path: '/investment-profile',
  //   name: 'investment-profile',
  //   beforeEnter: validateUserInfo,
  //   component: () => import('./pages/investment-profile'),
  // },

  // {
  //   path: '/fatca',
  //   name: 'fatca',
  //   beforeEnter: validateUserInfo,
  //   component: () => import('./pages/FATCA'),
  // },

  // {
  //   path: '/upload-document',
  //   name: 'upload-document',
  //   beforeEnter: validateUserInfo,
  //   component: () => import('./pages/upload-document'),
  // },
  // {
  //   path: '/success',
  //   name: 'success',
  //   component: () => import('./pages/success'),
  // },

  // {
  //   path: '/request',
  //   name: 'request',
  //   component: () => import('./pages/request-success'),
  // },

]
