<template>
  <div class="paginate" v-if="totalPage > 1">
    <div class="page">
      <div
        v-if="totalPage > 5"
        class="prev"
        @click="onSelection(page - 1)"
        :class="{ disabled: page == 1 }"
      >
        <img
          style="transform: rotate(180deg);"
          src="@/assets/icons/arrow_right.png"
        />
      </div>
      <div
        class="page-item"
        v-for="(item, index) in pageData"
        :key="index"
        :class="{ current: page == item.index }"
        @click="onSelection(item.index)"
      >
        <span> {{ item.name }}</span>
      </div>
      <div
        v-if="totalPage > 5"
        class="next"
        @click="onSelection(page + 1)"
        :class="{ disabled: page >= totalPage }"
      >
        <img src="@/assets/icons/arrow_right.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    page: [String, Number],
    totalPage: [String, Number],
    checkUrl: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { pageData: [] };
  },
  created() {
    // check page from url
    // let tmpPage = 1;
    // if (this.checkUrl) {
    //   let url = new URL(window.location.href);
    //   if (url.searchParams.has("page") && url.searchParams.get("page") > 1) {
    //     tmpPage = url.searchParams.get("page");
    //   }
    // }
    this.syncPage();

  },
  watch: {
    totalPage() {
      this.syncPage();
    },
    page() {
      this.syncPage();
    },
  },
  methods: {
    syncPage() {
      this.pageData = [];
      let currentPage = Number(this.page);
      if (this.totalPage <= 5) {
        this.pageData.push(
          ...Array.from({ length: this.totalPage }, (_, p) => {
            return {
              index: String(Number(p) + 1),
              name: Number(p) + 1,
            };
          })
        );
      } else if (currentPage + 5 > this.totalPage) {
        this.pageData.push(
          ...[
            this.totalPage - 4,
            this.totalPage - 3,
            this.totalPage - 2,
            this.totalPage - 1,
            this.totalPage,
          ].map((p) => {
            return {
              index: String(p),
              name: p,
            };
          })
        );
      } else {
        this.pageData.push(
          ...[
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage + 3,
            currentPage + 4,
          ].map((p) => {
            return {
              index: String(p),
              name: p,
            };
          })
        );
      }
    },
    onSelection(page) {
      // this.syncPage();
      // push to url

      this.$emit("onPage", page);
    },
    goToPage(e) {
      let page = parseInt(Number(e.target.value));
      this.onSelection(page);
    },
  },
};
</script>
<style lang="scss" scoped>
.paginate {
  background: white;
  padding-bottom: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .goto {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-right: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;

      color: #717171;
    }
    input {
      width: 60px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid #ececec;
      padding: 5px 10px;
      text-align: center;
    }
  }
  .page {
    display: flex;
    flex-direction: row;
    align-items: center;
    .prev,
    .next {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .disabled {
      color: #ececec;

      img {
        opacity: 0.2;
      }
    }
    .page-item {
      margin: 6px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-content: center;
      border-radius: 50%;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      cursor: pointer;
      span {
        margin: auto;
      }
    }
    .page-item.current {
      background: #000000;
      color: white;
    }
  }
}
</style>
