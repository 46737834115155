<template>
  <div class="content">
    <div class="profile">
      <div class="form-group">
        <label>Họ & tên</label>
        <div class="input-control" style="height: 36px;">
          {{ user.name }}
        </div>
      </div>

      <div class="form-group">
        <label>Số điện thoại</label>
        <div class="input-control" style="height: 36px;">
          {{ user.phone }}
        </div>
      </div>

      <div class="form-group">
        <label>Email</label>
        <div class="input-control" style="height: 36px;">
          {{ user.email }}
        </div>
      </div>
    </div>
    <div style="height: 63px;" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      name: this.user.name,
      email: this.user.name,
      phone: this.user.phone,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },

  methods: {
    async udpateInfo() {},
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  width: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  select,
  textarea,
  input {
    border: 0;
    background: transparent;
    width: 100%;
  }
  .input-control {
    border: 1px solid #b9b9b9;
    padding: 8px;
  }
}
</style>
