<template>
  <modal-base @close="$emit('close')" :title="title">
    <div class="search-box">
      <form @submit.prevent="search">
        <div class="form-group">
          <label>Sản phẩm</label>
          <div class="form-control">
            <input v-model="name" placeholder="Mã nội bộ, tên sản phẩm"/>
          </div>
        </div>
        <div class="form-group ">
          <label>Loại sản phẩm</label>
          <div class="form-control">
            <select v-model="type" @change="search">
              <option value="">Tất cả</option>
              <option v-for="(option,index) in ProductTypeLookup" v-bind:value="option.value" :key="index">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <div class="action">
        <button class="btn btn-submit" @click.prevent="search">Tìm kiếm</button>
        <button class="btn btn-info" @click.prevent="resetFilter">Thiết lập lại</button>
      </div>
    </div>
    <div style="height: 16px" />
    <div class="table-wrap">
      <div class="product">
        <table-count :meta="meta" @refresh="fetchData"></table-count>
        <div class="content">
          <table>
            <thead>
            <td>Chọn</td>
            <td>Mã sản phẩm</td>
            <td>Mã nội bộ</td>
            <td>Tên</td>
            <td>Loại sản phẩm</td>
            <td>Đơn vị</td>
            <td>Trạng thái</td>
            </thead>
            <tbody>
            <tr v-if="!rows || rows.length < 1">
              <td colspan="6"><span class="text-danger"> Không có dữ liệu </span></td>
            </tr>
            <tr v-else v-for="row of rows" :key="row.id">
              <td>
                <input type="checkbox" :value="row.id" v-model="checkedIds">
              </td>
              <td>{{ row.id }}</td>
              <td>{{ row.code || '--' }}</td>
              <td style="min-width: 200px; max-width: 500px; word-break: break-word;">{{ row.name }}</td>
              <td v-html="generateTagHtml(row.type, ProductTypeLookup)"></td>
              <td>{{ row.unit }}</td>
              <td v-html="row.status.toStatusHtml()"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate-view
          style="justify-content: right;"
          :page="meta.page"
          :totalPage="meta.totalPage"
          :checkUrl="false"
          @onPage="meta.page = String($event)"
        ></paginate-view>
      </div>
      <div style="height: 12px" />
      <div class="text-center">
        <button
          v-if="this.rows.length >0"
          class="btn btn-primary"
          style="width: 200px; margin: auto"
          @click="chooseProduct"
          :disabled="checkedIds.length < 1"
        >
          Chọn sản phẩm
        </button>
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase.vue";
import PaginateView from "@/components/PaginateView";
import {isEmpty, keyBy} from "lodash";
import TableCount from './TableCount.vue';
import ProductStatusEnum from '@/enums/ProductStatusEnum'
import ProductTypeLookup from '@/enums/ProductTypeLookup'
import {generateTagHtml} from "@/utils/convert";

export default {
  components: { ModalBase, PaginateView, TableCount },
  props: {
    productIds: {
      type: Array,
      default: () => []
    },
    productCurrent: {
      type: [Array,Object],
      default: null
    },
    dispatch: {
      type: String,
      default: 'product/list'
    },
    garageId: {
      type: String,
      default: null
    },
    status: {
      type: Array,
      default: () => ['active']
    },
    title: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      rows: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      checkedIds: this.productIds,
      products: [],
      name: null,
      type: '',
      ProductTypeLookup,
      generateTagHtml
    };
  },
  created() {
    this.fetchData(1);
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let resp = await this.$store.dispatch(this.dispatch, {
          page,status: this.status, garageId: this.garageId, name: this.name, type: this.type
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.rows = resp.data;
        this.meta = resp.meta;
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    async chooseProduct(){
      let products = []
      if(!isEmpty(this.checkedIds)){
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Vui lòng đợi...",
        });
        try {
          const queryProducts = await this.$store.dispatch("product/getByCondition", {
            ids: this.checkedIds
          });
          if(!isEmpty(this.productCurrent)){
            this.productCurrent = keyBy(this.productCurrent,'product_id')
          }
          for(let product of queryProducts){
            if(product.status === ProductStatusEnum.DEACTIVE){
              this.$toastr.e(`Sản phẩm ${product.name} ở trạng thái không hoạt động`);
              return;
            }
            let amount = 1
            if(!isEmpty(this.productCurrent) && this.productCurrent[product.id]) {
              amount = this.productCurrent[product.id].amount
            }

            products.push({
              id: product.id,
              product_id: product.id,
              product: {
                price: product.price,
                code: product.code,
                name: product.name,
                unit: product.unit,
                type: product.type,
              },
              amount: amount,
              amount_on_package: 1,
              amount_on_unit: 1, //dung tích
              alert_min_amount: product.alert_min_amount,
              unit: product.unit,
            })
          }
        } catch (e) {
          this.$toastr.e(e.responseJSON.message);
        } finally {
          this.$store.dispatch("global/loading", {
            isLoading: false,
          });
        }
      }

      this.$emit('chooseProducts', products.sort((a, b) => {
        if ( a.code < b.code ){
          return -1;
        }
        if ( a.code > b.code ){
          return 1;
        }
        return 0;
      }))
      this.$emit('close')
    },
    async search(){
      await this.fetchData(1)
    },
    async resetFilter(){
      this.name = null
      this.type = ''
      await this.fetchData(1)
    }
  },
};
</script>

<style lang="scss" scoped>
.product {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    width: 100%;
    table {
      border-collapse: collapse;
      border: 1px solid #ededed;
      width: 100%;
      font-size: 14px;
      line-height: 23px;
      color: #717171;
      td,
      th {
        font-weight: 500;
        padding: 10px;
        border: 1px solid #ddd;
      }
      tr td {
        .control {
          min-height: 40px;
          border: 1px solid #ededed;
        }
      }
    }
  }
}

.table-wrap{
  min-width: 900px;
  min-height: 300px
}

.search-box{
  padding: 0;
}

@media screen and (max-width: 991px) {
  .table-wrap{
    min-width: unset;
  }
}

</style>
