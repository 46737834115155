<template>
  <modal-base @close="$emit('close')" :title="title">
    <div class="search-box">
      <form @submit.prevent="search">
        <div class="form-group">
          <label>Sản phẩm</label>
          <div class="form-control">
            <input v-model="keyword" placeholder="Mã, tên sản phẩm"/>
          </div>
        </div>
        <div class="form-group ">
          <label>Loại sản phẩm</label>
          <div class="form-control">
            <select v-model="type" @change="search">
              <option value="">Tất cả</option>
              <option v-for="(option,index) in ProductTypeLookup" v-bind:value="option.value" :key="index">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <div class="action">
        <button class="btn btn-submit" @click.prevent="search">Tìm kiếm</button>
        <button class="btn btn-info" @click.prevent="resetFilter">Thiết lập lại</button>
      </div>
    </div>
    <div class="table-wrap">
      <div class="product" v-if="this.rows">
        <table-count :meta="meta" @refresh="fetchData"></table-count>
        <div class="content">
          <table>
            <thead>
            <td>Chọn</td>
            <td>Mã sản phẩm</td>
            <td>Mã nội bộ</td>
            <td>Tên</td>
            <td>Loại sản phẩm</td>
            <td>Tồn tối thiểu</td>
            <td>Tại buồng</td>
            <td>Tồn kho chính</td>
            <td>Đơn vị</td>
            </thead>
            <tbody>
            <tr v-if="!rows || rows.length < 1">
              <td colspan="7"><span class="text-danger"> Không có dữ liệu </span></td>
            </tr>
            <tr v-else v-for="row of rows" :key="row.id">
              <td>
                <input type="checkbox" :value="row.product_id" v-model="checkedIds">
              </td>
              <td>{{ row.product_id }}</td>
              <td>{{ row.product.code || '--' }}</td>
              <td style="min-width: 200px; max-width: 500px; word-break: break-word;">{{ row.product.name }}</td>
              <td v-html="generateTagHtml(row.product.type, ProductTypeLookup)"></td>
              <td>{{row.product.alert_min_amount.formatNumber()}}</td>
              <td>{{row.room_products.length > 0 ? row.room_products[0].amount.formatNumber() : 0}}</td>
              <td>{{ (row.amount - row.amount_delivered).formatNumber() }}</td>
              <td>{{ row.product.unit }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate-view
          style="justify-content: right;"
          :page="meta.page"
          :totalPage="meta.totalPage"
          :checkUrl="false"
          @onPage="meta.page = String($event)"
        ></paginate-view>
      </div>
      <div style="height: 12px" />
      <div
        v-if="this.rows.length >0"
        class="btn btn-primary"
        style="width: 200px; margin: auto"
        @click="chooseProductInventory"
      >
        Chọn sản phẩm
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "@/components/ModalBase";
import PaginateView from "@/components/PaginateView";
import {isEmpty,keyBy} from "lodash";
import TableCount from '@/components/TableCount.vue';
import ProductTypeLookup from '@/enums/ProductTypeLookup'
import {generateTagHtml} from "@/utils/convert";
export default {
  components: { ModalBase, PaginateView, TableCount },
  props: {
    productIds: {
      type: Array,
      default: () => []
    },
    productCurrent: null,
    dispatch: {
      type: String,
      default: 'inventory/list'
    },
    garageId: {
      type: Number,
      default: null
    },
    roomId: {
      type: Number,
      default: null
    },
    status: {
      type: Array,
      default: () => ['active']
    },
    title: {
      type: String,
      default: 'Sản phẩm trong kho'
    },
  },

  data() {
    return {
      rows: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      checkedIds: this.productIds,
      products: [],
      keyword: null,
      type: '',
      ProductTypeLookup,
      generateTagHtml
    };
  },
  created() {
    this.fetchData(1);
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let resp = await this.$store.dispatch(this.dispatch, {
          roomId: this.roomId,
          page,status: this.status, garageId: this.garageId, keyword: this.keyword, type: this.type, hasAmountDelivered: true
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.rows = resp.data;
        this.meta = resp.meta;
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    async chooseProductInventory(){
      let products = []
      if(!isEmpty(this.checkedIds)){
        this.$store.dispatch("global/loading", {
          isLoading: true,
          // msg: "Đang xử lý...",
        });
        try {
          const queryProducts = await this.$store.dispatch("inventory/getByCondition", {
            productId: this.checkedIds, garageId: this.garageId, roomId: this.roomId
          });
          if(!isEmpty(this.productCurrent)){
            this.productCurrent = keyBy(this.productCurrent,'product_id')
          }
          for(let item of queryProducts){
            let amountSwap = 1
            if(!isEmpty(this.productCurrent) && this.productCurrent[item.product_id]) {
              amountSwap = this.productCurrent[item.product_id].amountSwap
            }
            products.push({
              id: item.id,
              product_id: item.product_id,
              product: item.product,
              room_products: item.room_products,
              amount_delivered: item.amount_delivered,
              amount: item.amount,
              amountSwap,
            })
          }
        } catch (e) {
          console.log(1111,e);
          this.$toastr.e(e.responseJSON.message);
        } finally {
          this.$store.dispatch("global/loading", {
            isLoading: false,
          });
        }
      }
      this.$emit('chooseProducts', products.sort((a, b) => {
        if ( a.code < b.code ){
          return -1;
        }
        if ( a.code > b.code ){
          return 1;
        }
        return 0;
      }))
      this.$emit('close')
    },
    async search(){
      await this.fetchData(1)
    },
    async resetFilter(){
      this.keyword = null
      this.type = ''
      await this.fetchData(1)
    }
  },
};
</script>

<style lang="scss" scoped>
.product {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    table {
      border-collapse: collapse;
      border: 1px solid #ededed;
      width: 100%;
      font-size: 14px;
      line-height: 23px;
      color: #717171;
      td,
      th {
        font-weight: 500;
        padding: 10px;
        border: 1px solid #ddd;
      }
      tr td {
        .control {
          min-height: 40px;
          border: 1px solid #ededed;
        }
      }
    }
  }
}

.table-wrap{
  min-width: 900px;
  min-height: 300px
}

@media screen and (max-width: 991px) {
  .table-wrap{
    min-width: unset;
  }
}

</style>
