window.$ = window.jQuery = require('jquery');

window.$.ajaxSetup({
    headers: { 'x-api-token': localStorage.getItem('_token') }
});

window.$(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
    if('Unauthorized' == thrownError){
        localStorage.removeItem('_token');
        location.reload();
    }
});

let xhrOnlyLists = {};
window.$.ajaxOnce = function(url, options){
    if( xhrOnlyLists[url]){
        xhrOnlyLists[url].abort();
    }
    xhrOnlyLists[url] = window.$.ajax(url, options)
    return xhrOnlyLists[url]
}

window.$.getOnce = function( url, data, callback, type){
    if( xhrOnlyLists[url]){
        xhrOnlyLists[url].abort();
    }
    xhrOnlyLists[url] = window.$.get(url, data, callback, type)
    return xhrOnlyLists[url]
}

require('select2');
require('daterangepicker')
require('./utils/extensions');
require('./utils/constant');
require('./utils/validate-rule');