export default {
  //
  lists: (query = {}) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/bundle/lists'), query);
  },

  info: (id) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat(`/bundle/${id}/info`));
  },
}
