<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <label>Xác nhận duyệt đơn #{{ orderId }}</label>
        <ul>
          <li>
            Bạn không thể sử đổi thông tin đơn hàng được nữa.
          </li>
          <li>
            Thông báo sẽ được gửi đến các nhân viên được phân công.
          </li>
        </ul>
      </div>
      <div style="height: 20px" />
      <div class="btn btn-primary" @click="$emit('ok')">Duyệt đơn</div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
export default {
  components: { ModalBase },
  props: ["orderId"],
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
  }
}
.btn-primary {
  border-color: #00c292;
  background: #00c292;
  color: white !important;
}
</style>
