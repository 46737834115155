<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <p>Rút sản phẩm {{ deleteObj.name }} về garage</p>
      </div>
      <div style="height: 20px"/>
      <div class="form">
        <div class="right">
          <div class="form-group">
            <label style="display: flex; justify-content: space-between;">
              <span>Dung tích ({{deleteObj.unit}})</span> <span>Tối đa: {{deleteObj.amountMax}}</span>
            </label>
            <div class="input-control" :class="{error: errors.has('amount')}">
              <input
                name="amount"
                v-model.trim="amount"
                v-validate="`required|numeric|digits_between:1,${deleteObj.amountMax}`"
                type="text"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex" style="justify-content: end">
        <div class="btn" style="margin-right: 20px" @click="$emit('close')">Hủy</div>
        <div class="btn btn-submit" @click="confirm">Đồng ý</div>
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "@/components/ModalBase.vue";
import InputView from "@/components/InputView.vue";
import {mapActions} from "vuex";

export default {
  components: { ModalBase, InputView },
  props: ['deleteObj'],
  provide() {
    return { parentValidator: this.$validator };
  },
  data(){
    return {
      amount: null
    }
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    async confirm(){
      const result = await this.validateAll(this);
      if (!result) return;
      this.$emit('ok', {id: this.deleteObj.id, amount: this.amount});
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
  }
}
.btn-primary {
  border-color: #00c292;
  background: #00c292;
  color: white !important;
}
b{
  font-weight: bold;
  color: #3c3c3c;
}
.form-group {
  width: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  select,
  textarea,
  input {
    border: 0;
    background: transparent;
    color: #000000;
    width: 100%;
  }
  .input-control {
    border: 1px solid #b9b9b9;
    padding: 8px;
  }
}
</style>
