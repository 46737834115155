import { render, staticRenderFns } from "./InventoryAdd.vue?vue&type=template&id=4b2eecac&scoped=true&"
import script from "./InventoryAdd.vue?vue&type=script&lang=js&"
export * from "./InventoryAdd.vue?vue&type=script&lang=js&"
import style0 from "./InventoryAdd.vue?vue&type=style&index=0&id=4b2eecac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b2eecac",
  null
  
)

export default component.exports