<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <label>Reset mật khẩu</label>
        <div style="height: 20px" />
        <div style="line-height: 24px;">
          Mật khẩu cũ sẽ bị thay đổi bạn có chắc muốn reset lại mật khẩu mới
          không
        </div>
      </div>
      <div style="height: 20px" />
      <button class="btn btn-submit" @click="resetPassword()">
        Reset mật khẩu
      </button>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
export default {
  components: { ModalBase },
  props: ["email"],
  methods: {
    async resetPassword() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang yêu cầu",
      });
      try {
        let resp = await this.$store.dispatch("account/resetPassword", {
          email: this.email,
        });
        this.$emit("close");
        this.$emit("onChangePassword", resp.data.password);
      } catch (e) {
        this.$toastr.e("Lỗi yêu cầu. Vui lòng thử lại");
        setTimeout(() => {
          // location.reload();
        }, 1500);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          msg: "Đang yêu cầu",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
    b {
      font-weight: 600;
    }
  }
}
.btn-primary {
  border-color: #000000;
  background: #000000;
  color: white !important;
}
</style>
