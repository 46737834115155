export default {
  staffAll: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/garage/staffs'), query);

  },
  //admin
  list: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/garage/admin/list'), query);
  },
  create(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/garage/admin/create'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
  detail: (payload) => {
    return window.$.get(`${process.env.VUE_APP_API_URL}/garage/admin/detail/${payload.id}`);
  },
  update({id, user_id, name, status, address}) {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/garage/admin/update/${id}`,
      data: JSON.stringify({user_id, name, status, address}),
      contentType: 'application/json'
    });
  },
  lookup: (payload) => {
    return window.$.get(`${process.env.VUE_APP_API_URL}/garage/admin/lookup`,payload);
  },
  
  addRoom: ({garage_id, name, type}) => {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/garage/${garage_id}/addRoom`,
      data: JSON.stringify({name, type}),
      contentType: 'application/json'
    });
  },

  roomLists: ({garage_id, name, page}) => {
    return window.$.getOnce(`${process.env.VUE_APP_API_URL}/garage/${garage_id}/roomLists`, {name, page});
  },

  roomDetail: ({id}) => {
    return window.$.getOnce(`${process.env.VUE_APP_API_URL}/garage/admin/room/${id}`);
  },

  roomMemberUpdate: ({roomId, garageId, userIds}) => {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/garage/admin/room/${roomId}/updateMember`,
      data: JSON.stringify({garageId, userIds}),
      contentType: 'application/json'
    });
  },

  roomUpdate: ({roomId, status, garageId, name}) => {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/garage/admin/room/${roomId}/roomUpdate`,
      data: JSON.stringify({status, garageId, name}),
      contentType: 'application/json'
    });
  },

  roomDelete(payload) {
    return window.$.ajax({
      method: 'DELETE',
      url: `${process.env.VUE_APP_API_URL}/garage/admin/roomDelete/${payload.id}`,
      contentType: 'application/json'
    });
  },
};
