<template>
  <div class="default">
    <div class="bg-header">
      <div class="header">
        <div class="mb-menu" v-click-outside="mbMenu" @click="isLeftMenu = true">
          <img
            src="@/assets/icons/menu.png"
            style="height: 50%; object-fit: contain"
          />
        </div>
        <img src="@/assets/logo.png" class="logo" alt="iWash" />
        <div class="space"></div>
        <div class="profile" v-click-outside="outside">
          <div
            style="display: flex;height: 100%;align-items: center;border-radius: 100px;background: #ededed;padding: 4px;font-size: 14px;"
            @click.prevent="isShowMenu = !isShowMenu"
          >
            <img src="@/assets/default_avatar.png" style="height: 100%" />
            <div style="width: 4px;" />
            <div style="white-space: nowrap;">{{ user.name }}</div>
            <div style="width: 10px;" />
          </div>
          <div class="menu-profile" v-if="isShowMenu">
            <div
              @click.prevent="$router.push({ name: 'profile' })"
              class="item"
            >
              Thông tin cá nhân
            </div>
            <div class="divider" />
            <div class="item" @click.prevent="logout()">Đăng xuất</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div style="height: 5px" />
      <section>
        <menu-header></menu-header>
      </section> -->
    <div style="display: flex; min-height: calc(100vh - 61px)">
   
      <left-menu
        v-show="isLeftMenu || !isMobile()"
        style="top: 60px; width: 250px; left: 0; bottom: 0; height: calc(100vh - 61px);"
        @onSelectMenu="title = $event"
      ></left-menu>

      <div class="route-container">
        <section style="padding: 15px; flex: 1">
          <slot />
        </section>
        <div class="footer">
          <section style="display: flex">
            <div style="padding: 15px;color: #a5a5a5;">
              Copyright © 2022 iWash Car Studio VN
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../../components/LeftMenu.vue";
import MenuHeader from "../../components/MenuHeader.vue";
export default {
  components: { MenuHeader, LeftMenu },
  data() {
    return {
      isShowMenu: false,
      isLeftMenu: false,
      title: "HOME",
    };
  },

  computed: {
    ...mapGetters("account", ["user"]),
  },

  methods: {
    isMobile(){
      return window.innerWidth <= 1024;
    },
    async logout() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang đăng xuất",
      });
      try {
        await this.$store.dispatch("account/logout");
      } catch (e) {
      } finally {
        location.reload();
      }
    },
    mbMenu(){
      setTimeout(() =>{
        this.isLeftMenu = false;
      }, 100)
    },
    outside(e) {
      this.isShowMenu = false;
    },
    logout() {
      localStorage.removeItem("userData");
      localStorage.removeItem("_token");

      this.$store.dispatch("account/logout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.default {
  flex-direction: column;
  display: flex;
  .bg-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom: 1px solid #ededed;
    // box-shadow: 0px 4px 8px 2px rgb(84, 84, 84);
    .header {
      width: 100%;
      height: 60px;
      margin: auto;
      display: flex;
      justify-content: center;
      .title-menu {
        margin: auto 20px;
      }
      .space {
        flex: 1;
      }
      .profile {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 10px;
        cursor: pointer;
        position: relative;
        .menu-profile {
          background: white;
          padding: 5px 15px;
          border-radius: 10px;
          top: 56px;
          width: 240px;
          right: 15px;
          position: absolute;
          z-index: 1000;
          box-shadow: 2px 3px 9px 1px #ededed;
          .item {
            padding: 12px;
          }
          .divider {
            height: 1px;
            width: 100%;
            background: #ededed;
          }
        }
      }
    }
  }
}

section {
  width: 100%;
}
.footer {
  height: 50px;
}

.logo {
  height: 100%;
  width: 250px;
  object-fit: contain;
}

.route-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 250px);
  height: calc(100vh - 61px);
  overflow: auto;
}
.mb-menu {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .bg-header{
    position: fixed !important;
    top: 0;
    right: 0 !important;
    left: 0;
    width: 100% !important;
    z-index: 100;
  }
  .left-menu{
    position: fixed;
    top: 60px !important;
    left: 0;
  }
  .route-container {
    width: 100%;
    min-width: 768px;
      margin-top: 60px !important;
  }

  .mb-menu {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }
  .logo{
    width: 100%;
  }
}
</style>
