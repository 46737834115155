<template>
  <div>
    <div class="box-content">
      <div class="form-group mb-0">
        <label style="margin-bottom: 10px">Nhập sản phẩm vào phòng</label>
        <div>
          <div class="form-group">
            <div
              class="btn btn-primary"
              style="width: 200px;"
              @click.prevent="isShowProduct = true"
            >
              Chọn sản phẩm
            </div>
          </div>
        </div>
        <div  v-if="products && products !== null && products.length > 0" >
          <table>
            <thead>
              <td>Mã sản phẩm</td>
              <td>Mã nội bộ</td>
              <td style="min-width: 250px">Tên</td>
              <td>Loại sản phẩm</td>
              <td style="min-width: 80px;">Tồn tối thiểu</td>
              <td style="min-width: 80px;">Hiện tại</td>
              <td style="min-width: 80px;">Dung tích (chuyển vào buồng)</td>
              <td style="min-width: 80px;">Tối đa</td>
              <td style="min-width: 120px;">Hành động</td>
            </thead>
            <tbody>
            <tr v-for="(row, index) of products" :key="row.key">
              <td>{{ row.product_id }}</td>
              <td>{{ row.product.code || '--' }}</td>
              <td style=" max-width: 500px;  word-break: break-word;">{{ row.product.name }}</td>
              <td v-html="generateTagHtml(row.product.type, ProductTypeLookup)"></td>
              <td>{{row.product.alert_min_amount.formatNumber()}}</td>
              <td>{{row.room_products.length > 0 ? row.room_products[0].amount.formatNumber() : 0}}</td>
              <td>
                <div style="margin-bottom: 0; display: flex">
                  <div style="margin-right: 10px">
                    <cleave
                      :options="options.number"
                      :class="{error: errors.first(`Dung tích_${row.product_id}_${row.key}`)}"
                      class="input-control input-dynamic"
                      v-model="row.amountSwap"
                      :name="`Dung tích_${row.product_id}_${row.key}`"
                      v-validate="`required|numeric|digits_between:1,${row.amount - row.amount_delivered}`"
                      :data-vv-as="`Dung tích`"
                    />
<!--                     <div class="has-error" v-if="errors.has(`Dung tích_${row.product_id}_${row.key}`)">
                      {{ errors.first(`Dung tích_${row.product_id}_${row.key}`) }}
                    </div>-->
                  </div>
                  <div style="padding-top: 6px; width: 20px">
                    {{ row.product.unit }}
                  </div>
                </div>
              </td>
              <td>{{ (row.amount - row.amount_delivered).formatNumber() }}</td>
              <td>
                <button
                  class="btn btn-danger  btn-sm"
                  style="min-width: auto"
                  @click.prevent="deleteProduct(index)"
                >
                  Xóa
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div
          class="input-control"
          style="border: 0; margin: auto"
          v-if="products && products !== null && products.length > 0"
        >
          <div class="form-group" style="margin-bottom: 0;margin-top: 10px">
            <div
              class="btn btn-primary  btn-sm"
              style="width: 200px;"
              @click.prevent="addToInventory"
            >
              Lưu & Tải về báo cáo
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-garage-product-table
      :productCurrent="products"
      :productIds="productIds"
      :garageId="parseInt(garageId)"
      :roomId="roomId"
      :status="['soon','available']"
      :dispatch="'inventory/list'"
      v-if="isShowProduct"
      @close="isShowProduct = false"
      @chooseProducts="chooseProducts"
    >
    </modal-garage-product-table>
  </div>

</template>

<script>
import ModalGarageProductTable from "../modal/ModalGarageProductTable";
import { mapActions } from 'vuex';
import {cloneDeep, map, uniqueId} from "lodash";
import ProductTypeLookup from '@/enums/ProductTypeLookup'
import {generateTagHtml} from "@/utils/convert";
import Cleave from 'vue-cleave-component';
import moment from 'moment';

export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  components: {
    ModalGarageProductTable, Cleave
  },
  props: ['garageId','roomId'],
  data(){
    return {
      isShowProduct: false,
      products: [],
      productIds: [],
      ProductTypeLookup,
      generateTagHtml,
      options:{
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 0,
        },
      }
    }
  },
  watch: {
    products(val) {
      this.productIds = map(this.products, "product_id");
    },
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    chooseProducts(val) {
      val = val.map(product => { return {...product, ...{key: product.product_id + '_' + uniqueId()}} })
      this.products = cloneDeep(val);
    },
    async addToInventory() {
      const result = await this.validateAll(this);
      if (!result) {
        return;
      }

      try {
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Vui lòng đợi...",
        });
         let res = await this.$store.dispatch("inventory/roomProductAdd", {
          products: this.products,
          garageId: this.garageId,
          roomId: this.roomId
        })

        this.products = [];


        let FILE = window.URL.createObjectURL(new Blob([res]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `Nhập kho phòng ${this.roomId} ${moment(Date.now()).format('yyyy-MM-DD_HH_mm_ss')}.pdf`);
        document.body.appendChild(docUrl);
        docUrl.click();

        this.$emit('submit')
      } catch (e) {
        console.log(e)
        // this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    deleteProduct(index) {
      this.products.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.box-content {
  background: white;
  padding: 16px 18px;
  margin-bottom: 32px;

  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
    .input-dynamic{
      min-width: 100px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .box-content {
    .form-group{
      .input-dynamic{
        width: 100px;
      }
    }
  }
}
</style>