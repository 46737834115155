export default {
  //
  info: ({
    id
  }) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat(`/order/${id}/info`));
  },

  addSupport: (body) =>{
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/order/addSupport'),
      data: JSON.stringify(body),
      contentType: 'application/json'
    });
  },


  all: (query = {}) => {
    return window.$.getOnce(process.env.VUE_APP_API_URL.concat('/order/all'), query);
  },

  histories: ({order_id}) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat(`/order/${order_id}/histories`));
  },


  create: (body) => {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/order/fastCreate'),
      data: JSON.stringify(body),
      contentType: 'application/json'
    });
  },

  updatePayment: (body) => {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/order/updatePayment'),
      data: JSON.stringify(body),
      contentType: 'application/json'
    });
  },

  cancel: ({
    id,
    note
  }) => {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat(`/order/${id}/cancel`),
      data: JSON.stringify({
        note
      }),
      contentType: 'application/json'
    });
  },

  browse: (body) => {
    return window.$.ajax({
      method: 'PUT',
      url: process.env.VUE_APP_API_URL.concat(`/order/browse`),
      data: JSON.stringify(body),
      contentType: 'application/json'
    });
  },

  update: (body) => {
    return window.$.ajax({
      method: 'PUT',
      url: process.env.VUE_APP_API_URL.concat(`/order/${body.order_id}/update`),
      data: JSON.stringify(body),
      contentType: 'application/json'
    });
  },

  complete: (body) => {
    return window.$.ajax({
      method: 'PUT',
      url: process.env.VUE_APP_API_URL.concat(`/order/${body.order_id}/complete`),
      data: JSON.stringify(body),
      contentType: 'application/json'
    });
  },
  latest: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/order/latest'), query);
  },


  noteList: ({order_id}) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat(`/order/${order_id}/note-list`));
  }
};
