<template>
  <div class="tab-view">
    <div
      class="tab-item"
      v-for="tab in tabLists"
      @click.prevent="setCurrentTab(tab.id)"
      :key="tab.id"
      :class="{
        active: tab.id == currentTab,
      }"
    >
      {{ tab.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["tabLists", "current"],
  data() {
    return {
      currentTab: null,
    };
  },
  created() {
    this.currentTab = this.current ?? this.tabLists[0].id;
  },

  watch:{
    current(val){
      this.currentTab = val
    }
  },

  methods: {
    setCurrentTab(tab) {
      // this.currentTab = tab;
      this.$emit("onSelection", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-view {
  display: flex;
    border-bottom: 1px solid #ededed;
  .tab-item {
      cursor: pointer;
    padding: 10px 20px;
        border-bottom: 1px solid transparent;

  }
  .tab-item.active {
    border-bottom: 2px solid #b62126;
    background: white;
    color: #b62126;
  }
}
</style>
