<template>
  <default-layout>
    <div v-if="!user" class="create-user">
      <div class="loader" style="margin: auto" />
    </div>
    <div style="display: flex; flex-direction: column" v-else>
      <div class="create-user" style="position: relative;">
        <div class="safe" v-if="auth.role != 4" />
        <div style="display: flex;align-items: center;">
          <label style="flex: 1">Thông tin nhân viên #{{ user.id }}</label>
          <div style="display: flex" v-if="auth.role == 4">
            <div
              class="btn btn-primary"
              style="width: 200px; margin: auto"
              @click.prevent="isShowConfirmResetPassword = true"
            >
              Lấy lại mật khẩu
            </div>
            <div style="width: 20px;" />
            <div
              class="btn btn-submit"
              style="width: 200px; margin: auto"
              @click.prevent="updateUser()"
            >
              Lưu thay đổi
            </div>
          </div>
        </div>
        <div style="height: 24px;" />
        <div class="form">
          <div class="left">
            <div class="form-group">
              <div class="input-control" style="border: 0">
                <div class="form-group">
                  <label>Họ tên</label>
                  <div class="input-control">
                    <input v-model.trim="user.name" />
                  </div>
                </div>
                <div class="form-group">
                  <label>Email*</label>
                  <div class="input-control">
                    <input v-model.trim="user.email" />
                  </div>
                </div>
                <div class="form-group">
                  <label>Số điện thoại</label>
                  <div class="input-control">
                    <input v-model.trim="user.phone" />
                  </div>
                </div>
                <div class="form-group">
                  <label>Địa chỉ</label>
                  <div class="input-control">
                    <input v-model.trim="user.address" />
                  </div>
                </div>

                <!--              <div class="form-group">
                  <label>Vai trò</label>
                  <v-select v-model="user.role" :options="roleOption" :searchable="false" :clearable="false"/>
                </div>-->
              </div>
            </div>
          </div>
          <div style="width: 20px;" />
          <div class="right">
            <div class="form-group">
              <div class="input-control" style="border: 0">
                <div class="form-group">
                  <label>Trạng thái</label>
                  <div class="input-control">
                    <div class="div-select">
                      <select v-model="user.status">
                        <option value="active">Hoạt động</option>
                        <option value="blocked">Nghỉ việc</option>
                      </select>
                    </div>
                  </div>
                    <div v-if="user.status == 'blocked' && user.status != userStatus" class="has-error">
                      Chú ý: Nhân viên sẽ bị xóa khỏi ở tất cả các phòng đang tham gia. Bạn phải cài đặt thủ công lại tất cả.
                    </div>
                </div>
                <div class="form-group">
                  <label>Vai trò</label>
                  <div style="display: flex;height: 36px; padding: 0 10px;">
                    <div style="margin: auto 0;">
                      {{ UserRoleStringType[user.role] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Gagage</label>
                  <div class="input-control" v-if="user.role < 3">
                    <select-lazy-loading-view
                      :current="user.garage ? user.garage.id : null"
                      dispatch="garage/lookup"
                      @onChange="user.garage_id = $event"
                      disabled
                    ></select-lazy-loading-view>
                  </div>
                  <div
                    v-else
                    style="display: flex;height: 36px;padding: 0 10px;"
                  >
                    <div style="margin: auto 0;">
                      {{ user.garage ? user.garage.name : "--" }}
                    </div>
                  </div>
                </div>
                <div style="height: 24px;" />

                <!--              <div class="form-group">
                  <label>Vai trò</label>
                  <v-select v-model="user.role" :options="roleOption" :searchable="false" :clearable="false"/>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 24px;" />
      <div class="create-user" style="min-height: 500px">
        <tab-view
          :tabLists="tabLists"
          :current="currentTab"
          @onSelection="currentTab = $event"
        ></tab-view>
        <div style="height: 24px;" />

        <div class="tab-content">
          <overview-tab
            v-if="currentTab == 0"
            :user_id="user.id"
          ></overview-tab>
          <timeline-tab
            v-if="currentTab == 1"
            :user_id="user.id"
          ></timeline-tab>
          <work-tab :user_id="user.id" v-if="currentTab == 2"></work-tab>
        </div>
      </div>

      <modal-login-info
        :email="email"
        :passwordRoot="passwordRoot"
        v-if="isShowConfirm"
        @close="isShowConfirm = false"
      ></modal-login-info>
      <modal-confirm-reset-password
        :email="email"
        v-if="isShowConfirmResetPassword"
        @onChangePassword="onChangePassword($event)"
        @close="isShowConfirmResetPassword = false"
      ></modal-confirm-reset-password>
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import SelectLazyLoadingView from "../../../components/SelectLazyLoadingView.vue";
import ModalLoginInfo from "./modal/ModalLoginInfo.vue";
import UserRoleStringType from "../../../enums/UserRoleStringType";
import TabView from "../../../components/TabView.vue";
import ModalConfirmResetPassword from "./modal/ModalConfirmResetPassword.vue";
import OverviewTab from "./tabs/OverviewTab.vue";
import TimelineTab from "./tabs/TimelineTab.vue";
import WorkTab from "./tabs/WorkTab.vue";

export default {
  components: {
    DefaultLayout,
    ModalLoginInfo,
    TabView,
    ModalConfirmResetPassword,
    OverviewTab,
    SelectLazyLoadingView,
    TimelineTab,
    WorkTab,
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data() {
    return {
      UserRoleStringType,
      tabLists: [
        { id: 0, name: "Tổng quan" },
        { id: 2, name: "Đang được giao phó" },
        { id: 1, name: "Chấm công" },
      ],
      currentTab: 0,
      isHidePayment: false,
      isShowConfirm: false,
      isShowConfirmResetPassword: false,
      bundleId: null,
      user: null,
      userStatus: null,
      passwordRoot: null,
    };
  },
  created() {
    this.fetchUser();
  },
  computed: {
    email() {
      return this.$route.params.email || this.user.email;
    },
    auth() {
      return this.$store.getters["account/user"];
    },
  },

  watch:{
    'user.status':(val) => {
      console.log("XXX", val)
    }
  },
  methods: {
    onChangePassword(password) {
      this.isShowConfirm = true;
      this.passwordRoot = password;
    },
    async fetchUser() {
      try {
        this.user = await this.$store.dispatch("account/detail", {
          id: this.$route.params.id,
        });
        this.userStatus  = this.user.status;
      } catch (e) {
        console.log(e, "XXXX")
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
        window.history.go(-1);
      }
    },
    async resetPassword() {},
    async updateUser() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang yêu cầu...",
      });
      try {
        await this.$store.dispatch("account/update", {
          user: this.user,
        });
        this.$toastr.s(`Đã lưu thông tin nhân viên`);
        this.fetchUser();
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          msg: "Đang yêu cầu...",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-user {
  background: white;
  padding: 24px;

  .form {
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    .left {
      flex: 1;
      border: 0px;
    }
    .right {
      flex: 0.75;
      border: 0px;
    }
    .form-group {
      width: auto;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      label {
        margin-bottom: 8px;
        font-weight: bold;
      }
      select,
      textarea,
      input {
        border: 0;
        background: transparent;
        width: 100%;
      }
      .input-control {
        border: 1px solid #b9b9b9;
        padding: 8px;
      }
    }
  }
}
</style>
