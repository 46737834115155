<template>
  <default-layout>
    <div class="search-box">
      <form>
        <div class="form-group" v-if="auth.role == 4">
          <label>Chọn ga-ra</label>
          <div class="form-control">
            <select-lazy-loading-view
              dispatch="garage/lookup"
              :hasAll="false"
              :current="garageId"
              @onChange="garageId = $event"
            ></select-lazy-loading-view>
          </div>
        </div>
      </form>
    </div>

    <div class="box-container">
      <div class="header">
        <label class="title">Danh sách phòng</label>
        <div class="action">
          <button
            v-if="auth.role == 4"
            class="btn btn-submit"
            style="width: 200px; margin: auto"
            @click.prevent="isShowAddRoom = true"
          >
            Thêm phòng
          </button>
        </div>
      </div>
      <div class="form">
        <div class="right">
          <div v-if="roomLists == null">
            <div class="loader" style="margin: auto" />
          </div>
          <div style="display: flex; flex-direction: column;" v-else>
            <table-count
              :meta="roomMeta"
              @refresh="fetchRoomLists"
            ></table-count>
            <div class="content">
              <table>
                <thead>
                  <td>Mã</td>
                  <td>Tên</td>
                  <td>Nhân viên</td>
                  <td width="200">Trạng thái</td>
                  <td width="200">Qrcode</td>
                 <td style="min-width: 150px;">Hành động</td>
                </thead>
                <tbody>
                  <tr v-if="!roomLists || roomLists.length < 1">
                    <td colspan="6">
                      <span class="text-danger"> Không có dữ liệu </span>
                    </td>
                  </tr>
                  <tr v-for="(room, index) in roomLists" :key="index">
                    <td>{{ room.id }}</td>
                    <td
                      style="min-width: 200px; max-width: 500px;word-break: break-word;"
                    >
                      {{ room.name }}
                    </td>
                    <td
                      style="min-width: 200px; max-width: 500px;word-break: break-word;"
                    >
                      <span>
                        {{__getUserMap(room.users)}}
                      </span>
                    </td>
                    <td>
                      <label
                        class="switch"
                        style="margin: 0"
                        @click.prevent="switchRoomStatus(index)"
                      >
                        <input
                          type="checkbox"
                          :checked="room.status == 'active'"
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td>
                      <div
                        class="btn-link"
                        style="cursor: pointer;"
                        @click.prevent="downloadImage(room.id)"
                      >
                        Tải xuống
                      </div>
                    </td>
                    <td >
                      <div class="d-flex btn-group">
                        <a
                          class="btn btn-sm btn-info"
                          :href="`/room/${room.id}`"
                        >
                        Chi tiết
                        </a>
                        <a
                          v-if="auth.role == 4"
                          class="btn btn-danger btn-sm "
                          @click.prevent="showConfirmDelete(room.id, room.name)"
                          >Xóa</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <paginate-view
              style="justify-content: right;"
              :page="roomMeta.page"
              :totalPage="Math.floor(roomMeta.total / 10)"
              @onPage="roomMeta.page = String($event)"
            ></paginate-view>
          </div>
        </div>
      </div>
      <modal-add-room
        v-if="isShowAddRoom"
        :garageId="garageId"
        @reload="fetchRoomLists()"
        @close="isShowAddRoom = false"
      ></modal-add-room>

      <modal-confirm-delete-room
        :deleteObj="deleteObj"
        v-if="isShowConfirmDelete"
        @close="isShowConfirmDelete = false"
        @ok="deleteRoom"
      >
      </modal-confirm-delete-room>
    </div>
  </default-layout>
</template>

<script>
import QrCodeWithLogo from "qrcode-with-logos";

import PaginateView from "../../../components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import UserRoleStringType from "../../../enums/UserRoleStringType";
import TableCount from "../../../components/TableCount.vue";
import SelectLazyLoadingView from "../../../components/SelectLazyLoadingView.vue";
import ModalAddRoom from "./modal/ModalAddRoom.vue";
import ModalConfirmDeleteRoom from "@/router/pages/room/modal/ModalConfirmDeleteRoom";
export default {
  components: {
    DefaultLayout,
    PaginateView,
    TableCount,
    SelectLazyLoadingView,
    ModalAddRoom,
    ModalConfirmDeleteRoom
  },
  data() {
    return {
      isShowAddRoom: false,
      UserRoleStringType,
      firstLoading: true,
      garageId: 0,
      roomLists: null,
      roomName: null,
      roomMeta: {
        page: 1,
        totalPage: 1,
        total: 0,
      },
      deleteObj: null,
      isShowConfirmDelete: false,
    };
  },
  created() {
    this.garageId = this.auth.garage ? this.auth.garage.id : 0;
    // if (this.$route.query.garage_id && !this.auth.garage) {
    //   this.garageId = this.$route.query.garage_id;
    // }

    if(localStorage.getItem('garageId') && !this.auth.garage){
      this.garageId = localStorage.getItem('garageId');
    }

  },
  computed: {
    auth() {
      return this.$store.getters["account/user"];
    },
  },
  watch: {
    "roomMeta.page": function(val) {
      this.$nextTick(() => {
        this.fetchRoomLists(val);
      });
    },
    garageId(val) {
      if (val === 0) return;
      // const url = new URL(window.location);
      // url.searchParams.set("garage_id", val);
      // window.history.pushState({}, "", url);
      localStorage.setItem('garageId', val);
      // update status
      this.fetchRoomLists();
    },
  },

  methods: {
    __getUserMap(arr){
      return arr.map(userData => {
        return userData.user.name
      }).join(', ')
    },
    switchRoomStatus(index) {
      const room = this.roomLists[index];

      const currentStatus = room.status;

      if (room.status == "active") {
        room.status = "inactive";
      } else {
        room.status = "active";
      }

      this.$store
        .dispatch("garage/roomUpdate", {
          roomId: room.id,
          garageId: room.garage_id,
          status: room.status,
        })
        .catch((e) => {
          room.status = currentStatus;
        });
    },
    downloadImage(roomId) {
      const now = `${Date.now()}`;
      new QrCodeWithLogo({
        content: `${btoa(now + "qrcode").substring(0, 6)}.${now.substring(
          7
        )}.${roomId}`,
        nodeQrCodeOptions: {
          margin: 1,
        },
        width: 320,
      }).downloadImage(`qrcode_${roomId}`);
    },
    async fetchRoomLists(page = 0) {
      if (page == 0) {
        this.roomLists = null;
        this.roomMeta = {
          page: 1,
          totalPage: 1,
          total: 0,
        };
      }
      try {
        const resp = await this.$store.dispatch("garage/roomLists", {
          garage_id: this.garageId,
          page,
        });

        this.roomLists = resp.data;
        this.roomMeta = resp.meta;
      } catch (e) {
        console.log(e);
      }
    },
    showConfirmDelete(id, name) {
      this.deleteObj = {
        id, name
      };
      this.isShowConfirmDelete = true;
    },
    async deleteRoom(id) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        // msg: "Vui lòng đợi...",
      });
      try{
        await this.$store.dispatch("garage/roomDelete", { id });
        this.isShowConfirmDelete = false;
        await this.fetchData();
      }catch (e){
        this.$toastr.e(e.responseJSON.message);
      }finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
  }
}
.btn-group{
  a:last-child{
    margin-left: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .btn-group {
    a:last-child {
      margin-left: 0;
    }
  }
}
</style>
