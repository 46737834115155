import MediaApi from '../api/media_api';

export const state = {};

export const mutations = {};

export const getters = {};

export const actions = {
  upload(_, {
    file
  }) {
    return MediaApi.uploadFile(file);
  }
};