<template>
  <default-layout>
    <div class="box-container">
      <div class="header">
        <label class="title">Thêm ga-ra</label>
        <div class="action"></div>
      </div>
      <div class="form">
        <div class="right">
          <div class="form-group">
            <div class="input-control box-form-input">
              <input-view
                mName="Tên ga-ra"
                :value="garage.name"
                @input="garage.name = $event"
                maxlength="250"
                validate="required|vn-text"
              ></input-view>
               <div class="form-group">
                <label>Đia chỉ</label>
                <div class="input-control">
                  <input name="Đia chỉ" v-model.trim="garage.address" />
                </div>
                <div class="has-error" v-if="errors.has('Đia chỉ')">
                  {{errors.first('Đia chỉ')}}
                </div>
              </div>
              <!-- <div class="form-group">
                <label>Trạng thái</label>
                <div class="form-control">
                  <select v-model="garage.status">
                    <option v-for="option in statusOptions" v-bind:value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div> -->
              <div class="form-group">
                <label>Quản lý <span>* </span></label>
                <div class="input-control" style="padding: 0" :class="{error: errors.has('Quản lý')}">
                <staff-list-view
                  mName="Quản lý"
                  validate="required"
                  :role="2"
                  @input="garage.user_id = $event"
                ></staff-list-view>
                </div>
                <div class="has-error" v-if="errors.has('Quản lý')">
                  Quản lý ga-ra không được để trống
                </div>
              </div>
              <div style="height: 24px;" />
              <div
                class="btn btn-submit"
                style="width: 200px;"
                @click.prevent="createGara()"
              >
                Tạo ga-ra
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import StaffListView from "../../../components/StaffListView.vue";
import GaraStatusLookup from "@/enums/GaraStatusLookup";
import InputView from "@/components/InputView.vue";
import {mapActions} from "vuex";

export default {
  components: {
    DefaultLayout,
    StaffListView,
    InputView
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data() {
    return {
      garage: {
        name: null,
        user_id: null,
        address: null,
        status: GaraStatusLookup[0].value
      },
      statusOptions: GaraStatusLookup,
      errorUserId: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("global", ["validateAll"]),

    async createGara() {
      const result = await this.validateAll(this);
      if (!result) return;

      if(!this.garage.user_id){
        this.errorUserId = true
        return;
      }

      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang tạo ga-ra...",
      });
      try {
        const garage = await this.$store.dispatch("garage/create", this.garage);
        this.$toastr.s(`Đã tạo ga-ra`);
        this.$nextTick(() => {
          this.$router.push({
            name: "garage detail",
            params: garage,
          });
        });
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          msg: "Đang yêu cầu...",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
}
</style>
