import BundleApi from '../api/bundle_api';

export const state = {};

export const mutations = {};

export const getters = {
  
};

export const actions = {
  list(_, data) {
    return BundleApi.list(data);
  },

  create(_, data) {
    return BundleApi.create(data)
  },

  detail(_, data) {
    return BundleApi.detail(data)
  },

  update(_, data) {
    return BundleApi.update(data)
  },

  priceCosts(_, data){
    return BundleApi.priceCosts(data)
  }
};
