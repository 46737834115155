<template>
  <modal-base @close="$emit('close')" :title="title">
    <div class="container">
      <div style="height: 20px"/>
      <form>
        <div class="form-group">
          <label>Chọn khoảng thời gian</label>
          <div class="form-control">
            <input type="text" id="dateFilter" value="" />
          </div>
        </div>
      </form>

      <div class="d-flex" style="justify-content: end;padding: 0 12px;">
        <div class="btn" style="margin-right: 10px" @click="$emit('close')">Hủy</div>
        <div class="btn" style="display:flex; margin-right: 10px" @click="exportData('xlsx')"><img src="@/assets/icons/excel.png" width="20">&nbsp;&nbsp;Excel</div>
        <div class="btn" style="display:flex;" @click="exportData('pdf')"><img src="@/assets/icons/pdf.png" width="20">&nbsp;&nbsp;PDF</div>
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "@/components/ModalBase.vue";
import {mapActions} from "vuex";
import moment from "moment";
import "daterangepicker/daterangepicker.css";
export default {
  components: { ModalBase },
  provide() {
    return { parentValidator: this.$validator };
  },
  data(){
    return {
      title: 'Xuất báo cáo doanh thu',
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    }
  },
  mounted() {
    const thiss = this
    window.$('#dateFilter').daterangepicker({
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      "alwaysShowCalendars": true,
      "startDate": this.startDate,
      "endDate": this.endDate
    }, function(start, end, label) {
      thiss.changeDate(start,end)
    });
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    changeDate(start,end){
      this.startDate = start
      this.endDate = end
    },
    async confirm(){
      const result = await this.validateAll(this);
      if (!result) return;
      this.$emit('ok', {id: this.deleteObj.id, amount: this.amount});
      this.$emit('close');
    },
    async exportData(type = 'xlsx'){
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let startDate = this.startDate.format('YYYY-MM-DD')
        let endDate = this.endDate.format('YYYY-MM-DD')
        let res = await this.$store.dispatch("report/exportOrderCompleted", {
          type,
          startDate,
          endDate
        });
        this.firstLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `Doanh_thu_${startDate}_${endDate}.${type}`);
        document.body.appendChild(docUrl);
        docUrl.click();
      } catch (e) {
        let msg = 'Server gặp sự cố. Vui lòng thử lại sau'
        if(e.status){
          msg = 'Không có dữ liệu!'
        }
        this.$toastr.e(msg);
      }finally{
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
  }
}
.btn-primary {
  border-color: #00c292;
  background: #00c292;
  color: white !important;
}
b{
  font-weight: bold;
  color: #3c3c3c;
}
.form-group {
  width: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  select,
  textarea,
  input {
    border: 0;
    background: transparent;
    color: #000000;
    width: 100%;
  }
  .input-control {
    border: 1px solid #b9b9b9;
    padding: 8px;
  }
}
</style>

<style lang="scss">
.daterangepicker{
  table{
    thead{
      background: white !important;
    }
  }
  .btn{
    border-radius: unset;
  }
  z-index: 9999 !important;
}
</style>
