<template>
  <modal-base @close="$emit('close')">
    <div class="content">
      <label style="font-weight: bold;">Thêm phòng</label>
      <div style="height: 20px" />

      <form>
        <input-view
          mName="Tên phòng"
          :value="name"
          @input="name = $event"
          maxlength="250"
          validate="required|vn-text"
        ></input-view>
        <div class="form-group">
          <label>Loại phòng</label>
          <div class="form-control">
            <select v-model="type">
              <option
                v-for="(item, index) of typeOptions"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}</option
              >
            </select>
          </div>
        </div>
      </form>
      <div style="height: 12px" />
      <div class="btn btn-submit" style=" margin: auto;" @click="create()">
        Thêm phòng
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
import { mapActions } from "vuex";
import InputView from "@/components/InputView";
export default {
  components: { ModalBase, InputView },
  props: ["garageId"],
  data() {
    return {
      name: null,
      type: "washing",
      typeOptions: [
        {
          id: "washing",
          name: "Rửa xe",
        },
        {
          id: "polish",
          name: "Đánh bóng",
        },
      ],
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    async create() {
      const result = await this.validateAll(this);
      if (!result) {
        return;
      }

      try {
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Đang lưu dữ liệu...",
        });

        await this.$store.dispatch("garage/addRoom", {
          garage_id: this.garageId,
          name: this.name,
          type: this.type
        });
        this.$toastr.s("Thêm phòng thành công");
        this.$emit("close");
        this.$emit("reload");
      } catch (e) {
        this.$toastr.e("Lỗi cập nhập. Vui lòng thử lại");
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: 100px;
  .control {
    min-height: 40px;
    border: 1px solid #ededed;
  }
}
</style>
