<template>
  <default-layout>
    <div class="box-container">
      <div class="header">
        <label class="title">Thông tin sản phẩm</label>
        <div class="action"></div>
      </div>
      <div class="form" v-if="isLoading">
        <div class="loader" style="margin: auto;"/>
      </div>
      <div class="form" v-else>
        <div class="right">
          <div class="form-group">
            <div class="input-control box-form-input">
               <input-view
                mName="Mã nội bộ"
                :value="product.code"
                @input="product.code = $event"
                maxlength="250"
                validate="required"
              ></input-view>

              <input-view
                mName="Tên sản phẩm"
                :value="product.name"
                @input="product.name = $event"
                maxlength="250"
                validate="required|vn-text"
              ></input-view>
              <div class="form-group">
                <label>Loại sản phẩm *</label>
                <div class="form-control">
                  <select v-model="product.type">
                    <option
                      v-for="(option, index) in ProductTypeLookup"
                      v-bind:value="option.value"
                      :key="index"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
              <input-view
                mName="Đơn giá (Giá 1 ml)"
                subtitle="Lưu ý: Giá sẽ làm thay đổi GIÁ VẬT LIỆU của gói"
                :value="product.price"
                @input="product.price = $event"
                validate="required|numeric"
                :cleaveOption="options.number"
              ></input-view>
              
              <div class="form-group">
                <label>Chọn đơn vị*</label>
                <div class="form-control">
                  <select v-model="product.unit">
                    <option
                      v-for="option of ProductUnitType"
                      :key="option"
                      :value="option"
                      >{{ option }}</option
                    >
                  </select>
                </div>
              </div>
              <input-view
                mName="Dung tích tối thiểu để gửi thông báo"
                :value="product.alert_min_amount"
                @input="product.alert_min_amount = $event"
                validate="required|numeric"
                :cleaveOption="options.number"
              ></input-view>
              <div class="form-group">
                <label>Trạng thái</label>
                <div class="form-control">
                  <select v-model="product.status">
                    <option v-for="(option, index) in statusOptions" :key="index" v-bind:value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div style="height: 24px;" />
              <div
                class="btn btn-submit"
                style="width: 200px;"
                @click.prevent="updateProduct()"
              >
                Lưu thay đổi
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import ProductUnitType from "@/enums/ProductUnitType";
import ProductStatusLookup from '@/enums/ProductStatusLookup'
import ProductTypeLookup from '@/enums/ProductTypeLookup'
import InputView from "@/components/InputView.vue";
import {mapActions} from "vuex";

export default {
  components: {
    DefaultLayout, InputView
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data() {
    return {
      isLoading: true,
      product: {
        id: this.$route.params.id,
      },
      ProductUnitType,
      statusOptions: ProductStatusLookup,
      ProductTypeLookup,
      options:{
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 0,
        },
      }
    };
  },
  created() {
    this.fetchDetail();
  },
  mounted() {},
  methods: {
    ...mapActions("global", ["validateAll"]),
    async fetchDetail() {
      try {
        this.product = await this.$store.dispatch("product/detail", {
          id: this.product.id,
        });
        this.isLoading = false;
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
         window.history.go(-1)
      }finally{
      }
    },
    async updateProduct() {
      const result = await this.validateAll(this);
      if (!result) return;
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang yêu cầu...",
      });
      try {
        const product = await this.$store.dispatch("product/update", {
          product: this.product,
        });
        this.$toastr.s(`Đã cập nhật thông tin`);
        this.$nextTick(() => {
          this.$router.push({
            name: "product",
            params: product,
          });
        });
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
}
</style>
