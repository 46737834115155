export default {
  //
  uploadFile: (file) => {

    let formData = new FormData();
    formData.append('fileData', file);
    return new Promise((resolve, reject) => {
      window.$.post({
        type: 'POST',
        url: process.env.VUE_APP_API_URL.concat('/media/upload'),
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
        success: resolve,
        error: reject,
      });
    });

  },
};
