<template>
  <div class="bundler-info" v-if="bundleId">
    <div class="loader icon-loader" v-if="isLoading"></div>
    <div class="has-error" v-else-if="errorMessage">
      <div style="margin: 0 10px">{{ errorMessage }}</div>
      <a @click.prevent="fetchData(bundleId)" style="color: #b42020;"
        >Thử lại</a
      >
    </div>
    <div v-else class="content">
      <div>
        Giá: <span style="font-weight: bold">{{ info.bundler.price.formatCurrency() }}đ</span>
      </div>
      <ul>
        <li
          class="item"
          v-for="item of info.stores"
          :key="item.id"
          style="line-height: 22px;     word-break: break-word;"
        >
          {{ item.product.name }} ( {{ item.amount.formatCurrency() }}{{ item.product.unit }} )
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["bundleId"],
  data() {
    return {
      isLoading: true,
      info: null,
      errorMessage: null,
    };
  },
  watch: {
    bundleId(val) {
      this.fetchData(val);
    },
  },
  created(){
    if(this.bundleId){
      this.fetchData(this.bundleId)
    }
  },
  methods: {
    async fetchData(id) {
      this.isLoading = true;
      this.errorMessage = null;
      this.info = await this.$store
        .dispatch("bundler/info", {
          id,
        })
        .catch((e) => {
          console.log(e);
          this.errorMessage = "Không lấy được dữ liệu gói";
          return null;
        });
      if (this.info) {
        this.$emit("onPrice", this.info.bundler.price);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bundler-info {
  .has-error {
    display: flex;
    height: 34px;
    align-items: center;
    font-size: 13px;
  }
}
.icon-loader {
  width: 34px;
  height: 34px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
}
</style>
