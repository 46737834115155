<template>
  <default-layout>
    <div>Setting</div>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
export default {
  components: { DefaultLayout },
};
</script>
