<template>
  <default-layout>
    <div class="search-box">
      <form @submit.prevent="search">
        <div class="form-group">
          <label>Tên gói</label>
          <div class="form-control">
            <input v-model="name" />
          </div>
        </div>
        <div class="form-group">
          <label>Trạng thái</label>
          <div class="form-control">
            <select v-model="status" @change="search">
              <option value="">Tất cả</option>
              <option
                v-for="(option, index) in statusOptions"
                :key="index"
                v-bind:value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <div class="action">
        <button class="btn btn-submit" @click.prevent="search">Tìm kiếm</button>
        <button class="btn btn-info" @click.prevent="resetFilter">
          Thiết lập lại
        </button>
        <router-link :to="{ name: 'bundle create' }" style="color: #00c292">
          <button class="btn btn-primary">+ Thêm gói</button>
        </router-link>
      </div>
    </div>
    <div class="garage">
      <table-count :meta="meta" @refresh="fetchData"></table-count>
      <div class="content">
        <table>
          <thead>
            <td>Mã</td>
            <td>Tên gói</td>
            <td>Giá vật liệu</td>
            <td>Giá bán</td>
            <td>Trạng thái</td>
            <td>QR Code</td>
            <td style="min-width: 120px">Hành động</td>
          </thead>
          <tbody>
            <tr v-if="!rows || rows.length < 1">
              <td colspan="6">
                <span class="text-danger"> Không có dữ liệu </span>
              </td>
            </tr>
            <tr v-else v-for="row of rows" :key="row.id">
              <td>{{ row.id }}</td>
              <td
                style="
                  min-width: 200px;
                  max-width: 500px;
                  word-break: break-word;
                "
              >
                {{ row.name }}
              </td>
              <td>
                <span v-if="priceCosts[row.id] == null">
                 <div class="loader icon-loader"></div>
                </span>
                <span v-else>
                  {{priceCosts[row.id].formatCurrency() }}
                </span>
              </td>
              <td>{{ row.price.formatCurrency() }}</td>
              <td v-html="generateStatusHtml(row.status, statusOptions)"></td>
              <td>
                <div
                  class="btn btn-link"
                  @click.prevent="downloadImage(row.id)"
                >
                  Tải xuống
                </div>
              </td>
              <td>
                <a
                  style="color: #00c292"
                  :href="`/setting/bundle/${row.id}/detail`"
                >
                  <button class="btn btn-info btn-sm">Chi tiết</button>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginate-view
        style="justify-content: right"
        :page="meta.page"
        :totalPage="meta.totalPage"
        @onPage="meta.page = String($event)"
      ></paginate-view>
    </div>
  </default-layout>
</template>

<script>
import QrCodeWithLogo from "qrcode-with-logos";

import PaginateView from "../../../components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import TableCount from "../../../components/TableCount.vue";
import BundleStatusLookup from "@/enums/BundleStatusLookup";
import { generateStatusHtml } from "@/utils/convert";
import { isEmpty } from "lodash";

export default {
  components: { DefaultLayout, PaginateView, TableCount },
  data() {
    return {
      firstLoading: true,
      rows: [],
      priceCosts: {},
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      name: null,
      status: "",
      statusOptions: BundleStatusLookup,
      generateStatusHtml,
    };
  },
  created() {
    this.fetchData(1);
  },

  watch: {
    "meta.page": function (val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    downloadImage(bundleId) {
      const now = `${Date.now()}`;
      new QrCodeWithLogo({
        content: `${btoa(now + "qrcode").substring(0, 6)}.${now.substring(
          7
        )}.${bundleId}`,
        nodeQrCodeOptions: {
          margin: 1,
        },
        width: 320,
      }).downloadImage(`qrcode_bundle_${bundleId}`);
    },

    async fetchPriceCosts(bundles){
      const ids = bundles.map(bundle => bundle.id);
       try {
        const resp = await this.$store.dispatch("bundle/priceCosts", {
          ids
        });

        this.priceCosts = resp;
        
      } catch (e) {
        this.$toastr.e('Lỗi lấy bảng giá gốc vui lòng liên hệ bộ phận kĩ thuật.');
      }
    },

    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Vui lòng đợi...",
      });
      try {
        let resp = await this.$store.dispatch("bundle/list", {
          page,
          name: this.name,
          status: this.status,
        });
        if (isEmpty(resp.data) && page !== 1) {
          await this.fetchData(1);
          return;
        }
        this.rows = resp.data;
        this.meta = resp.meta;

        this.fetchPriceCosts(this.rows);
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    async search() {
      await this.fetchData();
    },
    async resetFilter() {
      this.status = "";
      this.name = null;
      await this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.garage {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
  }
}
 .icon-loader {
    width: 17px;
    height: 17px;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    margin: 0;
    border-top: 4px solid #3498db;
  }
</style>
