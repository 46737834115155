import GarageApi from '../api/garage_api';

export const state = {};

export const mutations = {};

export const getters = {
  
};

export const actions = {
  staffs(_, query) {
    return GarageApi.staffAll(query);
  },

  list(_, data) {
    return GarageApi.list(data);
  },

  create(_, data) {
    return GarageApi.create(data)
  },

  detail(_, data) {
    return GarageApi.detail(data)
  },

  update(_, data) {
    return GarageApi.update(data)
  },
  lookup(_, data) {
    return GarageApi.lookup(data)
  },
  addRoom(_, data){
    return GarageApi.addRoom(data)
  },

  roomLists(_, data){
    return GarageApi.roomLists(data)
  },

  roomDetail(_, data){
    return GarageApi.roomDetail(data)
  },

  roomMemberUpdate(_, data){
    return GarageApi.roomMemberUpdate(data)
  },

  roomUpdate(_, data){
    return GarageApi.roomUpdate(data)
  },

  roomDelete(_, data){
    return GarageApi.roomDelete(data)
  }
};
