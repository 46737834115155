<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <label>Cập nhật thông tin thanh toán</label>
        <div
          style="margin: 10px;color: red;font-style: italic;font-weight: 400;">
          Lưu ý: Bạn chỉ có thể cập nhật 1 lần
        </div>
        <div style="height: 30px" />
        <div class="form">
          <div class="form-group">
            <label>Ảnh bằng chứng</label>
            <div
              class="input-control"
              :class="{ error: errors.has('Ảnh bằng chứng') }"
            >
              <input
                name="Ảnh bằng chứng"
                v-validate="'required'"
                type="file"
                @change="selectImage"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Nội dung thanh toán</label>
            <div
              class="input-control"
              :class="{ error: errors.has('Nội dung thanh toán') }"
            >
              <textarea
                name="Nội dung thanh toán"
                v-validate="'required'"
                style="height: 40px; resize: none;"
                v-model.trim="payment_info.note"
              ></textarea>
            </div>
            <div class="has-error" v-if="errors.has('Nội dung thanh toán')">
              {{ errors.first("Nội dung thanh toán") }}
            </div>
          </div>
        </div>
      </div>
      <div style="height: 20px" />
      <div
        class="btn btn-primary"
        style="width: 200px;margin: auto;"
        @click="submit"
      >
        Cập nhật
      </div>
    </div>
  </modal-base>
</template>

<script>
import { mapActions } from "vuex";
import ModalBase from "../../../../components/ModalBase.vue";
export default {
  props: ["orderId"],
  provide() {
    return { parentValidator: this.$validator };
  },
  components: { ModalBase },
  data() {
    return {
      payment_info: {
        note: null,
        image: null,
      },
      imageFile: null,
    };
  },

  methods: {
    ...mapActions("global", ["validateAll"]),
    selectImage(ev) {
      this.imageFile = ev.target.files[0];
    },
    async submit() {
      const result = await this.validateAll(this);
      if (!result) {
        return;
      }
      console.log(result);
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang upload ảnh...",
      });
      try {
        let imageObj = await this.$store.dispatch("media/upload", {
          file: this.imageFile,
        });
        this.payment_info.image = imageObj.url;

        this.updateInfo();
      } catch (e) {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
        this.$toastr.e(`Lỗi upload ảnh. Vui lòng thử lại.`);
      }

      //
    },
    async updateInfo() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang cập nhật...",
      });
      try {
        await this.$store.dispatch("order/updatePayment", {
          payment_info: this.payment_info,
          order_id: this.orderId,
        });
        this.$emit("reload");
        this.$emit("close");
      } catch (e) {
        this.$toastr.e(`Cập nhật thông tin. Vui lòng thử lại.`);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
  }
}
.btn-primary {
  border-color: #00c292;
  background: #00c292;
  color: white !important;
}

.form {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 16px;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
}
</style>
