export const state = {
  loading: false,
};

export const mutations = {
  IS_LOADING: (state, val) => {
    state.loading = val;
  }
};

export const actions = {
  loading(_, {
    isLoading = true,
    msg = null
  }) {
    _.commit('IS_LOADING', {
      isLoading: isLoading,
      msg
    });
  },

  async validateAll(_, self) {
    const result = await self.$validator.validateAll();
    if (!result) {
      window.$("input, textarea, select").each(function () {
        let input = window.$(this);

        if (input.attr("aria-invalid") && input.attr("aria-invalid") === "true") {
          if (input.is(':hidden')) {
            input = input.parent();
          }
          window.$("html,body").animate({
            scrollTop: input.offset().top - 300
          }, "slow");
          return false;
        }
      });
    }
    return result;

  }
};