<template>
  <default-layout>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="d-flex content-between">
              <h5 class="card-title">Doanh thu</h5>
              <span
                class="b-avatar badge-light-success rounded-circle"
                style="width: 30px; height: 30px;"
                ><span class="b-avatar-custom"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-dollar-sign"
                  >
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path
                      d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                    ></path></svg></span
                ><!----></span
              >
            </div>

            <div class="stats-list">
              <div class="stats-info">
                <p>
                  Hôm nay
                  <strong v-if="revenueSummary">{{
                     revenueSummary.today? revenueSummary.today.formatCurrency() : "0"
                  }}</strong>
                  <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
              <div class="stats-info">
                <p>
                  Tuần này
                  <strong v-if="revenueSummary">{{
                    revenueSummary.week? revenueSummary.week.formatCurrency() : "0"
                  }}</strong>
                  <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
              <div class="stats-info">
                <p>
                  Tháng này
                  <strong v-if="revenueSummary">{{
                    revenueSummary.month? revenueSummary.month.formatCurrency() : "0"
                  }}</strong>
                  <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="d-flex content-between">
              <h5 class="card-title">Đơn hàng</h5>
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 30px; height: 30px;"
                ><span class="b-avatar-custom"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trending-up"
                  >
                    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                    <polyline points="17 6 23 6 23 12"></polyline></svg></span
                ><!----></span
              >
            </div>
            <div class="stats-list">
              <div class="stats-info">
                <p>
                  Hôm nay
                  <strong v-if="orderSummary">{{
                    orderSummary.today ? orderSummary.today.formatCurrency() : 0
                  }}</strong>
                  <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
              <div class="stats-info">
                <p>
                  Tuần này
                  <strong v-if="orderSummary">{{
                    orderSummary.week ? orderSummary.week.formatCurrency() : 0
                  }}</strong>
                  <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
              <div class="stats-info">
                <p>
                  Tháng này
                  <strong v-if="orderSummary">{{
                    orderSummary.month ? orderSummary.month.formatCurrency() : 0
                  }}</strong>
                  <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="d-flex content-between">
              <h5 class="card-title">Khách hàng</h5>
              <span
                class="b-avatar badge-light-info rounded-circle"
                style="width: 30px; height: 30px;"
                ><span class="b-avatar-custom"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle></svg></span
                ><!----></span
              >
            </div>
            <div class="stats-list">
              <div class="stats-info">
                <p>
                  Hôm nay
                  <strong v-if="userSummary">{{
                    userSummary.today ? userSummary.today.formatCurrency() : 0
                  }}</strong>
                   <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
              <div class="stats-info">
                <p>
                  Tuần này
                     <strong v-if="userSummary">{{ userSummary.week ? userSummary.week.formatCurrency() : 0 }}</strong>
                      <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
              <div class="stats-info">
                <p>
                  Tháng này
                     <strong v-if="userSummary">{{
                    userSummary.month ? userSummary.month.formatCurrency() : 0
                  }}</strong>
                   <strong v-else
                    ><div
                      class="loader"
                      style="width: 18px; height: 18px; border: 2px solid #f3f3f3;border-top: 2px solid;"
                  /></strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-mobile-column">
      <div class="col col-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex content-between">
              <h5 class="card-title">Đơn hàng mới nhất</h5>
              <router-link
                :to="{ name: 'order'}"
                style="color: #00c292"
              >Xem chi tiết</router-link>
            </div>
            <div class="stats-list" style="height: 400px;" v-if="orderLatest">
              <table>
                <thead>
                <td>Mã</td>
                <td>Trạng thái</td>
                <td>Gói</td>
                <td>Khách hàng</td>
                <td>Tổng tiền (đ)</td>
                <td>Nơi tạo</td>
                <td>Thời gian tạo</td>
                </thead>
                <tbody>
                <tr v-for="order in orderLatest" :key="order.id">
                  <td>
                    <router-link
                      :to="{ name: 'order detail', params: { id: order.id } }"
                      style="color: #00c292"
                    >{{ order.id }}</router-link></td>
                  <td v-html="order.status.toStatusHtml()"></td>
                  <td>{{ order.bundle.name }}</td>
                  <td>
                    <div>
                      <div>{{ order.customer.name }}</div>
                      <div>{{ order.customer.phone }}</div>
                      <div>{{ order.customer.address }}</div>
                    </div>
                  </td>
                  <td>{{ (order.price - order.price_discount).formatCurrency() }}</td>
                  <td>{{ order.garage.name }}</td>
                  <td>{{ moment(order.createdAt).format("YYYY-MM-DD HH:mm") }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex content-between">
              <h5 class="card-title">Biểu đồ đơn hàng theo trạng thái tháng {{ moment().format("MM - YYYY") }}</h5>
            </div>
            <div class="stats-list">
              <chart-order-status></chart-order-status>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import UserRoleType from '@/enums/UserRoleType'
import moment from "moment";
import ChartOrderStatus from './chart/ChartOrderStatus'

export default {
  components: { DefaultLayout, ChartOrderStatus },
  data(){
    return {
      moment,
      isLoadingList: true,
      revenueSummary: null,
      orderSummary: null,
      userSummary: null,
      orderLatest: null
    }
  },
  created() {
    this.fetchSummary()
    this.fetchOrderLatest()
  },
  computed: {
    auth() {
      return this.$store.getters["account/user"];
    },
  },
  methods: {
    async fetchSummary() {
      this.isLoadingList = true;
      let payload = {}
      if(this.auth.garage && this.auth.garage.id){
        payload.garageId = this.auth.garage.id
      }
      let resp = await this.$store.dispatch("report/summary", payload);
      this.revenueSummary = resp.revenue;
      this.orderSummary = resp.order;
      this.userSummary = resp.user;
      this.isLoadingList = false;
    },
    async fetchOrderLatest(){
      this.isLoadingList = true;
      let payload = {}
      if(this.auth.garage && this.auth.garage.id){
        payload.garageId = this.auth.garage.id
      }
      let resp = await this.$store.dispatch("order/latest", payload);
      this.orderLatest = resp
      this.isLoadingList = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  .col {
    flex: 1;
     padding-right: 15px;
  }
  .col:last-child{
    padding-right: 0;
  }
  .col-4{
    flex: 40%;
  }
  .col-6{
    flex: 60%;
  }
  .card{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ededed;
    box-shadow: 2px 4px 4px 0 #ededed;
    margin-bottom: 30px;
    .card-body {
      padding: 1.25rem;
      .card-title {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 0;
      }
      .stats-list {
        overflow-y: auto;
        .stats-info {
          background-color: #fff;
          /* border: 1px solid #e5e5e5; */
          border-radius: 4px;
          /* margin-bottom: 20px; */
          padding: 7px 0;
          text-align: center;

          p {
            display: flex;
            font-size: 16px;
            justify-content: space-between;
            margin-bottom: 5px;
            margin-top: 0;
            strong {
              font-weight: bolder;
            }
          }
        }
      }
    }
  }
}
.badge-light-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0;
}
.b-avatar.badge-light-info {
  background-color: rgba(0, 207, 232, 0.12);
  color: #00cfe8;
}
.b-avatar.badge-light-success {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
}

.rounded-circle {
  border-radius: 50%;
}
.b-avatar {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  overflow: visible;
  position: relative;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.bg-primary,
.badge-primary {
  background-color: #ff9b44;
}
.bg-info,
.badge-info {
  background-color: #009efb;
}
.bg-success,
.badge-success {
  background-color: #55ce63;
}

@media only screen and (max-width: 991px) {
  .flex-mobile-column{
    flex-direction: column;
  }
  .col-4{
    flex: 1 !important;
  }
  .col-6{
    flex: 1 !important;
    padding-right: 0 !important;
  }
}
</style>
