module.exports = {
  'pending': 'Đợi duyệt', // đang đợi duyệt
  'in-process': 'Đã duyệt', // đang xử lý
  'shiping': 'Đang nhận xe', // đang nhận xe
  'in-queue': 'Đợi đánh bóng', 
  'washing': 'Đang rửa xe', // đang trong quá trinh rửa  => trong quá trình này thì nhận viên k thể checkin polish
  'polish': 'Đang đánh bóng', // đang trong quá trình đánh bóng => ngược lại với trạng thái ở trên
  'done': 'Đợi nghiệm thu', // khi dich vụ đánh bóng và rửa đã xong
  'delivery': 'Đang giao xe', // đang giao xe
  'complete': 'Đã hoàn thành', // hoàn thành đơn hàng
  'canceled': 'Đã hủy',
}