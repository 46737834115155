export default {
  //admin
  history(query){
    return window.$.get(process.env.VUE_APP_API_URL.concat('/inventory/admin/history'), query);

  },

  list: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/inventory/admin/list'), query);
  },

  garage: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/inventory/admin/garage'), query);
  },

  store(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/inventory/admin/store'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
  add(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/inventory/admin/add'),
      data: JSON.stringify(data),
      contentType: 'application/json',
      xhr: function(){
        var xhr = new XMLHttpRequest();
        xhr.responseType= 'blob'
        return xhr;
      },
    });
  },
  delete(payload) {
    return window.$.ajax({
      method: 'DELETE',
      url: `${process.env.VUE_APP_API_URL}/inventory/admin/delete/${payload.id}`,
      contentType: 'application/json'
    });
  },
  swap(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/inventory/admin/swap'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },

  getByCondition(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/inventory/getByCondition'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },

  roomProductList: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/inventory/admin/roomProductList'), query);
  },

  roomProductAdd(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/inventory/admin/roomProductAdd'),
      data: JSON.stringify(data),
      contentType: 'application/json',
      xhr: function(){
        var xhr = new XMLHttpRequest();
        xhr.responseType= 'blob'
        return xhr;
    },
    });
  },

  roomProductDelete(payload) {
    return window.$.ajax({
      method: 'DELETE',
      url: `${process.env.VUE_APP_API_URL}/inventory/admin/roomProductDelete/${payload.id}?amount=${payload.amount}`,
      contentType: 'application/json'
    });
  },
};
