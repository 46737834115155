<template>
  <modal-base @close="$emit('close')">
    <div class="content">
      <label style="font-weight: bold;">{{
        type == "washing" ? "Chọn nhân viên rửa xe" : "Chọn nhân viên đánh bóng"
      }}</label>
      <div style="height: 12px" />
      <div class="control">
        <staff-assign-view
          style="height: 100%;"
          :value="value"
          @input="assign_lists = $event"
        ></staff-assign-view>
      </div>
      <div style="height: 12px" />
      <div
        class="btn btn-submit"
        style="min-width: 120px; margin: auto;  color: white !important;"
        @click="updateOrder()"
      >
        Lưu
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
import StaffAssignView from "../../../../components/StaffAssignView.vue";
export default {
  components: { ModalBase, StaffAssignView },
  props: ["type", "value", "orderId"],

  data() {
    return {
      assign_lists: this.value,
    };
  },
  methods: {
    async updateOrder() {
      try {
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Đang lưu dữ liệu...",
        });

        let bodyData = {
          order_id: this.orderId,
        };
        if (this.type == "polish") {
          bodyData["polish_assign_list"] = this.assign_lists.map(
            (assign) => assign.id || assign
          );
        } else {
          bodyData["washing_assign_list"] = this.assign_lists.map(
            (assign) => assign.id || assign
          );
        }

        await this.$store.dispatch("order/update", bodyData);
        this.$toastr.s("Đã lưu");
        this.$emit("reload");
        this.$emit('close')
      } catch (e) {
        this.$toastr.e("Lỗi cập nhập. Vui lòng thử lại");
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: 100px;
  .control {
    min-height: 40px;
    border: 1px solid #ededed;
  }
}
</style>
