<template>
  <div ref="modal" class="modal" @mousedown.self="$emit('close')">
    <div class="modal-body">
      <div class="close">
        <img src="@/assets/icons/close.svg" @click.self="$emit('close')" />
      </div>
      <div class="modal-title" v-if="title">{{ title }}</div>
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ['title'],
  created() {
    this.$nextTick(() => {
      window.$("body").addClass("no-scroll");
      const height = document.body.scrollHeight;
      if (height > window.innerHeight) {
        window.$("body").addClass("padding-scroll");
        if (window.innerWidth > 768) {
          this.$refs["modal"].style.right = "10px";
        }
      }
    });
    setTimeout(() => {}, 100);
  },
  beforeDestroy() {
    window.$("body").removeClass("no-scroll");
    window.$("body").removeClass("padding-scroll");
  },
};
</script>

<style lang="scss" scoped>
.modal {
  overflow: auto;
  padding: 40px;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 27%);

  z-index: 9999;
  transition: none;

  .modal-body {
    // background: linear-gradient(
    //   to left,
    //   rgb(111 74 199 / 30%),
    //   rgb(111 74 199 / 30%)
    // );
    // box-shadow: 3px 4px 5px 0px #d4d4d4;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 50px 20px 20px 20px;
    margin: auto;
    // max-width: 100%;
    position: relative;
    max-width: 100%;
    .close {
      position: absolute;
      right: 18px;
      top: 18px;
      width: 18px;
      cursor: pointer;
      object-fit: contain;
    }
    .modal-title{
      position: absolute;
      font-weight: bold;
      font-size: 18px;
      top: 14px;
      left: 33px;
      color: #3d3d3d;
    }
    div{
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 710px) {
  .modal {
    padding: 20px;
    .modal-body {
      padding: 20px;
    }
  }
}
</style>
