<template>
  <div class="timeline">
    <table>
      <thead>
        <td>Mã đơn hàng</td>
        <td>Tên gói</td>
        <td>Trạng thái đơn</td>
        <td>Ngày tạo</td>
      </thead>
      <tbody>
        <tr v-for="(item, index) of worklists" :key="index">
          <td><a style="padding: 4px 10px; background: #ffd67f; border-radius: 4px; color: #000;" :href="`/order/${item.id }/detail`">{{ item.id }}</a></td>
          <td>{{item.bundle.name}}</td>
          <td v-html="item.status.toStatusHtml()"></td>
          <td>{{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}}</td>
        </tr>
      </tbody>
    </table>
    <paginate-view
      style="justify-content: right;"
      :page="meta.page"
      :totalPage="meta.totalPage"
      @onPage="meta.page = String($event)"
    ></paginate-view>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ["user_id"],
  data() {
    return {
      moment,
      isLoading: true,
      worklists: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
    };
  },

  created() {
    this.fetchData()
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    async fetchData(page = 1) {
     this.isLoading = true;

      try {
        const resp = await this.$store.dispatch("account/worklists", {
          user_id: this.user_id,
          page,
          startDate: new Date(0)
        });
        this.worklists = resp.data;
        this.meta = resp.meta;
      } catch (e) {
        console.log(e, "XXXX");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
