<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <label>Ghi chú của nhân viên</label>
        <div style="height: 20px;" />
        <div
          v-if="histories == null"
          style="display: flex;align-items: center;justify-content: center;"
        >
          <div class="loader" />
        </div>
        <div v-else-if="histories.length == 0">
          <div>Không có dữ liệu</div>
        </div>
        <table v-else>
          <thead>
            <td>
              Thời gian
            </td>
            <td>
              Nhân viên
            </td>
            <td>
              Ghi chú
            </td>
            <td style="width: 40%">
              Hình ảnh
            </td>
          </thead>
          <tbody>
            <tr v-for="history of histories" :key="history.id">
              <td>
                {{ moment(history.createdAt).format("YYYY-MM-DD HH:mm") }}
              </td>
              <td v-html="history.user.name"></td>
              <td v-html="history.note"></td>
              <td>
                <a v-if="history.image" class="btn-link" target="_blank" :href="history.image" >Xem hình ảnh</a>
                <span v-else style="font-style: italic; color: #bbbbbb">Không có ảnh</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="height: 40px" />
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
import moment from "moment";

export default {
  components: { ModalBase },
  props: ["orderId"],
  data() {
    return {
      moment,
      histories: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.histories = await this.$store.dispatch("order/noteList", {
        order_id: this.orderId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 800px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

table, tr, td, th{
  font-size: 14px;
}
</style>
