<template>
  <modal-base @close="$emit('close')">
    <table>
      <thead>
        <td>Mã sản phẩm</td>
        <td>Mã nội bộ</td>
        <td>Tên</td>
        <td>Chai</td>
        <td>Dung tích</td>
        <td>Đơn vị</td>
      </thead>
      <tbody>
        <tr v-for="(row, index) of products" :key="index">
          <td>{{ row.product_id }}</td>
          <td>{{ row.product.code || '--' }}</td>
          <td style="min-width: 200px; max-width: 500px; word-break: break-word;">
            {{ row.product.name }}
          </td>
          <td>{{ row.amount_on_package }}</td>
          <td>{{ row.amount_on_unit.formatNumber() }}</td>
          <td>{{ row.unit }}</td>
        </tr>
      </tbody>
    </table>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
export default {
  props: ["products"],
  components: { ModalBase },
};
</script>

<style lang="scss" scoped>
table{
  min-width: 500px;
}
</style>
