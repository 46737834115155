<template>
  <default-layout>
    <div class="search-box">
      <form>
        <div class="form-group" v-if="user.role == 4">
          <label>Nơi tạo</label>
          <div class="form-control">
            <select-lazy-loading-view
              :hasAll="true"
              :current="search.garage_id"
              dispatch="garage/lookup"
              @onChange="search.garage_id = $event"
            ></select-lazy-loading-view>
          </div>
        </div>
        <div class="form-group">
          <label>Mã đơn hàng</label>
          <div class="form-control">
            <input v-model.trim="search.order_id" />
          </div>
        </div>
        <div class="form-group">
          <label>Trạng thái</label>
          <div class="form-control">
            <select v-model="search.status">
              <option :value="null">Tất cả</option>
              <option v-for="item of OrderStatusType" :key="item" :value="item">
                {{OrderStatusType.getString(item)}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label>Gói dịch vụ</label>
          <div class="form-control">
           <select-lazy-loading-view
            :hasAll="true"
            keyData="data"
              :current="search.bundle_id"
              dispatch="bundle/list"
              @onChange="search.bundle_id = $event"
           ></select-lazy-loading-view>
          </div>
        </div>
      </form>
      <div class="action">
        <button class="btn btn-submit" @click.prevent="searchSubmit()">
          Tìm kiếm
        </button>
        <button class="btn btn-primary" @click.prevent="isShowExportModal = true"  v-if="user.role == 4">Báo cáo doanh thu</button>
      </div>
    </div>
    <div class="order" v-if="orderLists == null">
    </div>
    <div class="order" v-else-if="orderLists.length == 0">
      Không có dữ liệu đơn hàng
    </div>
    <div class="order" v-else>
      <table-count :meta="meta" @refresh="fetchData(meta.page, search)"></table-count>
      <div class="content">
        <table>
          <thead>
            <td>Mã</td>
            <td>Trạng thái</td>
            <td>Gói</td>
            <td>Khách hàng</td>
            <td>Tổng tiền(đ)</td>
            <td>Thời gian tạo</td>
            <td>Nơi tạo</td>
            <td style="min-width: 120px;">Hành động</td>
          </thead>
          <tbody>
            <tr v-for="order of orderLists" :key="order.id">
              <td>{{ order.id }}</td>
              <td v-html="order.status.toStatusHtml()"></td>
              <td>{{ order.bundle.name }}</td>
              <td>
                <div>
                  <div>{{ order.customer.name }}</div>
                  <div>{{ order.customer.phone }}</div>
                  <div>{{ order.customer.address }}</div>
                </div>
              </td>
              <td>{{ (order.price - order.price_discount).formatCurrency() }}</td>
              <td>{{ moment(order.createdAt).format("YYYY-MM-DD HH:mm") }}</td>
              <td>{{ order.garage ? order.garage.name : "--" }}</td>

              <td>
                <router-link
                class="btn  btn-sm btn-info"
                  :to="{ name: 'order detail', params: { id: order.id } }"
                  >Chi tiết</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <paginate-view
      style="justify-content: right;"
      :page="meta.page"
      :totalPage="meta.totalPage"
      @onPage="meta.page = String($event)"
    ></paginate-view>

    <modal-export-order
      v-if="isShowExportModal"
      @close="isShowExportModal = false"
    />
  </default-layout>
</template>

<script>
import PaginateView from "../../../components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import SelectLazyLoadingView from "../../../components/SelectLazyLoadingView.vue";
import TableCount from "../../../components/TableCount.vue";
import OrderStatusType from "../../../enums/OrderStatusType";
import ModalExportOrder from "@/router/pages/order/modal/ModalExportOrder";

import moment from "moment";
import { mapState } from "vuex";
import {isEmpty} from "lodash";

export default {
  components: {
    DefaultLayout,
    PaginateView,
    SelectLazyLoadingView,
    TableCount,
    ModalExportOrder
  },
  data() {
    return {
      OrderStatusType,
      moment,
      firstLoading: true,
      orderLists: null,
      search: {
        garage_id: null,
        order_id: null,
        status: null,
        bundle_id: null,
      },
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      isShowExportModal: false
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  created() {
    const searchParams = new URLSearchParams(location.search);
    this.search = {
      garage_id: searchParams.get("garage_id"),
      order_id: searchParams.get("order_id"),
      status: searchParams.get("status"),
    };

    this.fetchData(1, this.search);
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        console.log("val", val);
        this.fetchData(val, this.search);
      });
    },
  },
  computed: {
    ...mapState("account", ["user"]),
  },
  methods: {
    searchSubmit() {
      // fill query
      // let url = new URL(window.location.href);
      // url = this._queryCreateOrUpdate(url, "garage_id", this.search.garage_id);
      // url = this._queryCreateOrUpdate(url, "order_id", this.search.order_id);
      // url = this._queryCreateOrUpdate(url, "status", this.search.status);
      // // update url
      // if (history.pushState) {
      //   window.history.pushState({ path: url.href }, "", url.href);
      // }

      this.firstLoading = true;
      this.fetchData(1, this.search);
    },

    _queryCreateOrUpdate(url, key, value) {
      if (url.searchParams.has(key)) {
        url.searchParams.set(key, value);
      } else {
        url.searchParams.append(key, value);
      }
      return url;
    },
    async fetchData(page = 1, search = {}) {
      this.$store.dispatch("global/loading", { isLoading: true });
      try {
        let resp = await this.$store.dispatch("order/all", {
          ...search,
          page,
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.orderLists = resp.data;
        this.meta = resp.meta;
      } finally {
              this.$store.dispatch("global/loading", { isLoading: false });

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  background: white;
  padding: 16px 18px;
  min-height: 50px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
  }
}
</style>
