/* eslint-disable */

import { Validator } from 'vee-validate';


Validator.extend('required', {
  getMessage: field => `${field} không được để trống`,
  validate: (value) => {
    return value != null && value != '';
  }
});

Validator.extend('name', {
  getMessage: field => `${field} không hợp lệ`,
  validate: (value) => {
    const patt = new RegExp(/^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ 0-9]+$/);
    return patt.test(value);
  }
});

Validator.extend('vn-text', {
  getMessage: field => `${field} không hợp lệ`,
  validate: (value) => {
    // const patt = new RegExp(/^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ 0-9().:\-]+$/);
    return true;
  }
});

Validator.extend('username', {
  getMessage: field => 'The ' + field + ' value is not a valid name.',
  validate: (value) => {
    const patt = new RegExp(/^[a-zA-Z0-9]+$/);
    return patt.test(value);
  }
});

Validator.extend('AlphaAndNumeric', {
  getMessage: field => 'Should contain at least alpha, numeric.',
  validate: (value) => {
    const patt = new RegExp(/^(?=.*[0-9])(?=.*[a-z||A-Z])[A-Za-z0-9!@#$%^&*()_+?><":~]*$/);
    return patt.test(value);
  }
});

Validator.extend('MaxAge', {
  getMessage: (field, params) => `You must be ${params[0]} years old or above`,
  validate: (value, params) => {
    const birthDay = new Date(value);
    const ageDate = new Date((Date.now() - birthDay));
    return Math.abs(ageDate.getUTCFullYear() - 1970) >= params[0];
  }
});

Validator.extend('TIN', {
  getMessage: (field) => `The ${field} value is not a valid name.`,
  validate: (value) => {
    const patt = new RegExp(/^\d{9}|Student$/);
    return patt.test(value);
  }
});

Validator.extend('digits_between', {
  validate: (value, params) => {
    try{
      const v = parseInt(value);
      if(params.length === 1){
        return v >= params[0]
      }
      return v >= params[0] && v <= params[1];
    }catch (e) {
      return false;
    }
  },
  getMessage: (field, params) => `${field} cần lớn hơn ${params[0]} và nhỏ hơn ${params[1]}`
});

Validator.extend('regex', {
  validate: (value, params) => {
    const patt = new RegExp(params.join(','));
    return patt.test(value);
  },
  getMessage: (field, params) => `${field} không đúng định dạng.`
});

Validator.extend('numeric', {
  getMessage: field => `${field} phải là số`,
  validate: (value) => {
    const patt = new RegExp('^[0-9]*$');
    return patt.test(value);
  }
});

Validator.extend('inventoryAmount', {
  validate: (value, params) => {
    let id = params[0]
    let packageElm = document.getElementById(`package-${id}`);
    let unitElm = document.getElementById(`unit-${id}`);
    if(packageElm && unitElm){
      let amount = packageElm.value * unitElm.value
      if(amount > 2000000){
        return false
      }
    }
    return true
  },
  getMessage: (field, params) => `Số lượng không được phép > 2.000.000`
});