<template>
  <div class="form-group">
    <label>
      {{ mName }}
      <span v-if="validate && validate.indexOf('required') > -1">*</span>
    </label>
    <div v-if="subtitle" v-html="subtitle"></div>
    <div class="input-control" :class="{error: errors.has(mName)}">
      <input
        v-if="!cleaveOption"
        :name="mName"
        v-model.trim="model"
        :maxlength="maxlength"
        v-validate="validate"
        :type="type || 'text'"
        autocomplete="off"
        :disabled="disabled"
      />
      <Cleave
        v-else
        :options="cleaveOption"
        :name="mName"
        v-model.trim="model"
        :maxlength="maxlength"
        v-validate="validate"
        :type="type || 'text'"
        autocomplete="off"
        :disabled="disabled"
      />

    </div>
    <div class="has-error" v-if="errors.has(mName)">
      {{ errors.first(mName) }}
    </div>
  </div>
</template>

<script>
import Cleave from "vue-cleave-component";

export default {
  components: {Cleave},
  inject: ["parentValidator"],
  props: ["mName", "maxlength", "value", "validate", "disabled", "type","cleaveOption", "subtitle"],
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    model(val) {
      this.$emit("input", val);
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>

<style lang="scss" scoped>
 .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      color: #000000;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
</style>
