Array.prototype.firstWhere = function (cb) {
  for (const item of this) {
    if (cb(item)) {
      return item;
    }
  }
  return null;
};

Array.prototype.findWhere = function (cb) {
  const ret = [];
  for (const item of this) {
    if (cb(item)) {
      ret.push(item);
    }
  }
  return ret;
};

Array.prototype.deleteWhere = function (cb) {
  for (const [index, item] of this.entries()) {
    if (cb(item)) {
      this.splice(index, 1);
    }
  }
};


String.prototype.getParameterByName = function (name) {
  name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(this);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

String.prototype.truncate = function (length = 5, separator = '...') {
  if (this.length <= length) return this;

  separator = separator || '...';

  const sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return this.substr(0, frontChars) + separator + this.substr(this.length - backChars);
};

String.prototype.formatCurrency = function () {
  if(this && this !== ''){
    return parseInt(this).toLocaleString("vi-VN", { maximumFractionDigits: 2 }).replaceAll('.',',')
  }
  return ''
  /*return new Intl.NumberFormat('vi-VI', {style: 'currency', currency: 'VND' }).format(this).replace(/[₫]/gi, '')
    .replace(/(\.+\d{2})/, '').trim()*/
};

Number.prototype.formatCurrency = function () {
  if(this && this !== ''){
    return parseInt(this).toLocaleString("vi-VN", { maximumFractionDigits: 2 }).replaceAll('.',',')
  }
  return ''
  /*return new Intl.NumberFormat('vi-VI', {style: 'currency', currency: 'VND' }).format(this).replace(/[₫]/gi, '')
    .replace(/(\.+\d{2})/, '').trim()*/
};


Number.prototype.formatNumber = function () {
  return new Intl.NumberFormat('vi-VI', {  }).format(this).replaceAll('.',',')
};

String.prototype.formatNumber = function () {
  return new Intl.NumberFormat('vi-VI', {  }).format(this).replaceAll('.', ',')
};

String.prototype.toStatusHtml = function () {
  const status = `${this}`;
  switch (status) {
    case 'in-process':
      return '<div class="tag" style="background: #218379">Đã duyệt</div>'
      case 'in-queue':
      return '<div class="tag" style="background: #74a7c4">Đợi đánh bóng</div>'
    case 'washing':
      return '<div class="tag" style="background: #2275cd">Đang rửa xe</div>'
    case 'polish':
      return '<div class="tag" style="background: #2a6e94">Đang đánh bóng</div>'
    case 'shiping':
      return '<div class="tag" style="background: #9d6f29">Đang nhận xe</div>'
    case 'delivery':
      return '<div class="tag" style="background: #19a748">Đang giao xe</div>'
    case 'done':
      return '<div class="tag" style="background: #32a2cb">Đợi nghiệm thu</div>'
    case 'complete':
      return '<div class="tag" style="background: #2cd600">Hoàn thành</div>'
    case 'canceled':
      return '<div class="tag" style="background: #ff5555">Đã hủy</div>'
    case 'active':
      return '<div class="tag" style="background: #5867dd">Hoạt động</div>'
    case 'inactive':
      return '<div class="tag" style="background: #218379">Không hoạt động</div>'
    case 'blocked':
        return '<div class="tag" style="background: red">Nghỉ việc</div>'
    default:
      return '<div class="tag" style="background: #ffb03c">Đợi duyệt</div>'
  }
}

String.prototype.toStatusInventoryHtml = function () {
  const status = `${this}`;
  switch (status) {
    case 'sold':
      return '<div class="tag" style="background: #ff5555">Hết hàng</div>'
      case 'soon':
      return '<div class="tag" style="background: #9d6f29">Sắp hết hàng</div>'
    default:
      return '<div class="tag" style="background: #5867dd">Còn hàng</div>'
  }
}