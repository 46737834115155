<template>
  <div class="overview">
    <div style="height: 24px;" />
    <div class="analytic">
      <label>Thống kê</label>
      <div style="height: 24px;" />
      <div class="content">
        <div class="analytic-view">
          <div class="title">Hôm nay</div>
          <div style="height: 12px;" />
          <div class="row">
            <span>Đơn đã xử lý:</span
            ><span class="amount">{{
              _getOverviewData("today", "total_order")
            }}</span>
          </div>
          <div class="row">
            <span>Tổng thời gian:</span
            ><span class="amount">{{
              _getOverviewData("today", "total_time_work")
            }}</span>
          </div>
        </div>
        <div style="width: 24px;" />
        <div class="analytic-view">
          <div class="title">Tuần này</div>
          <div style="height: 12px;" />
          <div class="row">
            <span>Đơn đã xử lý:</span
            ><span class="amount">{{
              _getOverviewData("week", "total_order")
            }}</span>
          </div>
          <div class="row">
            <span>Tổng thời gian:</span
            ><span class="amount">{{
              _getOverviewData("week", "total_time_work")
            }}</span>
          </div>
        </div>
        <div style="width: 24px;" />
        <div class="analytic-view">
          <div class="title">Tháng này</div>
          <div style="height: 12px;" />
          <div class="row">
            <span>Đơn đã xử lý:</span
            ><span class="amount">{{
              _getOverviewData("month", "total_order")
            }}</span>
          </div>
          <div class="row">
            <span>Tổng thời gian:</span
            ><span class="amount">{{
              _getOverviewData("month", "total_time_work")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["user_id"],
  data() {
    return {
      moment,
      overview: {
        today: null,
        week: null,
        month: null,
      },
    };
  },

  created() {
    this.fetchData("today");
    this.fetchData("week");
    this.fetchData("month");
  },

  methods: {
    async fetchData(type) {
      // T14:48:00.000Z

      let startDate = moment(Date.now()).format("YYYY-MM-DDT00:00:00.000Z");

      if (type == "week") {
        const curr = new Date();
        let day = curr.getDay(),
          diff = curr.getDate() - day + (day == 0 ? -6 : 1);
        startDate = moment(new Date(curr.setDate(diff)).getTime()).format(
          `YYYY-MM-DDT00:00:00.000Z`
        );
        // start date of week
      } else if (type == "month") {
        startDate = moment(Date.now()).format("YYYY-MM-01T00:00:00.000Z");
      }

      this.overview[type] = await this.$store
        .dispatch("account/overview", {
          user_id: this.user_id,
          startDate,
        })
        .then((resp) => resp.overview);
    },

    _getOverviewData(type, keyData) {
      if (this.overview[type]) {
        if (keyData == "total_time_work") {
          return (this.overview[type][keyData] / 3600000).toFixed(2) + " giờ";
        }
        return this.overview[type][keyData];
      }
      return "--";
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  padding: 20px;
  .status {
    span {
      font-weight: 700;
    }
  }
  .content {
    display: flex;
    .analytic-view {
      flex: 1;
      border-radius: 8px;
      border: 1px solid #ededed;
      box-shadow: 2px 4px 4px 0 #ededed;
      padding: 12px;
      div {
        line-height: 24px;
      }
      .title {
        font-weight: 700;
        font-size: 18px;
      }
      .row {
        display: flex;
        .amount {
          flex: 1;
          text-align: right;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
