<template>
  <modal-base @close="$emit('close')" :title="`Xoá phòng '${deleteObj.name}'`">
    <div class="container">
      <ul>
        <li>
          Đơn không có thông tin thanh toán sẽ không được duyệt tự động.
        </li>
        <li>
          Để duyệt đơn bạn cần vào Danh sách đơn > Chi tiết > Chọn duyệt đơn
        </li>
      </ul>
      <div style="height: 20px"/>
      <div class="d-flex" style="justify-content: end">
        <div class="btn" style="margin-right: 20px" @click="$emit('close')">Hủy</div>
        <div class="btn btn-danger" @click="$emit('ok', deleteObj.id); $emit('close');">Đồng ý</div>
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "@/components/ModalBase.vue";
export default {
  components: { ModalBase },
  props: ['deleteObj'],
  data(){
    return {
    }
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
  }
}
.btn-primary {
  border-color: #00c292;
  background: #00c292;
  color: white !important;
}
b{
  font-weight: bold;
  color: #3c3c3c;
}
</style>
