export default {
  //admin
  list: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/product/admin/list'), query);
  },
  create(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/product/admin/create'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
  detail: (payload) => {
    return window.$.get(`${process.env.VUE_APP_API_URL}/product/admin/detail/${payload.id}`);
  },
  update(payload) {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/product/admin/update/${payload.product.id}`,
      data: JSON.stringify(payload.product),
      contentType: 'application/json'
    });
  },
  getByCondition(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/product/getByCondition'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
};
