<template>
  <default-layout>
    <div class="create-order">
      <div class="form">
        <div class="left">
          <div class="form-group" style=" max-width: 340px;">
            <label>Chọn gói</label>
            <div
              style="padding:0;    "
              class="input-control"
              :class="{ error: errors.has('Chọn gói') }"
            >
              <select-bundler-view
                mName="Chọn gói"
                @input="bundleId = $event"
              ></select-bundler-view>
              <!-- <select-lazy-loading-view
                mName="Chọn gói"
                :validate="'required'"
                @onChange="bundleId = $event"
                :dispatch="'bundler/lists'"
              ></select-lazy-loading-view> -->
            </div>
          </div>
          <!-- INFO -->
          <bunder-info-view
            :bundleId="bundleId"
            @onPrice="price = $event"
          ></bunder-info-view>

          <div style="height: 20px;" />
          <div class="form-group">
            <label>Phân công</label>
            <div style="    font-style: italic;">
              Hệ thống sẽ tự động phân công nhân viên
            </div>
          </div>

          <div style="height: 8px;" />
          <div class="form-group">
            <label>Ghi chú</label>
            <div class="input-control">
              <textarea
                maxlength="500"
                v-model.trim="note"
                style="height: 100px; resize: none;"
              ></textarea>
            </div>
          </div>
        </div>
        <div style="width: 32px;" />
        <div class="right">
          <div class="form-group">
            <label>Thông tin khách hàng</label>
            <div class="input-control" style="border: 0;">
            
              <div class="form-group">
                <label>Số điện thoại</label>
                <div
                  class="input-control"
                  :class="{ error: errors.has('Số điện thoại') }"
                >
                  <input
                    name="Số điện thoại"
                    v-validate="'required|regex:^0+[0-9]{9}$'"
                    v-model.trim="customer.phone"
                    @blur="findCustomer()"
                  />
                </div>
                <div class="has-error" v-if="errors.has('Số điện thoại')">
                  {{ errors.first("Số điện thoại") }}
                </div>
              </div>

                 <div class="form-group">
                <label>Email</label>
                <div
                  class="input-control"
                  :class="{ error: errors.has('Email') }"
                >
                  <input
                    name="Email"
                    v-validate="'required'"
                    v-model.trim="customer.email"
                  />
                </div>
                <div class="has-error" v-if="errors.has('Email')">
                  {{ errors.first("Email") }}
                </div>
              </div>
              
              <div class="form-group">
                <label>Họ tên</label>
                <div
                  class="input-control"
                  :class="{ error: errors.has('Họ tên') }"
                >
                  <input
                    :disabled="customer_id != null"
                    name="Họ tên"
                    maxlength="50"
                    v-validate="'required|name'"
                    v-model.trim="customer.name"
                  />
                </div>
                <div class="has-error" v-if="errors.has('Họ tên')">
                  {{ errors.first("Họ tên") }}
                </div>
              </div>
              <div class="form-group">
                <label>Địa chỉ</label>
                <div
                  class="input-control"
                  :class="{ error: errors.has('Địa chỉ') }"
                >
                  <input
                    :disabled="customer_id != null"
                    name="Địa chỉ"
                    maxlength="255"
                    v-validate="'required'"
                    v-model.trim="customer.address"
                  />
                </div>
                <div class="has-error" v-if="errors.has('Địa chỉ')">
                  {{ errors.first("Địa chỉ") }}
                </div>
              </div>

            <div class="form-group">
                <label>Biển số xe</label>
                <div
                  class="input-control"
                  :class="{ error: errors.has('Biển số xe') }"
                >
                  <input
                    name="Biển số xe"
                    v-validate="'required'"
                    v-model.trim="license_plates"
                   
                  />
                </div>
                <div class="has-error" v-if="errors.has('Biển số xe')">
                  {{ errors.first("Biển số xe") }}
                </div>
              </div>
              <div class="form-group">
                <label>Ảnh xe</label>
                <div class="input-control">
                  <input type="file" @change="carImageChange" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Thông tin thanh toán</label>
            <label style="display: flex; align-items: center; cursor: pointer;"
              ><input
                v-model="isHidePayment"
                type="checkbox"
                style="width: auto;"
              />
              Cập nhật sau</label
            >
            <div class="input-control" style="border: 0;" v-if="!isHidePayment">
              <div class="form-group">
                <label>Ảnh bằng chứng</label>
                <div
                  class="input-control"
                  :class="{ error: errors.has('Ảnh bằng chứng') }"
                >
                  <input
                    name="Ảnh bằng chứng"
                    v-validate="'required'"
                    type="file"
                    @change="selectImage"
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Nội dung thanh toán</label>
                <div
                  class="input-control"
                  :class="{ error: errors.has('Nội dung thanh toán') }"
                >
                  <textarea
                    name="Nội dung thanh toán"
                    v-validate="'required'"
                    style="height: 40px; resize: none;"
                    v-model.trim="payment_info.note"
                  ></textarea>
                </div>
                <div class="has-error" v-if="errors.has('Nội dung thanh toán')">
                  {{ errors.first("Nội dung thanh toán") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 24px;" />
      <div
        class="btn btn-submit"
        style="width: 200px; margin: auto"
        @click.prevent="submit()"
      >
        Tạo đơn
      </div>
    </div>
    <modal-confirm-order
      v-if="isShowConfirm"
      @close="isShowConfirm = false"
      @ok="createOrder()"
    ></modal-confirm-order>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import SelectLazyLoadingView from "../../../components/SelectLazyLoadingView.vue";
import BunderInfoView from "../../../components/BunderInfoView.vue";
import StaffAssignView from "../../../components/StaffAssignView.vue";
import ModalConfirmOrder from "./modal/ModalConfirmOrder.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import InputView from "../../../components/InputView.vue";
import SelectBundlerView from "../../../components/SelectBundlerView.vue";
export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  components: {
    DefaultLayout,
    SelectLazyLoadingView,
    BunderInfoView,
    StaffAssignView,
    ModalConfirmOrder,
    InputView,
    SelectBundlerView,
  },
  data() {
    return {
      isHidePayment: true,
      isShowConfirm: false,
      bundleId: null,
      customer: {
        phone: null,
        name: null,
        address: null,
        email: null,
      },
      license_plates: null,
      car_image: null,
      customer_id: null,
      garage_id: null,
      washing_assign_list: [],
      polish_assign_list: [],
      note: null,
      price: null,
      payment_info: {
        note: null,
        image: null,
      },
      carImageFile: null,
      imageFile: null,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    selectImage(ev) {
      this.imageFile = ev.target.files[0];
    },

    carImageChange(ev) {
      this.carImageFile = ev.target.files[0];
    },

    async findCustomer() {
      if (this.customer.phone) {
        let customerLists = await this.$store
          .dispatch("customer/find", {
            phone: this.customer.phone,
          })
          .catch(() => null);
        if (customerLists.length == 0) {
          this.customer_id = null;
          return;
        }

        if (customerLists[0].status == "active") {
          this.customer_id = customerLists[0].id;
          this.customer.address = customerLists[0].address;
          this.customer.name = customerLists[0].name;
        } else if (!this.customer.address && !this.customer.name) {
          // fill data
          this.customer_id = null;
          this.customer.address = customerLists[0].address;
          this.customer.name = customerLists[0].name;
        }
      }
    },

    async submit() {
      // validate
      const result = await this.validateAll(this);
      if (!result) {
        return;
      }
      // return;

      // submit
      if (!this.isHidePayment) {
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Đang upload ảnh...",
        });
        try {
          let imageObj = await this.$store.dispatch("media/upload", {
            file: this.imageFile,
          });
          this.payment_info.image = imageObj.url;
          this.createOrder();
        } catch (e) {
          this.$store.dispatch("global/loading", {
            isLoading: false,
          });
          this.$toastr.e(`Lỗi upload ảnh. Vui lòng thử lại.`);
        }
      } else {
        this.payment_info = {
          note: null,
          image: null,
        };
        // confirm
        this.createOrder();
      }
    },

    async createOrder() {
      // đẩy Ảnh
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang tạo đơn...",
      });
      try {
        if (this.carImageFile) {
          let imageObj = await this.$store.dispatch("media/upload", {
            file: this.carImageFile,
          });
          this.car_image = imageObj.url;
        }

        const order = await this.$store.dispatch("order/create", {
          license_plates: this.license_plates,
          bundle_id: this.bundleId,
          price: this.price,
          customer_id: this.customer_id,
          customer: this.customer,
          note: this.note,
          car_image: this.car_image,
          payment_info: this.payment_info,
        });

        //
        this.$toastr.s(`Đã tạo đơn hàng #${order.id}`);
        this.$nextTick(() => {
          this.$router.push({
            name: "order detail",
            params: {
              id: order.id,
            },
          });
        });
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          msg: "Đang tạo đơn...",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-order {
  background: white;
  padding: 44px 24px;

  .form {
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    .left {
      flex: 1;
    }
    .right {
      flex: 1;
    }
    .form-group {
      width: auto;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      label {
        margin-bottom: 8px;
        font-weight: bold;
      }
      select,
      textarea,
      input {
        border: 0;
        background: transparent;
        width: 100%;
      }
      .input-control {
        border: 1px solid #b9b9b9;
        padding: 8px;
      }
    }
  }
}
</style>
