<template>
  <default-layout>

    <div v-if="garage == null" class="box-container" style="min-height: 345px">
      <div class="loader" style="margin: auto" />
    </div>
    <div class="box-container" v-else>
      <div class="header">
        <label class="title">Thông tin ga-ra</label>
        <div class="action">
          <div
            v-if="user.role == 4"
            class="btn btn-link"
            @click.prevent="$router.push(`/inventory?garage_id=${garageId}`)"
            style="padding:0"
          >
            Quản lý tồn kho
          </div>
          <div
            style="width: 1px; background: rgb(176, 176, 176); height: 15px; margin: 0 8px;"
          />
          <div
            class="btn btn-link"
            style="padding:0"
            @click.prevent="$router.push(`/staff?garage_id=${garageId}`)"
          >
            Nhân viên ({{ staffCount }})
          </div>
          <div
            style="width: 1px; background: rgb(176, 176, 176); height: 15px; margin: 0 8px;"
          />
          <div
            class="btn btn-link"
            style="padding:0"
            @click.prevent="$router.push(`/order?garage_id=${garageId}`)"
          >
            Đơn hàng ({{ orderCount }})
          </div>
          <div style="width:24px" />
          <button
            v-if="user.role == 4"
            class="btn btn-submit"
            style="width: 200px; margin: auto"
            @click.prevent="updategarage()"
          >
            Lưu thay đổi
          </button>
        </div>
      </div>
      <div class="form">
        <div class="right">
          <div class="form-group">
            <div class="input-control box-form-input">
<!--              <div class="form-group">
                <label>Tên</label>
                <div class="input-control">
                  <input
                    v-model.trim="garage.name"
                    :disabled="user.role != 4"
                  />
                </div>
              </div>-->
              <input-view
                mName="Tên ga-ra"
                :value="garage.name"
                @input="garage.name = $event"
                maxlength="250"
                :disabled="user.role != 4"
                validate="required|vn-text"
              ></input-view>
              <div class="form-group">
                <label>Địa chỉ</label>
                <div class="input-control">
                  <input
                    name="Đia chỉ"
                    v-model.trim="garage.address"
                    :disabled="user.role != 4"
                  />
                </div>
                <div class="has-error" v-if="errors.has('Đia chỉ')">
                  {{errors.first('Đia chỉ')}}
                </div>
              </div>
              <!-- <div class="form-group">
                <label>Trạng thái</label>
                <div class="form-control">
                  <select v-model="garage.status">
                    <option v-for="option in statusOptions" v-bind:value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div> -->
              <div class="form-group">
                <label>Quản lý ga-ra</label>
                <div style="height: 4px;" />
                <div class="user-info">
                  <div>{{ garage.user.name }}</div>
                  <div
                    v-if="user.role == 4"
                    class="btn btn-link"
                    @click.prevent="isShowChangeManager = true"
                  >
                    Thay đổi
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal-change-manager-garage
        v-if="isShowChangeManager"
        :garageId="garage.id"
        @reload="fetchDetail()"
        @close="isShowChangeManager = false"
      ></modal-change-manager-garage>
    </div>
    <div style="height: 24px" />

  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import ModalChangeManagerGarage from "./modal/ModalChangeManagerGarage.vue";
import PaginateView from "../../../components/PaginateView.vue";
import TableCount from "../../../components/TableCount.vue";
import {mapActions, mapGetters} from "vuex";
import GaraStatusLookup from "@/enums/GaraStatusLookup";
import InputView from "@/components/InputView.vue";

export default {
  components: {
    DefaultLayout,
    ModalChangeManagerGarage,
    PaginateView,
    TableCount,
    InputView
  },
  data() {
    return {
      isShowAddRoom: false,
      isShowChangeManager: false,
      garage: null,
      staffCount: 0,
      orderCount: 0,
     
      statusOptions: GaraStatusLookup
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  created() {
    this.fetchDetail();
    this.fetchRoomLists();
  },

  computed: {
    ...mapGetters("account", ["user"]),

    garageId() {
      return this.$route.params.id || this.user.garage.id;
    },
  },

  watch: {
    "roomMeta.page": function(val) {
      this.$nextTick(() => {
        this.fetchRoomLists(val);
      });
    },
  },

  methods: {
    ...mapActions("global", ["validateAll"]),

    async fetchDetail() {
      this.garage = null;
      try {
        const resp = await this.$store.dispatch("garage/detail", {
          id: this.garageId,
        });
        if(!resp.info){
          window.history.go(-1)
        }
        this.garage = resp.info;
        this.staffCount = resp.staff_count;
        this.orderCount = resp.order_count;
      } catch (e) {
        this.$nextTick(() => {
          if (e.responseJSON) {
            this.$toastr.e(e.responseJSON.message);
          } else {
            this.$toastr.e(
              `Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`
            );
          }
        });
        
         window.history.go(-1)
      }
    },

    async fetchRoomLists(page = 0) {
      if (page == 0) {
        this.roomLists = null;
        this.roomMeta = {
          page: 1,
          totalPage: 1,
          total: 0,
        };
      }
      try {
        const resp = await this.$store.dispatch("garage/roomLists", {
          garage_id: this.garageId,
          page,
        });

        this.roomLists = resp.data;
        this.roomMeta = resp.meta;
      } catch (e) {
        console.log(e);
      }
    },

    async updategarage() {
      const result = await this.validateAll(this);
      if (!result) return;
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang lưu...",
      });
      try {
        await this.$store.dispatch("garage/update", {
          ...this.garage,
        });
        this.$toastr.s(`Đã lưu ga-ra`);
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },

  
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  .btn {
    padding: 0;
  }
}
.form {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
}
</style>
