import ReportApi from '../api/report_api';

export const state = {};

export const mutations = {};

export const getters = {
  
};

export const actions = {
  summary(_, data) {
    return ReportApi.summary(data);
  },
  orderStatus(_, data) {
    return ReportApi.orderStatus(data);
  },
  orderTimeLine(_, data) {
    return ReportApi.orderTimeLine(data);
  },
  orderTimeLineExcel(_, data) {
    return ReportApi.orderTimeLineExcel(data);
  },
  garageRoomProduct(_, data) {
    return ReportApi.garageRoomProduct(data);
  },
  garageRoomProductExcel(_, data){
    return ReportApi.garageRoomProductExcel(data);
  },
  exportOrderCompleted(_, data) {
    return ReportApi.exportOrderCompleted(data);
  },
  exportInvoice(_, data){
    return ReportApi.exportInvoice(data)
  }
};
