module.exports = {
  PENDING: 'pending', // đang đợi duyệt
  SHIPPING: 'shiping', // đang nhận xe
  INPROCESS: "in-process", // đang xử lý
  INQUEUE: 'in-queue', // đang đợi
  WASHING: 'washing', // đang trong quá trinh rửa  => trong quá trình này thì nhận viên k thể checkin polish
  POLISH: 'polish', // đang trong quá trình đánh bóng => ngược lại với trạng thái ở trên
  DONE: 'done', // khi dich vụ đánh bóng và rửa đã xong
  DELIVERY: 'delivery', // đang giao xe
  COMPLETE: 'complete', // hoàn thành đơn hàng
  CANCELED: 'canceled',

  getString: (status) => {
    switch (status) {
      case 'in-process':
        return 'Đã duyệt'
      case 'shiping':
          return 'Đang nhận xe'
      case 'in-queue':
        return 'Đợi đánh bóng'
      case 'washing':
        return 'Đang rửa xe'
      case 'polish':
        return 'Đang đánh bóng'

      case 'delivery':
        return 'Đang giao xe'
      case 'done':
        return 'Đợi nghiệm thu'
      case 'complete':
        return 'Đã hoàn thành'
      case 'canceled':
        return 'Đã hủy'
      default:
        return 'Đợi duyệt'
    }
  }
}
