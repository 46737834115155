import ProductApi from '../api/product_api';

export const state = {};

export const mutations = {};

export const getters = {
  
};

export const actions = {
  list(_, data) {
    return ProductApi.list(data);
  },

  create(_, data) {
    return ProductApi.create(data)
  },

  detail(_, data) {
    return ProductApi.detail(data)
  },

  update(_, data) {
    return ProductApi.update(data)
  },

  getByCondition(_, data) {
    return ProductApi.getByCondition(data)
  }
};
