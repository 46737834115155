<template>
  <div class="container-farm">
    <div id="application" class="container no-inline-space  ">
      <div class="login">
        <h1>iWash Management</span></h1>
        <section class="pb-0">
          <div id="ember9" class="row ember-view">
            <div class="form text-left mt-10">
              <div class="mt-20">
                <div>
                  <label class="acc-label">Email</label>
                </div>
                <div>
                  <input
                    autocomplete="off"
                    autocapitalize="none"
                    v-model.trim="email"
                    class="form-control login-user ember-text-field ember-view"
                    type="email"
                    @keyup.enter="login"
                  />
                </div>
              </div>
              <div class="pt-15 pb-15">
                <label class="acc-label">Password</label>
                <div>
                  <input
                    autocomplete="off"
                    autocapitalize="none"
                    v-model="password"
                    class="form-control login-pass ember-text-field ember-view"
                    type="password"
                    @keyup.enter="login"
                  />
                </div>
              </div>
              <p class="text-center">
                <button
                  class="btn bg-primary"
                  data-ember-action=""
                  data-ember-action-12="12"
                  @click="login"
                >
                  Log In
                </button>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      email: "",
    };
  },
  methods: {
    async login() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });
        location.reload();
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#application {
  padding-top: 10px;
  padding-bottom: 70px;
  min-height: 150px;
}

.container {
  max-width: 1280px;
  min-width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  .login {
    background-color: #fff;
    color: #454545;
    margin: calc((100vh - 500px) / 2) auto 0 auto;
    padding: 20px 40px 40px 40px;
    max-width: 440px;
    position: relative;
    text-align: center;
    box-shadow: 0 0 10px rgb(61 61 61 / 25%);

    h1 {
      font-size: 1.5em;
      line-height: 1.25em;
      color: #34495e;
      font-weight: 400;
      margin-top: 0;

      span {
        padding-top: 10px;
        display: block;
        font-size: 0.8em;
        line-height: 1em;
      }
    }

    .pb-0 {
      padding-bottom: 0 !important;
    }

    .form {
      margin: 0 auto;

      .acc-label {
        color: #8b959c;
        padding-bottom: 5px;
        font-size: 0.85em;
      }

      label {
        color: #3d3d3d;
        display: inline-block;
      }

      input {
        display: block;
        box-sizing: border-box;
        border-width: 1.25px;
        border-color: #dde4e6;
        border-radius: 0;
        border-style: solid;
        background: #fbfcfc;
        padding: 5px 10px;
        font-size: 15px;
        line-height: 24px;
        width: 100%;
      }

      .btn {
        min-width: 250px;
        width: 250px;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 0 solid transparent;
        padding: 8px 15px;
        letter-spacing: 0.5px;
        border-radius: 1.5px;
      }

      .bg-primary {
        background: #0075a8;
        color: #fff;
      }
    }
  }

  .text-left {
    text-align: left !important;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .pb-15 {
    padding-bottom: 15px !important;
  }

  .pt-15 {
    padding-top: 15px !important;
  }

  .text-center {
    text-align: center !important;
  }
}
</style>
