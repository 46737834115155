<template>
  <div class="menu">
    <div class="tab">
      <div
        class="menu-item"
        v-for="menu in menus"
        :key="menu.id"
        @click="$router.replace(menu.route)"
        :class="{ active: currentMenu && menu.id == currentMenu.id }"
      >
        <img :src="menu.icon" />
        <div class="name">{{ menu.name }}</div>
      </div>
    </div>
    <div class="sub-menu" v-if="this.currentMenu && this.currentMenu.submenus.length">
      <div
      
        v-for="submenu in this.currentMenu.submenus"
        :key="submenu.id"
        class="name"
        :class="{
          active: currentSubMenu && currentSubMenu.id == submenu.id,
        }"
        @click.prevent="submenu.route && $router.replace(submenu.route)"
      >
        {{ submenu.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import MenuMaster from "../assets/jsons/menu.master.json";
import MenuManager from "../assets/jsons/menu.manager.json";

export default {
  data() {
    return {
      currentMenu: null,
      currentSubMenu: null,
      menus: MenuManager,
    };
  },
  created() {
    if(this.user.role == 4){
      this.menus = MenuMaster // role master
    }

    let pathname = location.pathname;
    let menu = this.menus.firstWhere(
      (menu) => menu.route != "/" && pathname.includes(menu.route)
    );
    this.currentMenu = menu;

    if (this.currentMenu && this.currentMenu.submenus) {
      this.currentSubMenu = this.currentMenu.submenus.firstWhere(
        (sub) => pathname == sub.route
      );
    }
  },
  computed: {
    ...mapGetters('account', ['user'])
  },
  methods: {
    selectMenu(menu) {
      this.currentMenu = menu;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  display: flex;
  flex-direction: column;
  height: 100px;
  .tab {
    display: flex;

    .menu-item:hover {
      background: #efefef;
    }
    .menu-item.active {
      background: white;
    }
    .menu-item {
      height: 50px;
      display: flex;
      flex-direction: row;
      padding: 14px 20px;

      align-items: center;
      cursor: pointer;
      width: auto;
      img {
        width: 16px;
        height: 16px;
      }
      .name {
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }

  .sub-menu {
    height: 50px;
    display: flex;
    flex-direction: row;
    padding: 14px 20px;
    background: white;

    align-items: center;
    cursor: pointer;
    width: auto;

    .name {
      font-size: 14px;
      margin-right: 24px;
    }
    .name:hover {
      color: #00c292;
    }
    .name.active {
      color: #00c292;
    }
  }
}
</style>
