export default {
  logout: () =>{
    return window.$.ajax({
      method: 'DELETE',
      url: process.env.VUE_APP_API_URL.concat('/user/logout'),
      contentType: 'application/json'
    });
  },
  me: ()=>{
    return window.$.getOnce(process.env.VUE_APP_API_URL.concat('/user/me'))
  },

  getProfile: () => {
    return window.$.ajax({
      method: 'GET',
      url: process.env.VUE_APP_API_URL.concat('/account/profile'),
      contentType: 'application/json'
    });
  },

  register: (data) => {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/account/register'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },

  resendEmail: (data) => {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/account/resend-email'),
      data: JSON.stringify(data),
      headers: {
        'X-API-TOKEN': localStorage.getItem('token')
      },
      contentType: 'application/json'
    });
  },
  usernameExists: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/account/username-exists'), query);

  },

  validationAccount: (tokenHash) => {

    return window.$.ajax({
      method: 'PUT',
      url: process.env.VUE_APP_API_URL.concat('/account/validation-account'),
      data: JSON.stringify({
        tokenHash: tokenHash
      }),
      contentType: 'application/json'
    });

  },
  updateLoginCredential(data) {
    return window.$.ajax({
      method: 'PUT',
      url: process.env.VUE_APP_API_URL.concat('/account/login-credentials'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },

  //admin
  list: (query) => {
    return window.$.getOnce(process.env.VUE_APP_API_URL.concat('/user/admin/list'), query);
  },
  create(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/user/admin/create'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
  detail: (payload) => {
    return window.$.get(`${process.env.VUE_APP_API_URL}/user/admin/detail/${payload.id}`);
  },
  update(payload) {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/user/admin/update/${payload.user.id}`,
      data: JSON.stringify(payload.user),
      contentType: 'application/json'
    });
  },
  lookup: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/user/admin/lookup'), query);
  },

  adminResetPassword: (body) => {
    return window.$.ajax({
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/user/admin/reset-password`,
      data: JSON.stringify(body),
      contentType: 'application/json'
    });
  },

  overview: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/user/overview'), query);
  },

  timelines: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/user/timelines'), query);
  },

  worklists: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/order/assigns'), query);
  },
};
