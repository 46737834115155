import CustomerApi from '../api/customer_api';

export const state = {};

export const mutations = {};

export const getters = {};

export const actions = {
  find(_, {
    phone,
    name,
    address
  }) {
    return CustomerApi.find({
      phone,
      name,
      address
    }).then(resp => resp.customerLists);
  },

  all(_, query) {
    return CustomerApi.all(query)
  },



};