<template>
  <default-layout>
    <!-- <div class="search-box">
      <div class=""></div>
    </div> -->
    <div style="height: 16px" />
    <div class="customer">
      <table-count :meta="meta" @refresh="fetchData"></table-count>
      <div class="content">
        <table>
          <thead>
            <td>Mã</td>
            <td>Họ & tên</td>
            <td>Số điện thoại</td>
            <td>Địa chỉ</td>
          </thead>
          <tbody>
          <tr v-if="!customerLists || customerLists.length < 1">
            <td colspan="6"><span class="text-danger"> Không có dữ liệu </span></td>
          </tr>
            <tr v-else v-for="customer of customerLists" :key="customer.id">
              <td>{{ customer.id }}</td>
              <td>{{ customer.name }}</td>
              <td>{{ customer.phone }}</td>
              <td>{{ customer.address }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginate-view
        style="justify-content: right;"
        :page="meta.page"
        :totalPage="meta.totalPage"
        @onPage="meta.page = String($event)"
      ></paginate-view>
    </div>
  </default-layout>
</template>

<script>
import PaginateView from "../../../components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import TableCount from '../../../components/TableCount.vue';
import {isEmpty} from "lodash";
export default {
  components: { DefaultLayout, PaginateView, TableCount },
  data() {
    return {
      firstLoading: true,
      customerLists: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
    };
  },
  created() {
    this.fetchData(1);
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Vui lòng đợi...",
      });
      try{
        let resp = await this.$store.dispatch("customer/all", {
          page,
          status: 'inactive'
        });
        if(isEmpty(resp.customerLists) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.customerLists = resp.customerLists;
        this.meta = resp.meta;
      }catch (e){
        this.$toastr.e(e.responseJSON.message);
      }finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.customer {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
   
  }
}
</style>
