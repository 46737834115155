import { render, staticRenderFns } from "./ChangePasswordTab.vue?vue&type=template&id=548fcc98&scoped=true&"
import script from "./ChangePasswordTab.vue?vue&type=script&lang=js&"
export * from "./ChangePasswordTab.vue?vue&type=script&lang=js&"
import style0 from "./ChangePasswordTab.vue?vue&type=style&index=0&id=548fcc98&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548fcc98",
  null
  
)

export default component.exports