<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <label>Thông tin đăng nhập tài khoản</label>
        <ul>
          <li>
            <b>Email:</b> {{ email }}
          </li>
          <li>
            <b>Mật khẩu:</b> {{ passwordRoot }}
          </li>
        </ul>
      </div>
      <div style="height: 20px" />
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
export default {
  components: { ModalBase },
  props: ["email","passwordRoot"],
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
    b{
      font-weight: 600;
    }
  }
}
.btn-primary {
  border-color: #000000;
  background: #000000;
  color: white !important;
}
</style>
