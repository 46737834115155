import axios from 'axios'

export default {
  //admin
  summary: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/report/admin/summary'), query);
  },
  orderStatus: (query) => {
    return window.$.get(process.env.VUE_APP_API_URL.concat('/report/admin/orderStatus'), query);
  },
  orderTimeLine: (query) => {
    return window.$.ajax({
      method: 'GET',
      url: process.env.VUE_APP_API_URL.concat('/report/admin/orderTimeLine'),
      data: query,
      xhrFields:{
        responseType: 'blob'
      },
    });
  },
  orderTimeLineExcel: (query) => {
    return window.$.ajax({
      method: 'GET',
      url: process.env.VUE_APP_API_URL.concat('/report/admin/orderTimeLineExcel'),
      data: query,
      xhrFields:{
        responseType: 'blob'
      },
    });
  },
  garageRoomProduct: (query) => {
    return window.$.ajax({
      method: 'GET',
      url: process.env.VUE_APP_API_URL.concat('/report/admin/pdf/tonkho'),
      data: query,
      xhrFields:{
        responseType: 'blob'
      },
    });
  },
  garageRoomProductExcel: (query) => {
    return window.$.ajax({
      method: 'GET',
      url: process.env.VUE_APP_API_URL.concat('/report/admin/garageRoomProduct'),
      data: query,
      xhrFields:{
        responseType: 'blob'
      },
    });
  },
  exportOrderCompleted: (query) => {
    let pathStr = '/report/admin/exportOrderCompleted';
    if(query.type && query.type == 'pdf'){
      pathStr = '/report/admin/pdf/doanhthu'
    }
    return window.$.ajax({
      method: 'GET',
      url: process.env.VUE_APP_API_URL.concat(pathStr),
      data: query,
      xhrFields:{
        responseType: 'blob'
      },
    });
  },
  exportInvoice: ({order_id})=>{
    return window.$.ajax({
      method: 'GET',
      url: process.env.VUE_APP_API_URL.concat(`/report/admin/pdf/hoadon/${order_id}`),
      xhrFields:{
        responseType: 'blob'
      },
    });
  }
};
