import BundleApi from '../api/bundler_api';

export const state = {};

export const mutations = {};

export const getters = {};

export const actions = {
  lists() {
    return BundleApi.lists().then(resp => resp.data);
  },

  info(_, {id}) {
    return BundleApi.info(id).then(resp => {
      if(!resp.bundler || !resp.stores){
        throw new Error("no data")
      }
      return resp;
    })
  }

};