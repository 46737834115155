<template>
  <modal-base @close="$emit('close')">
    <div class="content">
      <label style="font-weight: bold;">Chọn nhân viên</label>
      <div style="height: 12px" />
      <div class="control" :class="{error: errors.has('field')}">
        <staff-assign-view
          mName="field"
          v-if="type == 'polish'"
          style="height: 100%;"
          :value="value"
          :garageId="garageId"
          @input="assign_lists = $event"
        ></staff-assign-view>
       
        <select-lazy-loading-view
          v-else
          mName="field"
          dispatch="garage/staffs"
          keyData="data"
          :current="userWashingId"
          :payload="{ garage_id: garageId }"
          @onChange="assign_lists = [$event]"
        ></select-lazy-loading-view>
      </div>
      <div style="height: 12px" />
      <div
        class="btn btn-submit"
        style="min-width: 120px; margin: auto;  color: white !important;"
        @click="updateStaffRoom()"
      >
        Lưu
      </div>
    </div>
  </modal-base>
</template>

<script>
import { mapActions } from 'vuex';
import ModalBase from "../../../../components/ModalBase.vue";
import SelectLazyLoadingView from "../../../../components/SelectLazyLoadingView.vue";
import StaffAssignView from "../../../../components/StaffAssignView.vue";
export default {
  components: { ModalBase, StaffAssignView, SelectLazyLoadingView },
  props: ["type", "value", "roomId", "garageId"],
   provide() {
    return { parentValidator: this.$validator };
  },
  data() {
    return {
      assign_lists: this.value.map(user => user.id),
    };
  },
  computed: {
    userWashingId() {
      if (this.value && this.value.length > 0) {
        return this.value[0].id;
      }
      return 0;
    },
  },
 
  methods: {
     ...mapActions("global", ["validateAll"]),
    async updateStaffRoom() {
      const result = await this.validateAll(this);
      if (!result) {
        return;
      }
      
      this.$store.dispatch("global/loading", {
        isLoading: true,
       
      });

      try {
        await this.$store.dispatch("garage/roomMemberUpdate", {
          userIds: this.assign_lists,
          roomId: this.roomId,
          garageId: this.garageId,
        });
        this.$emit('reload');
        this.$emit('close');
      } catch (e) {
      } finally {
        // this.$store.dispatch("global/loading", {
        //   isLoading: false,
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: 100px;
  .control {
    min-height: 40px;
    border: 1px solid #ededed;
    align-items: center;
    display: flex;
    padding: 5px;
  }
  .control.error{
    border-color: darkred;
  }
}
</style>
