<template>
  <select v-model="model" :name="mName" v-validate="validate || ''" ref="select2" style="width: 100%; height: 100%;"> </select>
</template>

<script>
export default {
  inject: ["parentValidator"],
  props: ["value", "role", 'validate', "mName"],
  data(){
    return {
      model: null,
    }
  },
   created() {
    this.$validator = this.parentValidator;
  },
  mounted() {
    console.log(this.validate);
    const el = window.$(this.$refs["select2"]);
    if (this.value) {
      this.value.forEach(function(e) {
        if (!el.find("option:contains(" + e.text + ")").length)
          el.append(
            window
              .$("<option selected>")
              .text(e.text)
              .val(e.id)
          );
      });
    }

    el.select2({
      ajax: {
        url: `${process.env.VUE_APP_API_URL}/user/admin/list`,
        dataType: "json",
        data: (params) => {
          return {
            role: this.role,
            search: params.term ? params.term.trim() : null,
          };
        },
        processResults: function(data) {
          var result_data = window.$.map(data.data, function(obj) {
            return {
              ...obj,
              text: obj.name,
            };
          });
          return {
            results: result_data,
          };
        },
      },

      placeholder: "- Chọn -",
      templateResult: (repo) => {
        if(repo.loading) return 'Đang cập nhật...'
        return `${repo.text} - ${repo.phone}`;
      },
      templateSelection: (repo) => {
        return repo.text;
      },
      "language": {
        "noResults": function(){
          return "Không tìm thấy kết quả";
        }
      },
    })
      .trigger("change")
      .on("change", () => {
        const selected = window.$(this.$refs["select2"]).select2("data");
        const selectIds = selected.map((x) => Number(x.id));
        this.model = selectIds[0]
        this.$emit("input", selectIds[0]);
      });
  },

  beforeDestroy() {
    window
      .$(this.$refs["select2"])
      .off()
      .select2("destroy");
  },
};
</script>

<style lang="scss" scoped></style>
