<template>
  <div id="app">
    <RouterView />
    <div v-if="loading.isLoading" class="loading">
      <div class="box">
        <div class="loader icon-loader"></div>
        <div class="msg">{{ loading.msg }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "app",
  computed: {
    ...mapState("global", {
      loading(status) {
       
        document.body.style.setProperty(
          "overflow",
          status.loading.isLoading ? "hidden" : "auto"
        );

        return status.loading;
      },
    }),
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .icon-loader {
    margin: auto;
    width: 60px;
    height: 60px;
  }
  .box{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 40%;
    user-select: none;
    .msg{
      margin: auto;
      margin-top: 20px;
    }
  }
  .loading {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .loading > img {
    margin: auto;
  }
}
</style>
