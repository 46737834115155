<template>
  <default-layout>
    <div v-if="!order" class="container" style="padding: 40px">
      <div class="loader icon-loader"></div>
    </div>
    <div class="container" v-else>
      <div class="title" style="display: flex; align-items: center">
        <div style="flex: 1; display: flex; align-items: top">
          <div style="display: flex; flex-direction: column; margin: 2px">
            <div>Chi tiết đơn hàng #{{ order.info.id }}</div>
            <div style="height: 4px" />
            <div style="color: #a9a9a9; font-size: 14px">
              {{ order.info.garage.name }}
            </div>
          </div>
          <div style="width: 10px" />
          <div v-html="order.info.status.toStatusHtml()"></div>
        </div>
        <div
          @click.prevent="isShowHistory = true"
          style="
            width: 146px;
            padding: 10px;
            font-size: 14px;
            cursor: pointer;
            text-align: center;
            color: #b62126;
          "
        >
          Xem lịch sử
        </div>
         <div
         v-if="order.info.status == 'complete'"
         @click="exportInvoice()"
         class="btn btn-submit"
         style="width: 146px;padding: 10px;font-size: 14px;cursor: pointer;text-align: center;justify-content: center;align-items: center;display: flex;"
        >
        <img src="@/assets/icons/pdf.png" width="20" style="margin-right: 4px">
         Hóa đơn
        </div>
      </div>
      <div style="height: 30px" />
      <div class="content">
        <div class="customer">
          <div class="title">Thông tin gói</div>
          <div style="height: 12px" />
          <div v-if="order.info.status == 'pending' && user.role != 4">
            <div class="form-group">
              <div
                style="padding: 0"
                class="input-control"
                :class="{ error: errors.has('Chọn gói') }"
              >
                <select-bundler-view
                  :options="[order.info.bundle]"
                  :value="bundleId"
                  validate="required"
                  mName="Chọn gói"
                  @input="bundleId = $event"
                ></select-bundler-view>
              </div>
            </div>
            <bunder-info-view
              :bundleId="bundleId"
              @onPrice="price = $event"
            ></bunder-info-view>
          </div>
          <div v-else>
            <div class="info">
              <label
                style="width: 100%; font-weight: 800; word-break: break-word"
              >
                {{ order.info.bundle.name }}
              </label>
            </div>
            <div style="height: 10px" />
            <div class="info" style="flex-direction: column">
              <div
                v-for="product of order.products"
                :key="product.id"
                style="margin-bottom: 10px; word-break: break-word"
              >
                - {{ product.product.name }} ( {{ product.amount
                }}{{ product.product.unit }}
                )
              </div>
            </div>
          </div>

          <div style="height: 20px" />
          <div class="title" style="display: flex">
            Phân công
            <div style="flex: 1" />
            <div
              style="cursor: pointer; color: rgb(182, 33, 38)"
              @click="isShowChangeSupport = true"
              v-if="
                user.role == 3 && ['in-process'].includes(order.info.status)
              "
            >
              Nhân viên hỗ trợ
            </div>
          </div>
          <div style="height: 14px" />
          <div
            v-if="['pending'].includes(order.info.status)"
            style="font-style: italic"
          >
            Hệ thống sẽ tự động phân công nhân viên
          </div>
          <div v-else>
            <div class="info" style="">
              <div style="display: flex">
                <label style="flex: 1">Nhân viên rửa xe</label>
                <!-- <div
                v-if="order.info.status == 'pending' && user.role == 3"
                style="cursor: pointer;color: rgb(182, 33, 38);"
                @click.prevent="editStaff('washing')"
              >
                Chỉnh sửa
              </div> -->
              </div>
              <div style="height: 10px" />
              <div class="tag-list">
                <div
                  v-if="order.washing_list.length == 0"
                  style="font-style: italic; padding: 10px"
                >
                  Không có phân công
                </div>
                <div v-else>
                  <a
                    class="tag washing"
                    v-for="washing of order.washing_list"
                    :key="washing.user_id"
                    :href="`/staff/${washing.user_id}/detail`"
                  >
                    <span v-if="washing.is_support">[HT]</span>
                    {{ washing.user.name }}
                  </a>
                  <div style="font-weight: 300; padding: 10px">
                    Mã phòng: #{{ order.washing_list[0].garage_room_id }}
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 14px" />
            <div class="info">
              <div style="display: flex">
                <label style="flex: 1">Nhân viên đánh bóng</label>
                <!-- <div
                style="cursor: pointer;color: rgb(182, 33, 38);"
                @click.prevent="editStaff('polish')"
                v-if="order.info.status == 'pending' && user.role == 3"
              >
                Chỉnh sửa
              </div> -->
              </div>
              <div style="height: 10px" />
              <div class="tag-list">
                <div
                  v-if="order.polish_list.length == 0"
                  style="font-style: italic; padding: 10px"
                >
                  Không có phân công
                </div>
                <div v-else>
                  <a
                    class="tag"
                    v-for="polish of order.polish_list"
                    :key="polish.user_id"
                    :href="`/staff/${polish.user_id}/detail`"
                  >
                    <span v-if="polish.is_support">[HT]</span>
                    {{ polish.user.name }}
                  </a>
                  <div style="font-weight: 300; padding: 10px">
                    Mã phòng: #{{ order.polish_list[0].garage_room_id }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="height: 20px" />
          <div class="title" style="display: flex; align-items: center">
            <span style="flex: 1">Ghi chú</span>
            <div
              style="padding: 0"
              class="btn btn-link"
              @click.prevent="isShowStaffNote = true"
            >
              Ghi chú nhân viên
            </div>
          </div>
          <div style="height: 10px" />
          <div style="width: 100%; word-break: break-word">
            {{ order.info.note || "--" }}
          </div>
          <div style="height: 20px" />
        </div>

        <div style="width: 10px" />
        <div class="customer" style="flex: 1">
          <div class="title">Khách hàng</div>
          <div style="height: 12px" />
          <div class="info" style="flex-direction: row">
            <label>Tên</label>
            <div class="value">{{ order.info.customer.name || "--" }}</div>
          </div>
          <div style="height: 10px" />
          <div class="info" style="flex-direction: row">
            <label>SDT</label>
            <div class="value">{{ order.info.customer.phone || "--" }}</div>
          </div>
          <div style="height: 10px" />
          <div class="info" style="flex-direction: row">
            <label>Email</label>
            <div class="value">{{ order.info.customer.email || "--" }}</div>
          </div>
          <div style="height: 10px" />
          <div class="info" style="flex-direction: row">
            <label>Địa chỉ</label>
            <div class="value">{{ order.info.customer.address || "--" }}</div>
          </div>
          <div style="height: 10px" />
          <div class="info" style="flex-direction: row">
            <label>Biển số xe</label>
            <div class="value">{{ order.info.license_plates || "--" }}</div>
          </div>
          <div style="height: 10px" />
          <div class="info" style="flex-direction: row">
            <label>Ảnh xe</label>
            <img
              v-if="order.info.car_image"
              :src="order.info.car_image"
              style="width: 200px; height: 300px; object-fit: contain"
            />
            <div class="value" v-else>[Không có ảnh]</div>
          </div>

          <div style="height: 20px" />
          <div class="title">Thanh toán</div>
          <div style="height: 12px" />
          <div class="info" style="flex-direction: row">
            <div style="width: 200px">Giá bán:</div>
            <span style="font-weight: bold"
              >{{ order.info.price.formatCurrency() }}đ</span
            >
          </div>
          <div class="info" style="flex-direction: row">
            <div style="width: 200px">Giảm giá:</div>
            <div>
            <div style="font-weight: bold"
              >{{ order.info.price_discount.formatCurrency() }}đ
            </div>
            <div v-if="order.info.discount_info &&  order.info.discount_info.note" style="margin-left: 4px;font-style: italic;font-weight: normal; width: 320px; font-size: 12px;" >
              Ghi chú: {{ order.info.discount_info.note }}
            </div>
            </div>
          </div>
          <div
            style="width: 400px; height: 1px; margin: 10px 0; background: #ddd"
          />
          <div class="info" style="flex-direction: row">
            <div style="width: 200px">Thành tiền:</div>
            <span style="font-weight: bold"
              >{{
                (order.info.price - order.info.price_discount).formatCurrency()
              }}đ</span
            >
          </div>
          <div style="height: 20px" />
          <div
            v-if="
              (order.info.payment_info && order.info.payment_info.image) ||
              user.role == 4
            "
            style="display: flex; flex-direction: column"
          >
            <div style="display: flex">Ảnh bằng chứng</div>
            <div style="height: 12px" />
            <img
              v-if="order.info.payment_info && order.info.payment_info.image"
              :src="order.info.payment_info.image"
              style="
                width: 200px;
                height: 300px;
                object-fit: contain;
                margin: auto;
              "
            />
            <div v-else style="font-style: italic">[Không có ảnh]</div>
            <div style="height: 14px" />
            <div>Nội dung chuyển khoản</div>
            <div style="height: 12px" />
            <div style="font-style: italic" v-if="order.info.payment_info">
              {{ order.info.payment_info.note || "[Không có nội dung]" }}
            </div>
            <div style="font-style: italic" v-else>[Không có nội dung]</div>
          </div>
          <div
            v-else-if="!['complete', 'canceled'].includes(order.info.status)"
            class="input-control"
            style="border: 0"
          >
            <div class="form-group">
              <label style="display: flex"
                >Ảnh bằng chứng
                <div style="flex: 1"></div>
                <div
                  style="color: green; cursor: pointer"
                  @click="isShowUpdatePaymentInfo = true"
                >
                  Cập nhật thông tin
                </div></label
              >
              <div style="font-style: italic">[Không có ảnh]</div>
            </div>
            <div class="form-group">
              <label>Nội dung thanh toán</label>
              <div style="font-style: italic">[Không có ảnh]</div>
            </div>
          </div>

          <div style="height: 20px" />
          <div style="display: flex; margin: auto">
            <button
              class="btn btn-danger"
              @click.prevent="isShowcancel = true"
              style="width: 140px; margin: 10px"
              v-if="
                !['canceled', 'done', 'complete'].includes(order.info.status)
              "
            >
              Hủy đơn
            </button>
            <button
              class="btn btn-primary"
              style="
                width: 140px;
                background: #218379;
                color: white;
                margin: 10px;
              "
              v-if="order.info.status == 'pending' && user.role == 3"
              @click.prevent="validateBrowse"
            >
              Duyệt đơn
            </button>
            <button
              class="btn btn-submit"
              style="width: 150px; margin: 10px"
              v-if="order.info.status == 'done'"
              @click.prevent="complete()"
              :disabled="
                !order.info.payment_info ||
                !order.info.payment_info.note ||
                !order.info.payment_info.image
              "
            >
              Hoàn thành
            </button>
          </div>
        </div>
      </div>

      <modal-change-support-staff
        @close="isShowChangeSupport = false"
        @reload="fetchData(true)"
        v-if="isShowChangeSupport"
        :order="order"
      ></modal-change-support-staff>
    </div>

    <modal-staff-note-order
      v-if="isShowStaffNote"
      @close="isShowStaffNote = false"
      :orderId="orderId"
    ></modal-staff-note-order>

    <modal-update-payment-info
      :orderId="orderId"
      v-if="isShowUpdatePaymentInfo"
      @reload="fetchData(true)"
      @close="isShowUpdatePaymentInfo = false"
    ></modal-update-payment-info>
    <modal-assign-staff
      v-if="isShowAssign"
      @close="isShowAssign = false"
      @reload="fetchData(true)"
      :orderId="orderId"
      :type="editAssignType"
      :value="currentAssignLists"
    ></modal-assign-staff>

    <modal-confirm-cancel-order
      v-if="isShowcancel"
      @close="isShowcancel = false"
      @ok="cancelOrder($event)"
    ></modal-confirm-cancel-order>
    <modal-browse-order
      :orderId="orderId"
      v-if="isShowBrowse"
      @close="isShowBrowse = false"
      @ok="browse($event)"
    ></modal-browse-order>
    <modal-order-history
      :orderId="orderId"
      v-if="isShowHistory"
      @close="isShowHistory = false"
    ></modal-order-history>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import ModalAssignStaff from "./modal/ModalAssignStaff.vue";
import ModalConfirmCancelOrder from "./modal/ModalConfirmCancelOrder.vue";
import ModalBrowseOrder from "./modal/ModalBrowseOrder.vue";
import ModalOrderHistory from "./modal/ModalOrderHistory.vue";
import { mapActions, mapGetters } from "vuex";
import SelectBundlerView from "../../../components/SelectBundlerView.vue";
import BunderInfoView from "../../../components/BunderInfoView.vue";
import ModalUpdatePaymentInfo from "./modal/ModalUpdatePaymentInfo.vue";
import ModalChangeSupportStaff from "./modal/ModalChangeSupportStaff.vue";
import ModalStaffNoteOrder from "./modal/ModalStaffNoteOrder.vue";
export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  components: {
    DefaultLayout,
    ModalAssignStaff,
    ModalConfirmCancelOrder,
    ModalBrowseOrder,
    ModalOrderHistory,
    SelectBundlerView,
    BunderInfoView,
    ModalUpdatePaymentInfo,
    ModalChangeSupportStaff,
    ModalStaffNoteOrder,
  },

  data() {
    return {
      isShowStaffNote: false,

      isShowChangeSupport: false,
      isShowUpdatePaymentInfo: false,
      isShowHistory: false,

      isShowBrowse: false,
      isShowcancel: false,
      isShowAssign: false,
      isLoading: true,
      editAssignType: "washing",
      currentAssignLists: [],
      orderId: null,
      bundleId: null,
      order: null,
      payment_info: {
        image: null,
        note: null,
      },
      imageFile: null,
    };
  },

  created() {
    this.orderId = this.$route.params.id;
    this.isLoading = true;
    this.fetchData();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    async complete() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Vui lòng đợi...",
      });
      try {
        await this.$store.dispatch("order/complete", {
          order_id: this.orderId,
        });
        this.$toastr.s(`Đã hoàn thành đơn #${this.orderId}.`);
        this.fetchData();
      } catch (e) {
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    selectImage(ev) {
      this.imageFile = ev.target.files[0];
    },

    editStaff(type) {
      this.editAssignType = type;

      this.currentAssignLists = this.order[`${type}_list`].map((item) => {
        return {
          id: item.user.id,
          text: item.user.name,
        };
      });
      this.isShowAssign = true;
    },

    async cancelOrder(note) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Vui lòng đợi...",
      });
      try {
        await this.$store.dispatch("order/cancel", {
          id: this.orderId,
          note,
        });
        this.$toastr.s(`Đã hủy đơn #${this.orderId}.`);
        // forceRenrender
        this.fetchData();
      } catch (e) {
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },

    async validateBrowse() {
      const result = await this.validateAll(this);
      if (!result) {
        return;
      }
      this.isShowBrowse = true;
    },

    async browse() {
      this.isShowBrowse = false;
      this.orderBrowse();

      // this.$store.dispatch("global/loading", {
      //   isLoading: true,
      //   msg: "Đang upload ảnh...",
      // });
      // try {
      //   let imageObj = await this.$store.dispatch("media/upload", {
      //     file: this.imageFile,
      //   });
      //   this.payment_info.image = imageObj.url;

      //   await this.orderBrowse();
      // } catch (e) {
      //   this.$toastr.e(
      //     `Lỗi tải ảnh. Vui lòng chọn đúng file định dạng .png hoặc .jpg`
      //   );
      // } finally {
      //   this.$store.dispatch("global/loading", {
      //     isLoading: false,
      //   });
      // }
    },

    async orderBrowse() {
      try {
        this.$store.dispatch("global/loading", {
          isLoading: true,
          msg: "Đang duyệt đơn",
        });
        await this.$store.dispatch("order/browse", {
          order_id: this.orderId,
          bundle_id: this.bundleId,
          payment_info: this.payment_info,
        });
        this.$toastr.s(`Đã duyệt đơn #${this.orderId}.`);
        this.fetchData();
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },

    async fetchData(isUpdate = false) {
      this.order = await this.$store.dispatch("order/info", {
        id: this.orderId,
      });
      if (!isUpdate) {
        this.bundleId = this.order.info.bundle_id;
      }
    },

    async exportInvoice(){
      try{
        let res = await this.$store.dispatch("report/exportInvoice", {
           order_id: this.orderId,
        });
        this.firstLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `HoaDon_${this.orderId}_${Date.now()}.pdf`);
        document.body.appendChild(docUrl);
        docUrl.click();
      }catch(e){
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
      }
     
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-submit:disabled {
  background: #b9b9b9;
  border-color: #b9b9b9;
}
.container {
  background: white;
  padding: 18px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .tag {
    display: inline-block;
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 6px;
    background: #7172d7;
    color: white;
    width: auto;
    margin: 4px;
    white-space: nowrap;
  }
  .tag-list {
    display: block;
    .tag {
      cursor: pointer;
    }
    .tag.washing {
      background: #bb863b;
    }
  }

  .content {
    display: flex;
    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .payment {
      padding: 10px;
      flex: 1;
      border: 1px solid #ededed;
    }
    .customer {
      display: flex;
      flex-direction: column;
      padding: 18px;
      flex: 0.75;
      border: 1px solid #ededed;
      .info {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        label {
          width: 30%;
        }
      }
    }
  }
  .history {
    padding: 10px;
    flex: 1;
    border: 1px solid #ededed;
  }

  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
}
</style>