<template>
  <div>
    <div class="box-content">
      <table-count :meta="meta" @refresh="fetchData(meta.page)"></table-count>
      <div style="height: 10px" />
      <div class="form-group mb-0">
        <div>
          <table>
            <thead>
              <td>Thời gian</td>
              <td>File hoá đơn</td>
             <td style="min-width: 120px;">Hành động</td>
            </thead>
            <tbody>
            <tr v-if="!histories || histories.length < 1">
              <td colspan="6"><span class="text-danger"> Không có dữ liệu </span></td>
            </tr>
              <tr v-else v-for="(row, index) of histories" :key="index">
                <td style="word-break: break-word; ">
                  {{ moment(row.createdAt).format("YYYY-MM-DD HH:mm") }}
                </td>
                <td style="word-break: break-word; width: 200px;">
                  <a
                    v-if="row.fileUrl"
                    class=" btn btn-link btn-sm"
                    :href="row.fileUrl"
                    target="_blank"
                    >Xem nội dung</a
                  >
                </td>
                <td style="word-break: break-word; width: 200px;">
                  <div style=" display: flex;     justify-content: center;">
                    <button
                      class="btn btn-info  btn-sm"
                      style="min-width: auto"
                      @click.prevent="viewDetail(row.products)"
                    >
                      Xem chi tiết
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <paginate-view
      style="justify-content: right;"
      :page="meta.page"
      :totalPage="meta.totalPage"
      @onPage="meta.page = String($event)"
    ></paginate-view>
    <modal-product-lists
      @close="showModal = false"
      v-if="showModal"
      :products="products"
    ></modal-product-lists>
  </div>
</template>

<script>
import ModalGarageProductTable from "../modal/ModalGarageProductTable";
import SelectLazyLoadingView from "@/components/SelectLazyLoadingView";
import { isEmpty, map } from "lodash";
import ModalProductLists from "../modal/ModalProductLists.vue";
import moment from "moment";
import TableCount from "../../../../components/TableCount.vue";
import PaginateView from "../../../../components/PaginateView.vue";

export default {
  components: {
    ModalGarageProductTable,
    SelectLazyLoadingView,
    ModalProductLists,
    TableCount,
    PaginateView,
  },
  props: ["garageId"],
  data() {
    return {
      moment,
      showModal: false,
      isShowProduct: false,
      histories: [],
      products: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
    };
  },
  watch: {
    garageId(val) {
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    download(link) {
      var element = document.createElement("a");

      element.href = link;
      element.download = "output.png";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    getTypeText(type) {
      switch (type) {
        case "add":
          return "Nhập kho";
        case "swap":
          return "Chuyển kho";
      }
    },
    viewDetail(products) {
      this.showModal = true;
      this.products = products;
    },
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        // msg: "Vui lòng đợi...",
      });
      try {
        const resp = await this.$store.dispatch("inventory/history", {
          garage_id: this.garageId,
          page: page,
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.histories = resp.data;
        this.meta = resp.meta;
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e("Lỗi kết nối. Vui lòng thử lại sau.");
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          // msg: "Vui lòng đợi...",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box-content {
  background: white;
  padding: 16px 18px;
  margin-bottom: 32px;
  min-height: 300px;

  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
  .row-danger {
    background: #f5c6cb;
  }
}
</style>
