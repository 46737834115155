<template>
  <div class="content">
    <form>
      <div class="form-group">
        <label>Mật khẩu cũ</label>
        <div class="input-control">
          <input />
        </div>
      </div>

      <div class="form-group">
        <label>Mật khẩu mới</label>
        <div class="input-control">
          <input />
        </div>
      </div>

      <div class="form-group">
        <label>Xác nhận lại mật khẩu</label>
        <div class="input-control">
          <input />
        </div>
      </div>
      <div style="height: 15px;" />

      <div class="form-group">
        <button class="btn btn-submit" style="width: 200px;" @click.prevent="changePassword()">
          Đổi mật khẩu
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {

  computed:{
    ...mapGetters('account', 'user')
  },

  methods:{
    async changePassword(){

    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  width: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  select,
  textarea,
  input {
    border: 0;
    background: transparent;
    width: 100%;
  }
  .input-control {
    border: 1px solid #b9b9b9;
    padding: 8px;
  }
}
</style>
