<template>
  <default-layout>
    <div id="reportTimeLineEl">
      <div class="search-box">
        <form>
          <div class="form-group">
            <h3>Xuất dữ liệu checkin công việc</h3>
            <label>Chọn khoảng thời gian</label>
            <div class="form-control">
              <input type="text" id="dateFilter" value="" />
            </div>
          </div>
          <div class="action">
            <button class="btn btn-submit" @click.prevent="exportData()">
              Xuất dữ liệu
            </button>
            <div class="btn" style="display:flex;" @click="exportExcel()"><img src="@/assets/icons/excel.png" width="20">&nbsp;&nbsp;Excel</div>

          </div>
        </form>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/router/layouts/default";
import moment from 'moment'
import "daterangepicker/daterangepicker.css";
export default {
  components: {
    DefaultLayout
  },
  data(){
    return {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    }
  },
  mounted() {
    const thiss = this
    window.$('#dateFilter').daterangepicker({
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      "alwaysShowCalendars": true,
      "startDate": this.startDate,
      "endDate": this.endDate
    }, function(start, end, label) {
      thiss.changeDate(start,end)
    });
  },
  methods: {
    changeDate(start,end){
      this.startDate = start
      this.endDate = end
    },
    
    async exportExcel(){
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let startDate = this.startDate.format('YYYY-MM-DD')
        let endDate = this.endDate.format('YYYY-MM-DD')
        let res = await this.$store.dispatch("report/orderTimeLineExcel", {
          startDate,
          endDate
        });
        this.firstLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `Lịch sử làm việc ${startDate}_${endDate}.xlsx`);
        document.body.appendChild(docUrl);
        docUrl.click();
      } catch (e) {
        let msg = 'Server gặp sự cố. Vui lòng thử lại sau'
        if(e.status){
          msg = 'Không có dữ liệu!'
        }
        this.$toastr.e(msg);
      }finally{
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },

    async exportData(type="xlsx"){
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let startDate = this.startDate.format('YYYY-MM-DD')
        let endDate = this.endDate.format('YYYY-MM-DD')
        let res = await this.$store.dispatch("report/orderTimeLine", {
          startDate,
          endDate
        });
        this.firstLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `Lịch sử làm việc ${startDate}_${endDate}.zip`);
        document.body.appendChild(docUrl);
        docUrl.click();
      } catch (e) {
        let msg = 'Server gặp sự cố. Vui lòng thử lại sau'
        if(e.status){
          msg = 'Không có dữ liệu!'
        }
        this.$toastr.e(msg);
      }finally{
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    }
  }
}
</script>

<style lang="scss">
#reportTimeLineEl{
  .action{
    padding: 0 12px;
    justify-content: left;
  }
}
.daterangepicker{
  table{
    thead{
      background: white !important;
    }
  }
  .btn{
    border-radius: unset;
  }
}
</style>