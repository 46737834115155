<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <label>Chọn nhân viên hỗ trợ đơn hàng</label>
        <div style="height: 20px" />
        <div v-if="!washingUserIds && !polishUserIds">
          Đơn không hợp lệ
        </div>
        <div class="form-group" v-if="washingUserIds">
          <label>Hỗ trợ rửa xe</label>
          <div class="form-control">
            <select-lazy-loading-view
              :hasAll="true"
              textAllCustom="- Không chọn -"
              dispatch="garage/staffs"
              keyData="data"
              :current="washingSupportUserId"
              :payload="{ exceptIds: washingUserIds }"
              @onChange="washing_support_user_id = $event"
            ></select-lazy-loading-view>
          </div>
        </div>
        <div class="form-group" v-if="polishUserIds">
          <label>Hỗ trợ đánh bóng</label>
          <div class="form-control">
            <select-lazy-loading-view
              :hasAll="true"
              textAllCustom="- Không chọn -"
              dispatch="garage/staffs"
              keyData="data"
              :payload="{ exceptIds: polishUserIds }"
              :current="polishSupportUserId"
              @onChange="polish_support_user_id = $event"
            ></select-lazy-loading-view>
          </div>
        </div>
      </div>
      <div style="height: 20px" />
      <div
        class="btn btn-primary"
        @click="updateSupportUser"
        v-if="washingUserIds || polishUserIds"
      >
        Lưu thay đổi
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "../../../../components/ModalBase.vue";
import SelectLazyLoadingView from "../../../../components/SelectLazyLoadingView.vue";
export default {
  components: { ModalBase, SelectLazyLoadingView },
  props: ["order"],

  data() {
    return {
      washing_support_user_id: this.washingSupportUserId,
      polish_support_user_id: this.polishSupportUserId,
    };
  },

  computed: {
    polishUserIds() {
      const polish = this.order.polish_list.firstWhere(
        (polish) => !polish.is_support
      );
      if (polish) {
        return [polish.user.id];
      }

      return null;
    },
    washingUserIds() {
      const washing = this.order.washing_list.firstWhere(
        (washing) => !washing.is_support
      );
      if (washing) {
        return [washing.user.id];
      }

      return null;
    },

    washingSupportUserId() {
      const washing = this.order.washing_list.firstWhere(
        (washing) => washing.is_support
      );
      if (washing) {
        return washing.user.id;
      }
      return null;
    },
    polishSupportUserId() {
      const polish = this.order.polish_list.firstWhere(
        (polish) => polish.is_support
      );
      if (polish) {
        return polish.user.id;
      }
      return null;
    },
  },
  methods: {
    async updateSupportUser() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang cập nhật...",
      });

      try {
        await this.$store.dispatch("order/addSupport", {
          washing_support_user_id: this.washing_support_user_id,
          polish_support_user_id: this.polish_support_user_id,
          order_id: this.order.info.id,
        });
        this.$emit("reload");
        this.$emit("close");
      } catch (e) {
        this.$toastr.e(`Lỗi cập nhật. Vui lòng thử lại.`);
        location.reload();
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          msg: "Đang lưu...",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 450px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
  }
}
.btn-primary {
  border-color: #00c292;
  background: #00c292;
  color: white !important;
}
.form-group label {
  font-size: 16px;
}
</style>
