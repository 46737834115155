<template>
  <default-layout>
    <div class="box-container">
      <tab-view
        :tabLists="tabLists"
        :current="currentTab"
        @onSelection="currentTab = $event"
      ></tab-view>
      <div class="content">
        <div style="height: 25px" />
        <info-tab v-show="currentTab == 0"></info-tab>
        <change-password-tab v-show="currentTab == 1"></change-password-tab>
      </div>
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import TabView from "../../../components/TabView.vue";
import InfoTab from "./tabs/InfoTab.vue";
import ChangePasswordTab from "./tabs/ChangePasswordTab.vue";
export default {
  components: { DefaultLayout, TabView, InfoTab, ChangePasswordTab },

  data() {
    return {
      currentTab: 0,

      tabLists: [
        { id: 0, name: "Thông tin cá nhân" },
        { id: 1, name: "Đổi mật khẩu" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
}
.content {
  width: 500px;
}


</style>
