<template>
  <default-layout>
    <div class="search-box">
      <div class="action">
<!--        <button class="btn btn-info" @click.prevent="resetFilter">Thiết lập lại</button>-->
        <router-link :to="{ name: 'garage create' }" style="color: #00c292">
          <button class="btn btn-primary">+ Thêm Ga-ra</button>
        </router-link>
      </div>
    </div>
    <div class="garage">
      <table-count :meta="meta" @refresh="fetchData"></table-count>
      <div class="content">
        <table>
          <thead>
            <td>Mã</td>
            <td>Tên</td>
            <td>Quản lý</td>
             <td>Địa chỉ</td>
          <td style="min-width: 120px;">Hành động</td>
          </thead>
          <tbody>
            <tr v-for="row of rows" :key="row.id">
              <td>{{ row.id }}</td>
              <td style="width: 30%; max-width: 200px;word-break: break-word;">{{ row.name }}</td>
              <td>{{ row.user.name }}</td>
               <td style="width: 30%; max-width: 500px">{{ row.address }}</td>
              <td>
                <router-link :to="{ name: 'garage detail', params: { id: row.id } }" style="color: #00c292">
                  <button class="btn btn-info  btn-sm">Chi tiết</button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginate-view
        style="justify-content: right;"
        :page="meta.page"
        :totalPage="meta.totalPage"
        @onPage="meta.page = String($event)"
      ></paginate-view>
    </div>
  </default-layout>
</template>

<script>
import PaginateView from "../../../components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import TableCount from "../../../components/TableCount.vue";
import GaraStatusLookup from "@/enums/GaraStatusLookup";
import {generateStatusHtml} from "@/utils/convert";
import {isEmpty} from "lodash";

export default {
  components: { DefaultLayout, PaginateView, TableCount },
  data() {
    return {
      firstLoading: true,
      rows: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      name: null,
      status: '',
      statusOptions: GaraStatusLookup,
      generateStatusHtml
    };
  },
  created() {
    this.fetchData(1);
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Vui lòng đợi...",
      });
      try{
        let resp = await this.$store.dispatch("garage/list", {
          page, name: this.name, status: this.status
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.rows = resp.data;
        this.meta = resp.meta;
      }catch (e){
        this.$toastr.e(e.responseJSON.message);
      }finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    async search(){
      await this.fetchData()
    },
    async resetFilter(){
      this.status = ''
      this.name = null
      await this.fetchData()
    },
  },
};
</script>

<style lang="scss" scoped>


.garage {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
   
  }
}
</style>
