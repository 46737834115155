<template>
  <default-layout>
    <div class="search-box">
      <form @submit.prevent="search">
        <div class="form-group">
          <label>Tên sản phẩm</label>
          <div class="form-control">
            <input v-model="name"/>
          </div>
        </div>
        <div class="form-group ">
          <label>Loại sản phẩm</label>
          <div class="form-control">
            <select v-model="type" @change="search">
              <option value="">Tất cả</option>
              <option v-for="(option,index) in ProductTypeLookup" v-bind:value="option.value" :key="index">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group ">
          <label>Trạng thái</label>
          <div class="form-control">
            <select v-model="status" @change="search">
              <option value="">Tất cả</option>
              <option v-for="(option,index) in statusOptions" v-bind:value="option.value" :key="index">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <div class="action">
        <button class="btn btn-submit" @click.prevent="search">Tìm kiếm</button>
        <button class="btn btn-info" @click.prevent="resetFilter">Thiết lập lại</button>
        <router-link :to="{ name: 'product create' }" style="color: #00c292">
          <button class="btn btn-primary">+ Thêm sản phẩm</button>
        </router-link>
      </div>
    </div>
    <div style="height: 16px" />
    <div class="product">
    <table-count :meta="meta" @refresh="fetchData"></table-count>
      <div class="content">
        <table>
          <thead>
          <td>Mã</td>
          <td>Mã nội bộ</td>
          <td>Tên</td>
          <td>Loại</td>
          <td>Giá</td>
          <td>Đơn vị</td>
          <td>Trạng thái</td>
          <td style="min-width: 120px;">Hành động</td>
          </thead>
          <tbody>
          <tr v-if="!rows || rows.length < 1">
            <td colspan="6"><span class="text-danger"> Không có dữ liệu </span></td>
          </tr>
          <tr v-else v-for="row of rows" :key="row.id">
            <td>{{ row.id }}</td>
              <td>{{ row.code || '--' }}</td>
            <td style="min-width: 200px; max-width: 500px; word-break: break-word;">{{ row.name }}</td>
            <td v-html="generateTagHtml(row.type, ProductTypeLookup)"></td>
            <td>{{ row.price.formatCurrency() }}</td>
            <td>{{ row.unit }}</td>
            <td v-html="generateStatusHtml(row.status, statusOptions)"></td>
            <td>
              <router-link :to="{ name: 'product detail', params: { id: row.id } }">
                <button class="btn btn-info btn-sm">Chi tiết</button>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <paginate-view
        style="justify-content: right;"
        :page="meta.page"
        :totalPage="meta.totalPage"
        @onPage="meta.page = String($event)"
      ></paginate-view>
    </div>
  </default-layout>
</template>

<script>
import PaginateView from "../../../components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import TableCount from '../../../components/TableCount.vue';
import ProductStatusLookup from '@/enums/ProductStatusLookup'
import ProductTypeLookup from '@/enums/ProductTypeLookup'
import {generateStatusHtml, generateTagHtml} from "@/utils/convert";
import {isEmpty} from "lodash";

export default {
  components: { DefaultLayout, PaginateView, TableCount },
  data() {
    return {
      firstLoading: true,
      rows: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      name: null,
      status: '',
      type: '',
      statusOptions: ProductStatusLookup,
      ProductTypeLookup,
      generateStatusHtml,
      generateTagHtml
    };
  },
  created() {
    this.fetchData(1);
  },

  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },
  },
  methods: {
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Vui lòng đợi...",
      });
      try{
        let resp = await this.$store.dispatch("product/list", {
          page, name: this.name, status: this.status, type: this.type
        });
        if(isEmpty(resp.data) && page !== 1){
          await this.fetchData(1)
          return
        }
        this.rows = resp.data;
        this.meta = resp.meta;
      }catch (e){
        this.$toastr.e(e.responseJSON.message);
      }finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    async search(){
      await this.fetchData()
    },
    async resetFilter(){
      this.status = ''
      this.name = null
      this.type = ''
      await this.fetchData()
    },
  },
};
</script>

<style lang="scss" scoped>

.product {
  background: white;
  padding: 16px 18px;

  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
  .content {
    margin-bottom: 16px;
    min-height: 320px;
    
  }
}
</style>
