<template>
  <div class="left-menu">
    <div class="menu-item" v-for="menu of menus" :key="menu.id">
      <div v-if="menu.submenus && menu.submenus.length > 0">
        <div
          style="padding: 14px; display: flex; align-items: center;"
          @click="menu.collapse = !menu.collapse"
        >
          <img :src="menu.icon" style="width: 14px; margin-right: 8px;" />
          {{ menu.name }}
          <div style="flex: 1" />
          <img
            class="arrow"
            src="@/assets/icons/arrow_down.png"
            :class="{ arrow_up: !menu.collapse }"
          />
        </div>
        <div
          class="sub-menu"
          v-show="!menu.collapse"
          style="margin-bottom: 14px;"
        >
          <a
            style=" padding: 8px; font-size: 14px; padding-left: 40px; display: flex; "
            v-for="submenu of menu.submenus"
            :key="submenu.id"
            :class="{
              active:
                currentMenu &&
                currentSubMenu &&
                submenu.id == currentSubMenu.id &&
                currentMenu.id == menu.id,
            }"
            :href="submenu.route"
          >
            {{ submenu.name }}
          </a>
        </div>
        <div style="  border-bottom: 1px solid #ededed"></div>
      </div>
      <a
        v-else
        style="padding: 14px; display: flex; align-items: center; border-bottom: 1px solid #ededed"
        :class="{ active: currentMenu && currentMenu.id == menu.id }"
        :href="menu.route"
      >
        <img :src="menu.icon" style="width: 14px; margin-right: 8px;" />
        {{ menu.name }}
      </a>
    </div>
  </div>
</template>

<script>
import MenuMaster from "../assets/jsons/menu.master.json";
import MenuManager from "../assets/jsons/menu.manager.json";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      currentMenu: null,
      currentSubMenu: null,
      menus: MenuManager,
      collapse: {},
    };
  },
  created() {
    if (this.user.role == 4) {
      this.menus = MenuMaster; // role master
    }

    let pathname = location.pathname;
    let menu = this.menus.firstWhere(
      (menu) => menu.route != "/" && pathname.includes(menu.route)
    );

    this.currentMenu = menu || this.menus[0];

    if (this.currentMenu && this.currentMenu.submenus) {
      this.currentSubMenu = this.currentMenu.submenus.firstWhere(
        (sub) => pathname == sub.route
      );
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },

  methods: {
    selectMenu(menu) {
      this.$router.push(menu.route);
    },
  },
};
</script>

<style lang="scss" scoped>
.left-menu {
  scrollbar-width: none;
  overflow-y: auto;
  background: white;
  .menu-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
  
    .icon {
      width: 14px;
      color: white;
    }
    .title {
      font-weight: normal;
      font-size: 14px;
      margin-left: 10px;
      color: #000000;
    }
  }
  a{
     color: #000000;
  }
  .active {
    color: #b42020;
    font-weight: 700;
  }

  .arrow {
    width: 10px;
    opacity: 0;
      transition: opacity 0.2s linear;
  }

  .menu-item:hover {
    .arrow {
      opacity: 1;
      transition: opacity 0.2s linear;
    }
  }
  .arrow.arrow_up {
    transform: rotate(180deg);
  }


}

@media only screen and (max-width: 1024px) {
  .left-menu{
    position: absolute;
    z-index: 1000;
    top: 0;
    border-right: 1px solid #ededed;
    box-shadow: 5px 3px 5px 0px #ededed
  }
}

</style>
