<template>
  <modal-base @close="$emit('close')">
    <div class="container">
      <div>
        <label>Chú ý: </label>
        <p>Bạn có chắc muốn xoá sản phẩm vừa chọn không?</p>
      </div>
      <div style="height: 20px"/>
      <div class="d-flex" style="justify-content: end">
        <div class="btn" style="margin-right: 20px" @click="$emit('close')">Hủy</div>
        <div class="btn btn-danger" @click="$emit('ok', deleteObj.id); $emit('close');">Đồng ý</div>
      </div>
    </div>
  </modal-base>
</template>

<script>
import ModalBase from "@/components/ModalBase.vue";
export default {
  components: { ModalBase },
  props: ['deleteObj'],
  data(){
    return {
      // deleteObj: this.deleteObj
    }
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 400px;
}

label {
  font-size: 20px;
  font-weight: bold;
}

ul {
  li {
    line-height: 23px;
    margin-bottom: 8px;
  }
}
.btn-primary {
  border-color: #00c292;
  background: #00c292;
  color: white !important;
}
b{
  font-weight: bold;
  color: #3c3c3c;
}
</style>
