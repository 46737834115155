import OrderApi from '../api/order_api';

export const state = {};

export const mutations = {};

export const getters = {};

export const actions = {
  all(_, query) {
    return OrderApi.all(query);
  },
  addSupport(_, body){
    return OrderApi.addSupport(body);

  },

  info(_, {
    id
  }) {
    return OrderApi.info({
      id
    })
  },

  create(_, body) {
    return OrderApi.create(body).then(resp => resp.order);
  },

  updatePayment(_, body) {
    return OrderApi.updatePayment(body).then(resp => resp.order);
  },

  cancel(_, {
    id,
    note
  }) {
    return OrderApi.cancel({
      id,
      note
    });
  },

  browse(_, body) {
    return OrderApi.browse(body);
  },

  histories(_, {
    order_id
  }) {
    return OrderApi.histories({
      order_id
    }).then(resp => resp.data);
  },
  update(_, body) {
    return OrderApi.update(body)
  },

  complete(_, body) {
    return OrderApi.complete(body)
  },
  latest(_, query) {
    return OrderApi.latest(query)
  },

  noteList(_, query) {
    return OrderApi.noteList(query).then(resp => resp.noteList)
  }
};