import AccountApi from '../api/account_api';

export const state = {
  user: JSON.parse(decodeURIComponent(localStorage.getItem("userData") || "{}")),
};

export const mutations = {
  LOGOUT: (_) => {
    localStorage.removeItem("userData");
    localStorage.removeItem("_token");
    _.user = null;
  },

  UPDATE_PROFILE: (_, data) => {
    _.user = data;
    localStorage.setItem(
      "userData",
      encodeURIComponent(JSON.stringify(_.user || {}))
    );
  },
};

export const getters = {
  user(state) {
    return state.user;
  },
  
};

export const actions = {
  logout(_){
    return AccountApi.logout().then((resp)=>{
      _.commit('LOGOUT', resp.user);
      return resp;
    });
  },
  me(_, data) {
    return AccountApi.me(data).then((resp)=>{
      _.commit('UPDATE_PROFILE', resp.user);
      return resp;
    });
  },
  register(_, data) {
    return AccountApi.register(data);
  },
  resendEmail(_, data) {
    _.dispatch('global/loading', true, {root: true});
    return AccountApi.resendEmail(data).always(() => {
      _.dispatch('global/loading', false, {root: true});
    });
  },

  usernameExists(_, username) {
    return AccountApi.usernameExists({
      'username': username
    });
  },
  getProfile(_) {
    _.dispatch('global/loading', true, {root: true});
    return AccountApi.getProfile().always(() => {
      _.dispatch('global/loading', false, {root: true});
    });
  },

  validationAccount(_, tokenHash) {
    return AccountApi.validationAccount(tokenHash);
  },

  updateLoginCredential(_, data) {
    // show loading
    _.dispatch('global/loading', true, {root: true});
    return AccountApi.updateLoginCredential(data).always(() => {
      _.dispatch('global/loading', false, {root: true});
    });
  },

  list(_, data) {
    return AccountApi.list(data);
  },

  create(_, data) {
    return AccountApi.create(data)
  },

  detail(_, data) {
    return AccountApi.detail(data)
  },

  update(_, data) {
    return AccountApi.update(data)
  },

  lookup(_, data) {
    return AccountApi.lookup(data);
  },

  resetPassword(_, {email}) {
    return AccountApi.adminResetPassword({email})
  },

  overview(_, query){
    return AccountApi.overview(query)
  },

  timelines(_, query){
    return AccountApi.timelines(query)
  },

  worklists(_, query){
    return AccountApi.worklists(query)
  }
};
