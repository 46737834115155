<template>
  <default-layout>
    <div v-if="roomData">
      <div
        class="title"
        style="display: flex;align-items: center; background: white; padding: 20px;"
      >
        <div style="flex: 1; display: flex;   align-items: top;">
          <div style="display: flex; flex-direction: column; margin: 2px;">
            <div>Thông tin phòng</div>
          </div>
        </div>
      </div>

      <div class="box-content">
        <div class="form">
          <div class="left">
            <div class="form-group">
              <div class="input-control box-form-input">
                <div class="form-group">
                  <label>Tên phòng</label>
                  <div style="display: flex;">
                    <div
                      class="input-control"
                      style="flex: 1;"
                      v-if="user.role == 4"
                    >
                      <input v-model="roomData.name" />
                    </div>
                    <div
                      v-else
                      class="input-control"
                      style="border-color: transparent"
                    >
                      {{ roomData.name }}
                    </div>
                    <div style="width:10px" />
                    <div
                      v-if="user.role == 4"
                      style="display: flex;align-items: center; cursor: pointer;color: #b52323;"
                      class="btn-sm btn-link"
                      @click.prevent="changeName()"
                    >
                      Lưu thay đổi
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Loại phòng</label>
                  <div class="input-control" style="border-color: transparent">
                    {{ typeOptions[roomData.type] }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Trạng thái</label>
                  <div style="display: flex; align-items: center;">
                    <label
                      class="switch"
                      style="margin: 0"
                      @click.prevent="switchRoomStatus()"
                    >
                      <input
                        type="checkbox"
                        :checked="roomData.status == 'active'"
                      />
                      <span class="slider round"></span>
                    </label>
                    <span style="margin-left: 8px">{{
                      roomData.status == "active" ? "Đang mở" : "Đang khóa"
                    }}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label style="display: flex; align-items: center;">
                    <span>
                      Nhân viên chính
                    </span>
                    <div
                      v-if="user.role == 4"
                      class="btn btn-link"
                      @click.prevent="isShowChangeStaff = true"
                    >
                      Thay đổi
                    </div></label
                  >
                  <div style="height: 4px;" />
                  <div class="tag-list">
                    <div
                      v-if="roomData.users.length == 0"
                      style="font-style: italic; color: #bbbbbb;"
                    >
                      Không có
                    </div>
                    <a
                      v-for="user of roomData.users"
                      :key="user.id"
                      class="tag"
                      style="line-height: initial;font-size: 16px; cursor: pointer;"
                      :href="`/staff/${user.id}/detail`"
                      target="_blank"
                    >
                      {{ user.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="form-group">
              <label>QR code</label>

              <img ref='refQRCodeImage' src="" style="width: 220px; height: 220px; margin: auto">
              <div style="margin: auto" class="btn btn-link" @click.prevent="downloadImage()"><span >Tải xuống</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-content">
        <tab-view
          :tabLists="tabLists"
          :current="currentTab"
          @onSelection="currentTab = $event"
        ></tab-view>
        <div style="margin-top: 20px">
          <div v-show="currentTab === 0">
            <div v-if="isFirsloading"></div>
            <div v-else>
              <div class="search-box">
                <form @submit.prevent="search">
                  <div class="form-group">
                    <label>Sản phẩm</label>
                    <div class="form-control">
                      <input v-model="keyword" placeholder="Mã, tên sản phẩm" />
                    </div>
                  </div>
                  <div class="form-group ">
                    <label>Loại sản phẩm</label>
                    <div class="form-control">
                      <select v-model="type" @change="search">
                        <option value="">Tất cả</option>
                        <option
                          v-for="(option, index) in ProductTypeLookup"
                          v-bind:value="option.value"
                          :key="index"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
                <div class="action">
                  <button class="btn btn-submit" @click.prevent="search">
                    Tìm kiếm
                  </button>
                  <button class="btn btn-info" @click.prevent="resetFilter">
                    Thiết lập lại
                  </button>
                </div>
              </div>
              <div style="height: 20px" />

              <table-count :meta="meta" @refresh="fetchData"></table-count>
              <div class="content">
                <table>
                  <thead>
                    <td>Mã sản phẩm</td>
                    <td>Mã nội bộ</td>
                    <td width="40%">Tên sản phẩm</td>
                    <td>Loại sản phẩm</td>
                    <td>Tồn tối thiểu</td>
                    <td>Tồn hiện tại</td>
                    <td>Đơn vị</td>
                    <td v-if="user.role === UserRoleType.MASTER" style="min-width: 120px;">Hành động</td>
                  </thead>
                  <tbody>
                    <tr v-if="!rows || rows.length < 1">
                      <td colspan="7">
                        <span class="text-danger"> Không có dữ liệu </span>
                      </td>
                    </tr>
                    <tr v-else v-for="row of rows" :key="row.id">
                      <td>{{ row.product_id }}</td>
                      <td>{{ row.product.code || '--' }}</td>
                      <td
                        style="min-width: 200px; max-width: 500px; word-break: break-word;"
                      >
                        {{ row.product.name }}
                      </td>
                      <td
                        v-html="
                          generateTagHtml(row.product.type, ProductTypeLookup)
                        "
                      ></td>
                      <td v-html="row.product.alert_min_amount.formatNumber()"></td>
                      <td v-html="row.amount.formatNumber()"></td>
                      <td>{{ row.product.unit }}</td>
                      <td v-if="user.role === UserRoleType.MASTER">
                        <button
                          class="btn btn-danger  btn-sm"
                          style="min-width: auto; margin-right: 10px"
                          @click.prevent="
                            showConfirmDelete(row.id, row.product.name, row.amount, row.product.unit)
                          "
                        >
                          Rút về garage
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <paginate-view
                style="justify-content: right;"
                :page="meta.page"
                :totalPage="meta.totalPage"
                @onPage="meta.page = String($event)"
              ></paginate-view>
            </div>
          </div>
          <room-product-add
            v-show="currentTab === 1"
            :roomId="roomId"
            :garageId="garageId"
            @submit="
              currentTab = 0;
              fetchData();
            "
          ></room-product-add>
        </div>
      </div>

      <modal-confirm-refund-product
        :deleteObj="deleteObj"
        v-if="isShowConfirmDelete"
        @close="isShowConfirmDelete = false"
        @ok="deleteInventory"
      >
      </modal-confirm-refund-product>

      <modal-change-staff-room
        v-if="isShowChangeStaff"
        @close="isShowChangeStaff = false"
        @reload="roomDetail()"
        :roomId="roomData.id"
        :garageId="roomData.garage_id"
        :value="userIds"
        :type="roomData.type"
      ></modal-change-staff-room>
    </div>
  </default-layout>
</template>

<script>
import PaginateView from "@/components/PaginateView.vue";
import DefaultLayout from "@/router/layouts/default";
import ModalConfirmRefundProduct from "./modal/ModalConfirmRefundProduct";
import TableCount from "@/components/TableCount.vue";
import TabView from "@/components/TabView.vue";
import RoomProductAdd from "./tab/RoomProductAdd";
import UserRoleType from "@/enums/UserRoleType";
import { mapGetters } from "vuex";
import ModalChangeStaffRoom from "./modal/ModalChangeStaffRoom.vue";
import { isEmpty } from "lodash";
import { generateTagHtml } from "@/utils/convert";
import ProductTypeLookup from "@/enums/ProductTypeLookup";
import QrCodeWithLogo from "qrcode-with-logos";

export default {
  components: {
    ModalConfirmRefundProduct,
    DefaultLayout,
    PaginateView,
    TableCount,
    TabView,
    RoomProductAdd,
    ModalChangeStaffRoom,
  },
  data() {
    return {
      isShowChangeStaff: false,
      typeOptions: {
        washing: "Rửa xe",
        polish: "Đánh bóng",
      },
      rows: [],
      meta: {
        page: "1",
        totalPage: 1,
        total: 0,
      },
      keyword: null,
      status: "",
      type: "",
      isShowProduct: false,
      isShowSwapProduct: false,
      productIds: [],
      amountItems: [],
      garageOption: [],
      roomId: null,
      isLoadingList: true,
      isFirsloading: true,
      isShowConfirmDelete: false,
      deleteObj: null,
      currentTab: 0,
      tabLists: [{ id: 0, name: "Sản phẩm trong phòng" }],
      UserRoleType: UserRoleType,
      generateTagHtml,
      ProductTypeLookup,
      roomData: null,
      garageId: null,
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },

  async created() {
    this.roomId = this.$route.params.id;
    this.roomDetail();
    if (this.user.role === UserRoleType.MASTER) {
      this.tabLists = [
        { id: 0, name: "Sản phẩm trong phòng" },
        { id: 1, name: "Nhập sản phẩm" },
      ];
    } else {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
    userIds() {
      if (this.roomData) {
        return this.roomData.users.map((user) => {
          return {
            ...user,
            text: user.name,
          };
        });
      }
      return [];
    },
  },
  watch: {
    "meta.page": function(val) {
      this.$nextTick(() => {
        this.fetchData(val);
      });
    },

    roomId: function(val) {
      if (val === 0) return;
      this.fetchData();
    },
  },
  methods: {
    async qrCodeImage(){      
      const now = `${Date.now()}`;
      new QrCodeWithLogo({
            content: `${btoa(now + "qrcode").substring(0, 6)}.${now.substring(
              7
            )}.${this.roomId}`,
            nodeQrCodeOptions: {
              margin: 1,
            },
            width: 320,
            image: this.$refs.refQRCodeImage,
      }).toImage();
    },
      downloadImage() {
      const now = `${Date.now()}`;
      new QrCodeWithLogo({
        content: `${btoa(now + "qrcode").substring(0, 6)}.${now.substring(
          7
        )}.${this.roomId}`,
        nodeQrCodeOptions: {
          margin: 1,
        },
        width: 320,
      }).downloadImage(`qrcode_${this.roomId}`);
    },
    async switchRoomStatus() {
      if (this.roomData.status == "active") {
        this.roomData.status = "inactive";
      } else {
        this.roomData.status = "active";
      }
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang cập nhật",
      });

      try {
        await this.$store.dispatch("garage/roomUpdate", {
          roomId: this.roomId,
          garageId: this.roomData.garage_id,
          status: this.roomData.status,
        });
      } catch (e) {
        this.roomDetail();
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },

    async changeName() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang cập nhật",
      });
      try {
        await this.$store.dispatch("garage/roomUpdate", {
          roomId: this.roomId,
          garageId: this.roomData.garage_id,
          name: this.roomData.name,
        });
      } catch (e) {
        this.roomDetail();
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },

    search() {
      this.fetchData(1);
    },
    async resetFilter() {
      this.status = "";
      this.type = "";
      this.keyword = null;
      await this.fetchData();
    },
    async roomDetail() {
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let resp = await this.$store.dispatch("garage/roomDetail", {
          id: this.roomId,
        });
        this.roomData = resp;
        this.garageId = resp.garage_id;
        this.$nextTick(() => {
            this.qrCodeImage()
        })
       
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    async fetchData(page = 1) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
      });
      try {
        let resp = await this.$store.dispatch("inventory/roomProductList", {
          page,
          roomId: this.roomId,
          keyword: this.keyword,
          type: this.type,
          limit: 10,
        });
        if (isEmpty(resp.data) && page !== 1) {
          await this.fetchData(1);
          return;
        }
        this.rows = resp.data;
        this.meta = resp.meta;
        this.isFirsloading = false;
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
    showConfirmDelete(id, name, amountMax, unit) {
      this.deleteObj = {
        id,
        name,
        amountMax,
        unit
      };
      this.isShowConfirmDelete = true;
    },
    async deleteInventory({ id, amount }) {
      this.$store.dispatch("global/loading", {
        isLoading: true,
        // msg: "Vui lòng đợi...",
      });
      try {
        await this.$store.dispatch("inventory/roomProductDelete", { id, amount: parseInt(amount) });
        this.isShowConfirmDelete = false;
        await this.fetchData();
      } catch (e) {
        this.$toastr.e(e.responseJSON.message);
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
}
.box-content {
  background: white;
  padding: 16px 18px;
  margin-bottom: 32px;
  min-height: 300px;
  .header {
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #717171;
    }
  }
  .content {
    margin-bottom: 16px;
    //min-height: 320px;
  }
}
.icon-loader {
  width: 34px;
  height: 34px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
}

.form {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      color: black;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
}
</style>
