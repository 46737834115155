<template>
  <div class="div-select">
    <div class="loader icon-loader" v-if="!lists"></div>
    <div v-else-if="errorMessage" class="has-error">{{ errorMessage }}</div>
    <div v-else-if="disabled">{{ getSelected() }}</div>
    <select v-else v-model="selected" :name="mName" v-validate="validate || ''">
      <option value="" :disabled="!hasAll" selected v-if="hasAll || !current">{{hasAll? textAllCustom || 'Tất cả' : '- Chọn -'}}</option>
      <option v-for="item of lists" :key="item.id" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  inject: ["parentValidator"],
  props: ["dispatch", "current", 'hasAll', 'textAllCustom', 'payload','toggleRefresh', 'disabled' , 'validate', "mName", 'keyData'],
  data() {
    return {
      errorMessage: null,
      lists: null,
      selected: this.current || '',
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    selected(val) {
      this.$emit("onChange", val);
    },
    async toggleRefresh(val){
      await this.fetchData()
      this.selected = '';
    }
  },
  created(){
    this.$validator = this.parentValidator;
  },
  methods: {
    async fetchData() {
      this.errorMessage = null;
      this.lists = null;
      this.lists = await this.$store.dispatch(this.dispatch, this.payload).catch((e) => {
        console.log(e);
        this.errorMessage = "Lỗi kết nối";
        return [];
      });
      if(this.keyData){
        this.lists = this.lists[this.keyData];
      }
      if (this.current != null && this.lists.length > 0 && !this.lists.firstWhere(x => x.id == this.current)) {
        this.selected = `${this.lists[0].id}`;
      }
      this.$emit("onChange", this.selected);
    },
    getSelected(){
      for(let item of this.lists){
        if(item.id == this.selected)
        return item.name
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.div-select {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 20px;
  select {
    border: 0px;
    width: 100%;
    height: 100%;
  }
  .icon-loader {
    width: 17px;
    height: 17px;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
  }
}
</style>
