<template>
  <default-layout>
    <div class="box-container">
      <div class="header">
        <label class="title">Thêm nhân viên</label>
        <div class="action"></div>
      </div>
      <form class="form">
        <div class="right">
          <div class="form-group">
            <div class="input-control box-form-input">
              <div class="form-group">
                <label>Chọn ga-ra</label>
                <div
                  class="input-control"
                  style="width: 300px"
                >
                  <select-lazy-loading-view
                    :current="1"
                    dispatch="garage/lookup"
                    @onChange="user.garage_id = $event"
                  ></select-lazy-loading-view>
                </div>
              </div>

              <input-view
                mName="Họ tên"
                :value="user.name"
                @input="user.name = $event"
                maxlength="50"
                validate="required|name"
              ></input-view>

              <input-view
                mName="Email"
                :value="user.email"
                @input="user.email = $event"
                validate="required"
              ></input-view>

              <input-view
                mName="Số điện thoại"
                :value="user.phone"
                @input="user.phone = $event"
                validate="required|regex:^0+[0-9]{9}$"
              ></input-view>

              <input-view
                mName="Địa chỉ"
                :value="user.address"
                @input="user.address = $event"
                maxlength="255"
                validate="required"
              ></input-view>

              <div style="height: 24px" />
              <div
                class="btn btn-submit"
                style="width: 200px;"
                @click.prevent="createUser()"
              >
                Tạo nhân viên
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <modal-login-info
      :email="email"
      :passwordRoot="rawPassword"
      v-if="isShowLoginInfo"
      @close="dismiss()"
    ></modal-login-info>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/router/layouts/default";
import BunderInfoView from "../../../components/BunderInfoView.vue";
import StaffAssignView from "../../../components/StaffAssignView.vue";
import SelectLazyLoadingView from "../../../components/SelectLazyLoadingView.vue";
import StaffListView from "../../../components/StaffListView.vue";
import ModalLoginInfo from "./modal/ModalLoginInfo.vue";
import { mapActions } from "vuex";
import InputView from "../../../components/InputView.vue";
export default {
  components: {
    DefaultLayout,
    SelectLazyLoadingView,
    StaffListView,
    ModalLoginInfo,
    InputView,
  },
  data() {
    return {
      isHidePayment: false,
      isShowLoginInfo: false,
      bundleId: null,
      user: {
        garage_id: null,
        phone: null,
        name: null,
        address: null,
      },
      email: null,
      rawPassword: null,
      id: null,
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  methods: {
    ...mapActions("global", ["validateAll"]),
    selectImage(ev) {
      this.imageFile = ev.target.files[0];
    },

    async submit() {
      // validate
      // submit
    },

    dismiss() {
      this.$router.push({
        name: "staff detail",
        params: {
          id: this.id,
        },
      });
      this.isShowLoginInfo = false;
    },

    async createUser() {
      const result = await this.validateAll(this);
      if (!result) return;
      
      this.$store.dispatch("global/loading", {
        isLoading: true,
        msg: "Đang tạo nhân viên...",
      });
      try {
        const user = await this.$store.dispatch("account/create", {
          ...this.user,
        });

        this.$toastr.s(`Đã tạo nhân viên`);
        this.isShowLoginInfo = true;
        this.id = user.id;
        this.email = user.email;
        this.rawPassword = user.password;
      } catch (e) {
        if (e.responseJSON) {
          this.$toastr.e(e.responseJSON.message);
        } else {
          this.$toastr.e(`Lỗi kết nối. Vui lòng liên hệ với bộ phận kĩ thuật`);
        }
      } finally {
        this.$store.dispatch("global/loading", {
          isLoading: false,
          msg: "Đang tạo nhân viên...",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    flex: 1;
  }
  .form-group {
    width: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
    select,
    textarea,
    input {
      border: 0;
      background: transparent;
      width: 100%;
    }
    .input-control {
      border: 1px solid #b9b9b9;
      padding: 8px;
    }
  }
}
</style>
